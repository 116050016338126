export const STATEMENT_CATEGORY_FIX_INIT_PROPS = "STATEMENT_CATEGORY_FIX_INIT_PROPS"
export const STATEMENT_CATEGORY_FIX_EDIT_ITEM = "STATEMENT_CATEGORY_FIX_EDIT_ITEM"
export const STATEMENT_CATEGORY_FIX_ADD_ITEM = "STATEMENT_CATEGORY_FIX_ADD_ITEM"
export const STATEMENT_CATEGORY_FIX_DELETE_ITEM = "STATEMENT_CATEGORY_FIX_DELETE_ITEM"

function statementCategoryFixInitProps(dataset) {
  return {
    type: STATEMENT_CATEGORY_FIX_INIT_PROPS,
    dataset: dataset
  }
}

function statementCategoryFixEditItem(dataset, index, e) {
  return {
    type: STATEMENT_CATEGORY_FIX_EDIT_ITEM,
    dataset: dataset,
    index: index,
    e: e
  }
}

function statementCategoryFixAddItem(dataset, item) {
  return {
    type: STATEMENT_CATEGORY_FIX_ADD_ITEM,
    dataset: dataset,
    item: item,
  }
}

function statementCategoryFixDeleteItem(dataset, index) {
  return {
    type: STATEMENT_CATEGORY_FIX_DELETE_ITEM,
    dataset: dataset,
    index: index,
  }
}

export const StatementCategoryFixAction = {
  statementCategoryFixInitProps,
  statementCategoryFixEditItem,
  statementCategoryFixAddItem,
  statementCategoryFixDeleteItem
}