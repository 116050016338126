import React from 'react'
import { hot } from 'react-hot-loader'
import InputNumber from './InputNumber';


const InputFixedV = props => {
  const valueStatus = props.valueStatus
  const tabIndex = valueStatus == "solid" || valueStatus == "sum" ? -1 : 0 
  return (
    <React.Fragment>
      {props.edittable && (
        <label className="fs-label">
          <InputNumber
            {...props}
            className={`fs-input fs-${props.kindOfItem}`}
            name="fixed_v"
            value={props.value}
            onChange={(e) => props.onChange(e)}
          />
        </label>
      )}
      {!props.edittable && (
        <label className={`fs-label ${props.valueStatus}`}>
          <InputNumber
            {...props}
            className={`fs-input fs-${props.kindOfItem} ${props.valueStatus}`}
            name="fixed_v"
            value={props.value}
            tabIndex={tabIndex}
            readOnly />
        </label>
      )}
    </React.Fragment>
  );
}


InputFixedV.defaultProps = {
  value: "",
  kindOfItem: "small",
  valueStatus: "",
  edittable: false,
  onChange: null,
}

export default hot(module)(InputFixedV);