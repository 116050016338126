$(() => {
  const $modalTextAndModalTextCommentEnterFix = $(
    ".trigger--modal_text .trigger--enter_fix, .trigger--modal_text_comment .trigger--enter_fix"
  );
  const $modalTextAndModalTextCommentFixModal = $(
    ".trigger--modal_text .trigger--fix_modal, .trigger--modal_text_comment .trigger--fix_modal"
  );
  const $modalTextAndModalTextCommentModalValue = $(
    ".trigger--modal_text .trigger--select-modal-value, .trigger--modal_text_comment .trigger--select-modal-value"
  );
  const $modalImageImgClick = $(".trigger--modal_image .trigger--img-click");
  const $modalImageSelectCrawlImage = $(
    ".trigger--modal_image .trigger--select_crawl_image"
  );
  const $modalTextClearValue = $(".trigger--modal_text .trigger--clear_value");

  // モーダル表示時にテキストフィールドをフォーカス
  $(document).on("opening", ".trigger--modal_text_comment", () =>
    $(".event--input_modal_text").focus()
  );
  $(document).on("opening", ".trigger--modal_text", function () {
    const data_id = $(this).data("id");
    $(`.event--input_modal_text[data-id=${data_id}]`).focus();
  });

  // エンター時に確定
  $modalTextAndModalTextCommentEnterFix.on("keypress", function (e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      $(this).parents(".remodal").find(".trigger--fix_modal").click();
      return false;
    }
    return true;
  });

  // 「モーダルの確定」から「プレビューとクイック入力」に即座に反映する
  // ========================trigger--modal_text_comment====================================================================
  // コメント入力、テキスト入力（共通処理）
  $modalTextAndModalTextCommentFixModal.on("click", function () {
    const element = $(this)
      .parents(".remodal")
      .find(".event--input_modal_text");
    const data_id = element.data("id");
    const value = element.val();
    if (value !== "") {
      $(`#event--show_text_${data_id}`).html(
        String(value).replace(/\n/g, "<br>")
      );
      $(`#txt_${data_id}`).val(value);
    }
  });

  // 候補とリレーションからの選択による入力
  $modalTextAndModalTextCommentModalValue.on("click", function () {
    const data_id = $(this).data("id");
    const value = $(this).data("value");
    $(`#event--show_text_${data_id}`).html(
      String(value).replace(/\n/g, "<br>")
    );
    $(`#txt_${data_id}`).val(value);
    $(`.event--input_modal_text[data-id=${data_id}]`).val(value).focus();
  });

  // 画像入力（クイック入力を疑似クリックする）
  $modalImageImgClick.on("click", function () {
    const data_id = $(this).data("id");
    $(`#img_${data_id}`).click();
    $(".remodal").find(".remodal-close").click();
  });

  // クローリング済み画像から入力
  $modalImageSelectCrawlImage.on({
    click() {
      const data_id = $(this).data("id");
      const src = $(this).attr("src");
      const element = $(`#img_${data_id}`).parents("div");

      // 画像の追加
      console.log(data_id);
      element.append(
        `<input type='hidden' name='img[${data_id}]' value='${src}'>`
      );
      $(`#event--show_image_title_${data_id}`).html(
        `${src}<span class="trigger--remove_image red ml-2" data-id="${data_id}"><i class="fa fa-minus-circle"></i></span>`
      );
      $(".remodal").find(".remodal-close").click();

      // プレビュー画像の差し替え
      $(`#event--image_block_${data_id}`)
        .find(".trigger--change_image")
        .addClass("active")
        .html(`<img src=${src}>`);
    },
    // 読み込みに失敗した画像がある場合は削除処理
    error() {
      $(this).parents("div.col-2").remove();
    },
  });

  $modalTextClearValue.on("click", function () {
    const data_id = $(this).data("id");
    $(`#event--show_text_${data_id}`).html("テキストを入力");
    $(`#txt_${data_id}`).val("");
    $(`.event--input_modal_text[data-id=${data_id}]`).val("");
  });
});
