$(window).on("load resize", () => {
  if (!$("#documents-index").length) {
    return;
  }

  const $triggerScale = $(".trigger--scale");
  const width = $triggerScale.outerWidth();
  const height = width * 0.715;

  const scale = height * 0.000784314;
  const inner_width = (1 / scale) * 100;

  $triggerScale.css("height", `${height}px`);

  $(".event--parent_scale").each((index, element) => {
    $(element).css("width", `${width}px`);
    $(element).css("height", `${height}px`);
  });

  $(".event--child_scale").each((index, element) => {
    $(element).css("width", `${inner_width}%`);
    $(element).css("transform", `scale(${scale})`);
    $(element).css("display", "block");
  });
});
