import * as math from "mathjs";

export const itpl = {
  // 欠損値を線形補間
  interpolate: (array) => {
    let count = itpl.countNaNSeries(array);
    switch (count) {
      case 0:
        return array;
      case 1:
        return itpl.interpolate1(array);
      case 2:
        return itpl.interpolate2(array);
      case 3:
        return itpl.interpolate3(array);
      default:
        return [
          "... Unexpected Error Occured in itpl.interpolate Please expect interpolate less than 4 ...",
        ];
    }
  },

  interpolateAssociativeArray: (asArray) => {
    let r = {};
    for (let key in asArray) {
      // noinspection JSUnfilteredForInLoop
      r[key] = itpl.interpolate(asArray[key]);
    }
    return r;
  },

  // 欠損値の連続数を算出
  countNaNSeries: (array) => {
    let seriesFlag = false;
    let count = 0;
    let tmp = [0];
    array.forEach((v) => {
      if (isNaN(v)) {
        seriesFlag = true;
        count += 1;
        tmp.push(count);
      } else {
        seriesFlag = false;
        count = 0;
      }
    });
    return math.max(tmp);
  },

  // 欠損値補間(1つ連続で欠損)
  interpolate1: (array) => {
    let r = [];
    array.forEach((v, i, a) => {
      if (isNaN(v) && a[i - 1] !== undefined && a[i + 1] !== undefined) {
        r.push(math.mean([a[i - 1], a[i + 1]]));
      } else {
        r.push(a[i]);
      }
    });
    return r;
  },

  // 欠損値補間(2つ連続で欠損)
  interpolate2: (array) => {
    let r = [];
    array.forEach((v, i, a) => {
      let ret = v;

      if (isNaN(ret)) {
        if (
          isNaN(a[i + 1]) &&
          a[i - 1] !== undefined &&
          a[i + 2] !== undefined
        ) {
          ret = array[i - 1] + (array[i + 2] - array[i - 1]) / 3;
        } else if (
          isNaN(a[i - 1] && a[i - 2] !== undefined && a[i + 1] !== undefined)
        ) {
          ret = array[i - 2] + ((array[i + 1] - array[i - 2]) / 3) * 2;
        } else {
          //
        }
        r.push(ret);
      } else {
        r.push(ret);
      }
    });
    return r;
  },

  // 欠損値補間(3つ連続で欠損)
  interpolate3: (array) => {
    let r = [];
    array.forEach((v, i, a) => {
      let ret = v;
      if (isNaN(v)) {
        if (
          isNaN(a[i + 1]) &&
          isNaN(a[i + 2]) &&
          a[i - 1] !== undefined &&
          a[i + 3] !== undefined
        ) {
          ret = a[i - 1] + (a[i + 3] - a[i - 1]) / 4;
        } else if (
          isNaN(a[i - 1]) &&
          isNaN(a[i + 1]) &&
          a[i - 2] !== undefined &&
          a[i + 2] !== undefined
        ) {
          ret = a[i - 2] + ((a[i + 2] - a[i - 2]) / 4) * 2;
        } else if (
          isNaN(a[i - 2]) &&
          isNaN(a[i - 1]) &&
          a[i - 3] !== undefined &&
          a[i + 1] !== undefined
        ) {
          ret = a[i - 3] + ((a[i + 1] - a[i - 3]) / 4) * 3;
        } else {
          //
        }
        r.push(ret);
      } else {
        r.push(ret);
      }
    });
    return r;
  },
};
