import React from "react";
import { hot } from "react-hot-loader";
import { interviewAnswerEdit } from "./interviewAnswers";
import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
  DEVELOPMENT
} from "../../constants";

class InterviewBackgroundContainer extends React.Component {
  constructor(props) {
    super(props);
    const { pageName } = props;
    if (process.env.NODE_ENV === DEVELOPMENT) {
      console.log(`pageName: ${pageName}`);
    }

    switch (pageName) {
      case "reports__interviews":
        // インタビュー一覧
        this.interviews();
        break;
      case "interview_answers__edit":
        const { questionCount } = props;
        // インタビュー回答画面
        interviewAnswerEdit({ questionCount });
        break;
      case "interview_answers__result_update":
      case "interview_answers__result_edit":
        // インタビュー更新
        this.interviewAnswerResultUpdate();
        break;
      case "interview_questions__updates":
      case "interview_questions__edit":
        // 管理画面インタビュー編集画面
        this.interviewQuestionEdit();
        break;
      default:
        break;
    }
  }

  /**
   * インタビューシート一覧
   */
  interviews() {
    $(document).on("click", "#documents-index #trigger--download_submit", () =>
      setTimeout(() => {
        const $submit = $("#documents-index #trigger--download_submit");
        if ($submit.is(":disabled")) {
          submit.prop("disabled", false);
        }
      }, 1000)
    );

    let check = 0;
    $(document).on("click", "#documents-index #trigger--all_check", () => {
      if (check === 0) {
        $("#documents-index .list-group-item input").prop("checked", true);
        check = 1;
      } else {
        $("#documents-index .list-group-item input").prop("checked", false);
        check = 0;
      }
    });

    $(document).on(
      "click",
      "#documents-index .trigger--url_issuance",
      function () {
        $(this).css("pointer-events", "none");
        setTimeout(() => location.reload(), 500);
      }
    );
  }

  /**
   * インタビュー更新
   */
  interviewAnswerResultUpdate() {
    this.setViewAnswerEdit();

    // 行の追加を行う
    $(document).on("click", ".trigger--add_row", function () {
      const question_id = $(this).data("question_id");
      const contents_number = $(this).data("contents_number");
      const len =
        $(`#result_edit #contents_number_${contents_number} tbody`).children()
          .length + 1;
      const item_ids = $(this).data("item_ids");
      $(`#result_edit #table_${contents_number} tbody`).append("<tr/>");
      for (let i = 0; i < item_ids.length; i++) {
        $(`#result_edit #table_${contents_number} tbody tr:last`).append(
          `<td><input type="text" class="form-control" name="${question_id}[${len}][${item_ids[i]}]"></td>`
        );
      }
    });

    // テーブル行削除
    $(document).on("click", ".trigger--remove_row", (event) => {
      const $element = $(event.target);
      const $questionElement = $element.closest("[data-question_id]");
      const questionId = $questionElement.data("question_id");
      const len = $questionElement.find("tbody").children().length;
      if (len > 1) {
        alert("最後の行を削除してよろしいですか。");
        $questionElement.find(".table tbody tr:last").remove();
      }
    });

    $(document).on("click", "#trigger--answer_edit_button", () =>
      this.setViewAnswerEdit()
    );

    $(document).on("click", "#trigger--answer_result_button", () =>
      this.setViewAnswerResult()
    );
  }

  /**
   * 回答画面の表示
   */
  setViewAnswerEdit() {
    $("#result_edit .answer-edit-contents").show();
    $("#result_edit .answer-result-contents").hide();
    $("#result_edit #trigger--answer_edit_button").css(
      "background-color",
      "white"
    );
    $("#result_edit #trigger--answer_result_button").css(
      "background-color",
      "#f4f6f8"
    );
  }

  /**
   * 解答画面の表示
   */
  setViewAnswerResult() {
    $("#result_edit .answer-edit-contents").hide();
    $("#result_edit .answer-result-contents").show();
    $("#result_edit #trigger--answer_edit_button").css(
      "background-color",
      "#f4f6f8"
    );
    $("#result_edit #trigger--answer_result_button").css(
      "background-color",
      "white"
    );
  }

  /**
   * インタビューで表示する質問を設定してください。
   * app/views/interview_questions/_form.html.erb
   */
  interviewQuestionEdit() {
    $(() => {
      let cnt = $(".question_block").length;

      // 質問追加時
      $(document).on("cocoon:after-insert", ".trigger--insert_question", () => {
        cnt += 1;
        $(".event--question_count-render:last").text(cnt);
        $(".question_block:last .answer_type").attr("id", `answer_type_${cnt}`);

        $(".question_block:last .item_block").attr("id", `item_block_${cnt}`);
        $(".question_block:last .next_question").val(cnt + 1);

        const DEFAULT_ROW_SIZE = 1;
        $(".question_block:last .default-row-size")
          .removeClass(`default-row-size__${cnt - 1}`)
          .addClass(`default-row-size__${cnt}`)
          .find(".default-row-size__form-input")
          .val(DEFAULT_ROW_SIZE);
      });

      // 選択肢追加時
      $(document).on(
        "cocoon:after-insert",
        ".trigger--insert_item",
        function () {
          const item_block_id = `#${$(this).parent().attr("id")}`;
          const no = item_block_id.replace("#item_block_", "");
          const text = $(`#answer_type_${no} option:selected`).text();
          if (text === ANSWER_TYPE__CHECK_BOX || text === ANSWER_TYPE__TABLE) {
            $(item_block_id)
              .find(".item_next_question")
              .attr("style", "display:none !important");
          } else {
            $(item_block_id)
              .find(".item_next_question")
              .attr("style", "display:block");
          }
          if (text === ANSWER_TYPE__TABLE) {
            $(item_block_id)
              .find(".item_text_id")
              .attr("style", "display:block");
          } else {
            $(item_block_id)
              .find(".item_text_id")
              .attr("style", "display:none !important");
          }
        }
      );

      // 質問形式変更時
      // UIパーツの表示切り替え
      $(document).on("change", ".trigger-answer_type", function () {
        const answer_type_id = `#${$(this).attr("id")}`;
        const no = answer_type_id.replace("#answer_type_", "");
        const text = $(`${answer_type_id} option:selected`).text();
        const $itemBlock = $(`#item_block_${no}`);
        const $defaultRowSize = $(`.default-row-size.default-row-size__${no}`);

        switch (text) {
          case ANSWER_TYPE__RADIO_BUTTON:
          case ANSWER_TYPE__SELECT_BOX:
            $itemBlock.show();
            $itemBlock.find(".item_next_question").show();
            $itemBlock
              .find(".item_text_id")
              .attr("style", "display:none !important");
            $itemBlock.find("input").prop("disabled", false);
            $defaultRowSize.hide();
            break;
          case ANSWER_TYPE__TEXT_BOX:
            $defaultRowSize.show();
            $itemBlock.hide();
            $itemBlock.find("input").prop("disabled", true);
            break;
          case ANSWER_TYPE__CHECK_BOX:
            $defaultRowSize.hide();
            $itemBlock.show();
            $itemBlock
              .find(".item_next_question")
              .attr("style", "display:none !important");
            $itemBlock
              .find(".item_text_id")
              .attr("style", "display:none !important");
            $itemBlock.find("input").prop("disabled", false);
            $itemBlock.find(".item_next_question").prop("disabled", true);
            break;
          case ANSWER_TYPE__TABLE:
            $defaultRowSize.show();
            $itemBlock.show();
            $itemBlock
              .find(".item_next_question")
              .attr("style", "display:none !important");
            $itemBlock.find("input").prop("disabled", false);
            $itemBlock.find(".item_text_id").show();
            break;
        }
      });

      // 質問並び順変更
      const update_sort_no = (ui) => {
        $(".edit_interview").find(".interview_question_sort_no").each(function(index, element){
          var count = index + 1;
          $(element).val(count);
        });
      }

      $(document).ready(function(){
        update_sort_no();
        $(".edit_interview").sortable({
          items: '.sortable_block', // 特定の要素のみ対象
          axis: "y", // ドラッグの方向を縦に固定
          "opacity": 0.5, // ドラッグ中の透明度
          "update": function(event,ui){ // ドラッグ完了後のコールバック
            update_sort_no(ui);
          }
        });
      });

      // 選択肢並び順変更
      const update_item_sort_no = () => {
        $(".item_block").each(function(item_index, item){
          $(item).find(".interview_question_item_sort_no").each(function(index, element){
            element.value = index + 1;
          });
        });
      }

      $(document).ready(function(){
        update_item_sort_no();
        $(".item_block").sortable({
          items: '.sortable_block', // 特定の要素のみ対象
          axis: "y", // ドラッグの方向を縦に固定
          "opacity": 0.5, // ドラッグ中の透明度
          "update": function(event,ui){ // ドラッグ完了後のコールバック
            update_item_sort_no();
          }
      });

    });

    });
  }

  render() {
    return null;
  }
}

export default hot(module)(InterviewBackgroundContainer);
