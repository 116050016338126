// noinspection FunctionNamingConventionJS
export function reports_analysis() {
  const element = $("#reports-analysis");
  const report_id = element.data("id");
  const company_name = element.data("title");

  const finish = () => {
    let url = `/reports/${report_id}/crawl_finish`;
    $.ajax({ type: "GET", dataType: "json", url });
  };

  const kaiseki = setInterval(
    () =>
      $.ajax({
        type: "GET",
        dataType: "json",
        url: `/reports/${report_id}/check_status`,
      }).done((data) => {
        switch (data.response) {
          case "success":
            element.find(".kaiseki-title").text(`${company_name}の解析結果`);
            element
              .find(".kaiseki-body")
              .html(
                `<div class="kaiseki-success"><a href="/reports/${report_id}/lists" class="btn btn-info">解析結果</a></div>`
              );
            element.find(".kaiseki-message").text("解析が終了しました");
            element.find(".kaiseki-text").removeClass("blink");
            finish();
            clearInterval(kaiseki);
            break;
          case "failed":
            element.find(".kaiseki-title").text(`${company_name}の解析結果`);
            element
              .find(".kaiseki-body")
              .html('<div class="kaiseki-failed red">解析に失敗しました</div>');
            element.find(".kaiseki-message").text("");
            element.find(".kaiseki-text").removeClass("blink");
            finish();
            clearInterval(kaiseki);
            break;
          case "scraped":
            element.find(".kaiseki-message").text("解析をしています");
            break;
          default:
            $.ajax({
              type: "GET",
              dataType: "json",
              url: `/reports/${report_id}/check_crawl`,
            }).done((c_data) => element.find(".kaiseki-message").text(c_data.response));
        }
      }),
    3000
  );
}

global.reports_analysis = reports_analysis;
