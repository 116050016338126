// noinspection FunctionNamingConventionJS
export function similarity_request() {
  const config = $("#similarity-request");
  const report_id = config.data("report-id");
  const request_id = config.data("request-id");
  const next_link = config.data("next-link");
  const back_link = config.data("back-link");

  const element = $("#trigger--parse_website");
  const parse_text = $("#trigger--parse_text");
  const parse_progress = $("#trigger--parse_progress");
  const parse_crawls = $("#trigger--parse_crawls");

  // noinspection JSUnusedLocalSymbols
  const parseWebsite = setInterval(
    () =>
      $.ajax({
        type: "GET",
        url: `/reports/${report_id}/longlists/${request_id}/status`,
        dataType: "json",
      })
        .done((data) => {
          if (data.finished) {
            if (data.status === "success") {
              element.html(
                "<i class='fa fa-check fs-72 green mt-5 mb-3'></i><h2 class='green'><strong>類似解析が完了しました</strong></h2><div class'fs-18'>間も無く画面が移動します...</div>"
              );
              setTimeout(() => {
                location.href = next_link;
              }, 3000);
            } else {
              element.html(
                "<i class='fa fa-minus fs-72 red mt-5 mb-3'></i><h2 class='red'><strong>解析に失敗しました<strong></h2>"
              );
              setTimeout(() => {
                location.href = back_link;
              }, 3000);
            }
          } else {
            parse_text.text(data.message);

            if (data.progress > 0) {
              parse_progress.text(`${String(data.progress)}% ...`);
            } else {
              $.ajax({
                type: "GET",
                dataType: "json",
                url: `/reports/${report_id}/longlists/${request_id}/crawls`,
              })
                .done((data) =>
                  $.each(data, (index, value) => {
                    const event_name = `event--crawls_${String(index)}`;
                    if (parse_crawls.find(`#${event_name}`).length === 0) {
                      parse_crawls.prepend(
                        `<li class='list-group-item mb-1' id='${event_name}'>${value} <strong>のデータを取得しました</strong></li>`
                      );
                      $(`#${event_name}`)
                        .hide()
                        .effect("slide", "slow")
                        .effect("highlight", "slow");
                    }
                  })
                )
                .fail((_data) => console.log("error"));
            }
          }
        })
        .fail((_data) => {
          element.html(
            "<i class='fa fa-minus fs-72 red mt-5 mb-3'></i><h2 class='red'><strong>解析に失敗しました<strong></h2>"
          );
          setTimeout(() => {
            location.href = back_link;
          }, 3000);
        }),
    3000
  );
}

global.similarity_request = similarity_request;
