import React from "react";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Button from "@material-ui/core/Button";
import deepOrange from "@material-ui/core/colors/deepOrange";
import grey from "@material-ui/core/colors/grey";
import { ReferenceAreaAction } from "../../../../actions/financialActions/financialStatements/ReferenceAreaAction";
import { ocrResultSelector } from "../../../../selectors/financial/financialStatements/ocrResultSelector";
import { OCR_RESULT_CATEGORY } from "../../../../constants";

class OcrResult extends React.Component {
  state = { isCollapsed: true };
  constructor(props) {
    super(props);

    const { financialStatementScreenFixes } = props;
    const { category } = financialStatementScreenFixes;
    this.unit = this.props.unit;
    this.result = this.props.result || [];
    this.index = this.props.index;
    this.dragRefs = [];
    for (let i = 0; i < this.result.length; i++) {
      this.dragRefs.push(React.createRef());
    }
    this.category = category;
    this.handleClickItemCheck = this.handleClickItemCheck.bind(this);
    this.handleInit(props.index, this.result);
    this.autoSagInput = this.autoSagInput.bind(this);
    this.autoInput = this.autoInput.bind(this);
  }

  //　以下、ロジック
  // ==========================================================================

  // Drag開始用の関数
  handleDragStart(e, i) {
    this.props.assignReferenced(this.props.index, i);
    const name = this.dragRefs[i].current.dataset.name;
    let value1 = this.dragRefs[i].current.dataset.value1;
    value1 *= this.calcMagnificationForDrag();
    const data = JSON.stringify({
      name,
      book_v: value1,
      diff_v: 0,
      fixed_v: value1,
      comment: "",
      addedIn: "book",
    });
    e.dataTransfer.setData("text/plain", data);
  }

  handleDragEnd(e, i) {
    if (e.dataTransfer.dropEffect === "none") {
      this.props.removeReferenced(this.props.index, i);
    } else {
      this.props.assignReferenced(this.props.index, i);
    }
  }
  handleClickItemCheck(i, isReferenced) {
    if (isReferenced) {
      this.props.removeReferenced(this.props.index, i);
    } else {
      this.props.assignReferenced(this.props.index, i);
    }
  }

  handleInit(index, result) {
    this.props.handleInit(index, result);
  }

  /**
   * 同科目名に自動反映
   * params>categoryの値によって呼び出すactionを変更する
   */
  autoInput() {
    const {
      autoInput,
      index,
      financialStatements,
      financialStatementScreenFixes,
    } = this.props;
    const { structure } = financialStatementScreenFixes;

    autoInput(index, financialStatements, this.category, structure);
  }

  /**
   *  販売費及び管理費に反映
   */
  autoSagInput() {
    const { autoSagInput, index, financialStatements } = this.props;
    autoSagInput(index, financialStatements, this.category);
  }

  calcMagnificationForDrag() {
    return this.getUnitFromOcrResult() / this.getUnitFromTarget();
  }
  getUnitFromTarget() {
    const unitSelected = document.getElementById("financial_statement_fix_unit")
      .value;
    return this.convertUnitStrToNum(unitSelected);
  }
  getUnitFromOcrResult() {
    return this.convertUnitStrToNum(this.unit);
  }
  convertUnitStrToNum(str) {
    switch (str) {
      case "円":
        return 1.0;
      case "千円":
        return 1000.0;
      case "百万円":
        return 1000000.0;
      default:
        return 1.0;
    }
  }

  // 以下、コンポーネント
  // =====================================================================

  renderOcrResult(i) {
    const result = this.result.map((item) => ({
      ...item,
      isReferenced: !!item.isReferenced,
    }));
    let rowStyle = { cursor: "pointer" };
    const isReferenced = result[i].isReferenced;
    if (isReferenced) {
      rowStyle["opacity"] = 0.5;
    }
    const value_for_display =
      result[i]["金額1"]
        .replace(/[^\d.-]/g, "")
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") || "";
    return (
      <React.Fragment key={i}>
        <tr
          draggable={true}
          style={rowStyle}
          onDragStart={(e) => this.handleDragStart(e, i)}
          onDragEnd={(e) => this.handleDragEnd(e, i)}
          onClick={() => this.handleClickItemCheck(i, isReferenced)}
          ref={this.dragRefs[i]}
          data-name={result[i]["項目名"]}
          data-value1={result[i]["金額1"]}
        >
          <td className="bg-fsf0 text-center" width={"10%"} colSpan={1}>
            <DragHandleIcon />
          </td>
          <td className="text-center check_boxes" width={"10%"} colSpan={3}>
            <label
              className="check-modify"
              htmlFor={`result_${this.index}_${i}`}
            />
            <input
              type="checkbox"
              id={`result_${this.index}_${i}`}
              checked={result[i].isReferenced}
              readOnly
            />
          </td>
          <td
            width={"46%"}
            colSpan={5}
            style={{ paddingLeft: "0.5rem", paddingTop: "6px" }}
          >
            {result[i]["項目名"]}
          </td>
          <td colSpan={3} style={{ paddingLeft: "0.5rem", paddingTop: "6px" }}>
            {value_for_display}
          </td>
          {/* <td>{result[i]["value2"]}</td> */}
        </tr>
      </React.Fragment>
    );
  }

  renderOcrResults() {
    let list = [];
    const result = this.result;
    if (![[], undefined, null].includes(result)) {
      if (result.length > 1) {
        result.forEach((v, i) => list.push(this.renderOcrResult(i)));
      } else if (result.length === 1) {
        list.push(this.renderOcrResult(0));
      }
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  render() {
    this.result = this.props.financialStatements.referenceArea.ocrResultList[
      this.props.index
    ];
    const unit = this.props.unit;
    const file_name = this.props.file_name;
    if (this.state.isCollapsed) {
      return (
        <div className="contents-block">
          <div className="report-title d-inline">{file_name}</div>
          <button
            onClick={() => this.setState({ isCollapsed: false })}
            type="button"
            className="float-right"
          >
            開く
          </button>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="contents-block">
          <div className="report-title d-inline">{file_name}</div>
          <button
            onClick={() => this.setState({ isCollapsed: true })}
            type="button"
            className="float-right"
          >
            閉じる
          </button>
          <div className="clearfix" />
          <div className="float-right mt-2">
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: deepOrange["A400"], color: "white" }}
              onClick={this.autoInput}
            >
              同科目名に自動反映
            </Button>
            {this.category ===
              OCR_RESULT_CATEGORY.PROFIT_AND_LOSS_STATEMENT && (
              <Button
                variant="contained"
                size="small"
                className="ml-2"
                style={{ backgroundColor: grey["A400"], color: "white" }}
                onClick={this.autoSagInput}
              >
                販売費及び管理費に反映
              </Button>
            )}
          </div>
          <div className="clear-fix"></div>
          <div>
            <table className="table table-sm mb-0">
              <thead className="text-light bg-fsf2">
                <tr className="text-left">
                  <td colSpan={2} width={"20%"}>
                    <p className="mt-2 text-right mr-2">チェック</p>
                  </td>
                  <td width={"40%"} colSpan={5}>
                    <p className="mt-2">項目名</p>
                  </td>
                  <td colSpan={5} className="mt-2">
                    <p className="mt-2">金額(単位：{unit})</p>
                  </td>
                </tr>
              </thead>
            </table>
            <div className="scroll-area mt-0">
              <table className="table table-sm">
                <tbody>{this.renderOcrResults()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <input
          type="hidden"
          name={`financial_statement_fix[ocr_result][${this.props.index}]`}
          value={JSON.stringify(this.result || "")}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleInit(index, result) {
      dispatch(ReferenceAreaAction.referenceAreaInitProps(index, result));
    },

    /**
     * 同科目名に自動反映
     * @param index
     * @param financialStatements
     * @param category
     * @param valuableItemStructure
     */
    autoInput(index, financialStatements, category, valuableItemStructure) {
      dispatch(
        ReferenceAreaAction.referenceAreaAutoInput(
          index,
          financialStatements,
          category,
          valuableItemStructure
        )
      );
    },

    /**
     * 販売費及び管理費に反映
     * @param index [Number]
     * @param financialStatements
     * @param category
     */
    autoSagInput(index, financialStatements, category) {
      dispatch(
        ReferenceAreaAction.referenceAreaAutoSagInput(
          index,
          financialStatements,
          category
        )
      );
    },
    removeReferenced(image_index, item_index) {
      dispatch(
        ReferenceAreaAction.referenceAreaRemoveReferenced(
          image_index,
          item_index
        )
      );
    },
    assignReferenced(image_index, item_index) {
      dispatch(
        ReferenceAreaAction.referenceAreaAssignReferenced(
          image_index,
          item_index
        )
      );
    },
  };
}

export default hot(module)(
  connect(ocrResultSelector, mapDispatchToProps)(OcrResult)
);
