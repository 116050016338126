import { map, filter } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import {
  BS_INIT_PROPS,
  BS_ADD_SMALL_ITEM,
  BS_ADD_MIDDLE_ITEM,
  BS_ADD_LARGE_ITEM,
  BS_REMOVE_SMALL_ITEM,
  BS_REMOVE_MIDDLE_ITEM,
  BS_REMOVE_LARGE_ITEM,
  BS_EDIT_SMALL_ITEM,
  BS_EDIT_MIDDLE_ITEM,
  BS_EDIT_LARGE_ITEM,
  BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  BS_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS,
  BS_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS,
  BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS,
  BS_AUTO_INPUT_BY_OCR_RESULT,
  BalanceSheetAction,
} from "../../actions/financialActions/financialStatements/BalanceSheetAction";

const balanceSheetAddSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_ADD_SMALL_ITEM),
    map((_action) =>
      BalanceSheetAction.bsCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetAddMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_ADD_MIDDLE_ITEM),
    map((_action) =>
      BalanceSheetAction.bsCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetRemoveSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_REMOVE_SMALL_ITEM),
    map((_action) =>
      BalanceSheetAction.bsCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetRemoveMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_REMOVE_MIDDLE_ITEM),
    map((_action) =>
      BalanceSheetAction.bsCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetRemoveLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_REMOVE_LARGE_ITEM),
    map((_action) =>
      BalanceSheetAction.bsCalcCategoryItemsByLargeItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetEditSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_EDIT_SMALL_ITEM),
    map((_action) =>
      BalanceSheetAction.bsCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetEditMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_EDIT_MIDDLE_ITEM),
    map((_action) => {
      return BalanceSheetAction.bsCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.balanceSheet.book
      );
    })
  );

const balanceSheetEditLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_EDIT_LARGE_ITEM),
    map((_action) => {
      return BalanceSheetAction.bsCalcCategoryItemsByLargeItems(
        state$.value.financialStatements.balanceSheet.book
      );
    })
  );

const balanceSheetCalcMiddleItemsBySmallItemsEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS),
    map((_action) =>
      BalanceSheetAction.bsCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

const balanceSheetCalcCategoryItemsByLargeItemsEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS),
    map((_action) =>
      BalanceSheetAction.bsCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.balanceSheet.book
      )
    )
  );

export const balanceSheetEpic = combineEpics(
  balanceSheetAddSmallItemEpic,
  balanceSheetAddMiddleItemEpic,
  balanceSheetRemoveSmallItemEpic,
  balanceSheetRemoveMiddleItemEpic,
  balanceSheetRemoveLargeItemEpic,
  balanceSheetEditSmallItemEpic,
  balanceSheetEditMiddleItemEpic,
  balanceSheetEditLargeItemEpic,
  balanceSheetCalcMiddleItemsBySmallItemsEpic,
  balanceSheetCalcCategoryItemsByLargeItemsEpic
);
