import React, { useState, useRef, useEffect } from "react";
import { hot } from "react-hot-loader";
import InputNumberBase from "../../../../../tools/InputNumber";

const InputNumber = (props) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef(null);
  const isInitialMount = useRef(true);
  const isSecondMount = useRef(true);
  const [timer, setTimer] = useState(null);

  //編集時のエフェクト
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (props.value !== value) {
        if (inputRef.current) {
          if (!inputRef.current.className.match("editting")) {
            inputRef.current.className += " editting";
          }
        }
        clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            inputRef.current.className = inputRef.current.className.replace(
              "editting",
              ""
            );
          }, 1500)
        );
      }
      setValue(props.value);
    }
  });

  return <InputNumberBase {...props} inputRef={inputRef} />;
};

export default hot(module)(InputNumber);
