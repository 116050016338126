import { createStructuredSelector } from "reselect";
import { financialStatementSelector } from "./financialStatementSelector";
import {financialStatementScreenFixesSelector} from "../financialStatementScreens/financialStatementScreenFixesSelector";

/**
 * OcrResultSelector
 */
export const ocrResultSelector = createStructuredSelector({
  financialStatements: financialStatementSelector,
  financialStatementScreenFixes: financialStatementScreenFixesSelector,
});
