import React from "react"
import * as PropTypes from "prop-types"

import {Provider} from "react-redux";
import financialStore from "../../stores/financialStore"

const store = financialStore();

class FinancialAppProvider extends React.Component {
    render() {
        return <Provider store={store}>
            {this.props.children}
        </Provider>;
    }
}

FinancialAppProvider.propTypes = {
    children: PropTypes.node,
};

export default FinancialAppProvider;

