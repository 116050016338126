/**
 * 初期表示の際に任意で入力値がないものについては、非表示とする
 */
function init() {
  const secondary_rows = $(
    "#nonnames_form #contents_table .badge-secondary"
  ).parents("#nonnames_form #contents_table .row");
  secondary_rows.hide();

  secondary_rows.each(function () {
    const textarea = $(this).find("textarea").val();
    const text = $(this).find("input").val();
    if (textarea) {
      $(this).find("textarea").parents(".row").show();
    } else if (text) {
      $(this).find("input").parents(".row").show();
    } else {
    }
  });
}

/**
 * 追加項目のset
 */
function setAddition(currentAdditionCount, trashIcon) {
  let additionCount = currentAdditionCount;
  const addition = $("#nonnames_form").data("addition");
  if (addition && addition.length !== 0) {
    $.each(addition, (index, key) => {
      additionCount = addRow(additionCount, trashIcon);
      $("#nonnames_form #contents_table tbody tr:last .addition-title").val(
        key["title"]
      );
      $("#nonnames_form #contents_table tbody tr:last .addition-body").val(
        key["body"]
      );
    });
  }
  return additionCount;
}

/**
 * 追加項目の追加
 */
function addRow(currentAdditionCount, trashIcon) {
  let additionCount = currentAdditionCount + 1;

  $("#nonnames_form #contents_table tbody").append(
    '<tr class="row addition-row"/>'
  );
  $("#nonnames_form #contents_table tbody tr:last")
    .append(
      `<td class="col-2 mt-2"><input class="form-control addition_count addition-title" name="addition[${additionCount}][title]"/></td>`
    )
    .append(
      `<td class="col-5"><textarea class="form-control addition-body" name="addition[${additionCount}][body]"/></td>`
    )
    .append('<td class="col-5"></td>');
  $("#nonnames_form #contents_table tbody tr:last td:last").append(
    trashIcon.clone()
  );
  return additionCount;
}

/**
 * 処理の実行
 */
function submitProcess() {
  $.each($("#nonnames_form #contents_table tbody .addition-row"), function () {
    const titleLength = $(this).find(".addition-title:input").val().length;
    const bodyLength = $(this).find(".addition-body:input").val().length;
    if (titleLength === 0 && bodyLength === 0) {
      $(this).remove();
    }
  });
}

$(() => {
  let additionCount = $("#nonnames_form #contents_table .addition_count")
    .length;
  const trashIcon = $("#nonnames_form #trash_icon").children();

  init();

  // 削除するをクリックした際に入力値をクリアして行を非表示にする
  $(document).on("click", "#nonnames_form .none-row", function () {
    const secondary_row = $(this).parents(".row");
    secondary_row.find("input").val("");
    secondary_row.find("textarea").val("");
    secondary_row.hide();
  });

  // 項目追加のモーダルのテキストをクリックした際にその行を追加
  $(document).on("click", "#select_text .select-text", function () {
    const secondary_rows = $(
      "#nonnames_form #contents_table .badge-secondary"
    ).parents("#nonnames_form #contents_table .row");
    const select_text = $(this).data("title");
    secondary_rows.each(function () {
      const nonname_title = $(this).find(".nonname-title").text();
      if (select_text === nonname_title) {
        $(this).show();
        $(this).effect("highlight");
      }
    });
  });

  // モーダルを表示時に表示されている項目についてはチェックをつける
  $(document).on("click", "#nonnames_form #nonname_modal", () => {
    const visible_rows = $(
      "#nonnames_form #contents_table .badge-secondary"
    ).parents("#nonnames_form #contents_table .row:visible");
    $("#select_text .check").hide();
    visible_rows.each(function () {
      const nonname_title = $(this).find(".nonname-title").text();
      $("#select_text .select-text").each(function () {
        if (nonname_title === $(this).data("title")) {
          $(this).prev(".check").show();
        }
      });
    });
  });

  additionCount = setAddition(additionCount, trashIcon);

  $(document).on("click", "#select_text #trigger--add_row", () => {
    additionCount = addRow(additionCount, trashIcon);
  });

  $(document).on("click", "#nonnames_form .remove-row", function () {
    $(this).parents("tr").remove();
  });

  $("#nonnames-edit form").submit(() => submitProcess());

  $("#nonnames-new form").submit(() => submitProcess());

  $(document).on(
    "click",
    "#nonnames-new #nonname_reflection, #nonnames-edit #nonname_reflection",
    function () {
      const element = $(this);
      const reportId = element.data("report-id");
      const nonnameId = element.data("nonname-id");
      $.ajax({
        type: "GET",
        url: `/reports/${reportId}/nonnames/nonname_reflection`,
        data: {
          report_id: reportId,
          nonname_id: nonnameId,
        },
        dataType: "json",
        success: (response) =>
          $.each(response, (result, content) =>
            $.each(content, (content_title, value) => {
              if (value !== "") {
                $(`#nonnames_form #body_${content_title}_name`)
                  .val(value)
                  .parents(".row")
                  .show();
              }
            })
          ),
        error: () => console.log("error"),
      });
    }
  );
});
