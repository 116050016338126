import React from 'react';
import { hot } from 'react-hot-loader'
import {
  InputName,
  InputBookV,
  InputDiffV,
  InputFixedV,
  InputComment,
  AddButton,
  DeleteButton,
}
  from '../input'

const MiddleItemTr = props => {
  const has_items_small = props.items_small.length > 0
  const addedInFix = props.addedIn == "fix"

  //子要素がある　=> コメントのみ編集可能       x    追加     x    x     x      o      has_items_small
  //子要素がない => name以外編集可能          x    追加      x    o     o      o      !has_items_small
  //fixで追加されたもの => 全て編集可能       o     削除      x     o     o      o      addedInFix
  return (
    <React.Fragment>
      {has_items_small && !addedInFix && <MiddleItemTrWithItemsSmall {...props} />}
      {!has_items_small && !addedInFix && <MiddleItemTrWithoutItemsSmall {...props} />}
      {addedInFix && <MiddleItemTrAddedInFix {...props} />}
    </React.Fragment>
  );
}

const MiddleItemTrBase = props => {
  return (
    <tr>
      <td colSpan={5} className="indent-2">{props.inputName}</td>
      <td colSpan={1} className="icon">{props.iconButton}</td>
      <td colSpan={4}>{props.inputBookV}</td>
      <td colSpan={1} className="text-center">+</td>
      <td colSpan={3}>{props.inputDiffV}</td>
      <td colSpan={1} className="text-center">=</td>
      <td colSpan={3}>{props.inputFixedV}</td>
      <td colSpan={4}>{props.inputComment}</td>
    </tr>
  );
}

const MiddleItemTrWithoutItemsSmall = props => {
  return (
    <MiddleItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="middle" />}
      iconButton={
        <AddButton hasInfo={false} onClick={(e) => props.addSmallItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="middle" />}
      inputDiffV={
        <InputDiffV
          {...props}
          value={props.diff_v}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
      inputFixedV={
        <InputFixedV
          {...props}
          value={props.fixed_v}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
      inputComment={
        <InputComment
          {...props}
          value={props.comment}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
    />
  );
}

const MiddleItemTrWithItemsSmall = props => {
  return (
    <MiddleItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="middle" />}
      iconButton={
        <AddButton
          hasInfo={true}
          onClick={(e) => props.addSmallItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="middle" />}
      inputDiffV={
        <InputDiffV
          {...props}
          value={props.diff_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="middle" />}
      inputFixedV={
        <InputFixedV
          {...props}
          value={props.fixed_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="middle" />}
      inputComment={
        <InputComment
          {...props}
          value={props.comment}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
    />
  );
}

const MiddleItemTrAddedInFix = props => {
  return (
    <MiddleItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
      iconButton={
        <DeleteButton
          onClick={(e) => props.removeMiddleItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="middle" />}
      inputDiffV={
        <InputDiffV
          {...props}
          value={props.diff_v}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
      inputFixedV={
        <InputFixedV
          {...props}
          value={props.fixed_v}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
      inputComment={
        <InputComment
          {...props}
          value={props.comment}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle" />}
    />
  );
}

MiddleItemTr.defaultProps = {
  name: "",
  book_v: null,
  diff_v: null,
  fixed_v: null,
  comment: "",
  code: "",
  addedIn: "default",
}

export default hot(module)(MiddleItemTr);
