import {
  FINANCIAL_STATEMENT_IMAGE_INIT_PROPS,
  FINANCIAL_STATEMENT_IMAGE_INIT_OCR_LIMITED_COUNT,

  FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK,
  FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_SUCCESS,
  FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_FAILURE,

  FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_GET_STATUS_SUCCESS,
  FINANCIAL_STATEMENT_IMAGE_GET_STATUS_FAILURE,

  FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_GET_RESULT_SUCCESS,
  FINANCIAL_STATEMENT_IMAGE_GET_RESULT_FAILURE,

  FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN,
  FINANCIAL_STATEMENT_IMAGE_MODAL_CLOSE,
  FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK,
  FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST,
  FINANCIAL_STATEMENT_IMAGE_REANALYZE_SUCCESS,
  FINANCIAL_STATEMENT_IMAGE_REANALYZE_FAILURE,

  FINANCIAL_STATEMENT_IMAGE_SELECT_IMAGE,
} from "../../../actions/financialActions/financialStatementImages/Main"

function financialStatementImageReducer(state = {}, action) {

  switch (action.type) {

    // 初期化関連
    case FINANCIAL_STATEMENT_IMAGE_INIT_PROPS:
      return { ...state, ...{ fs_images: action.fs_images } };
    case FINANCIAL_STATEMENT_IMAGE_INIT_OCR_LIMITED_COUNT:
      return { ...state, ...{ ocrLimitedCount: action.ocrLimitedCount } };


    // OCR解析開始
    case FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...fs_image, is_loading: action.is_loading, ajax_text: action.ajax_text } : fs_image)
        }
      }
    case FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...fs_image, is_loading: action.is_loading, ajax_text: action.ajax_text } : fs_image)
        }
      }
    case FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_SUCCESS:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...action.dataChanged, is_loading: action.is_loading, ajax_text: action.ajax_text } : fs_image),
        }, ...{ ocrLimitedCount: action.ocrLimitedCount }
      };
    case FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_FAILURE:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...fs_image, is_loading: action.is_loading, ajax_text: action.ajax_text } : fs_image)
        }
      }


    // 解析ステータスの取得(バックエンドで解析結果の取得)
    case FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...fs_image, is_loading: action.is_loading } : fs_image)
        }
      };
    case FINANCIAL_STATEMENT_IMAGE_GET_STATUS_SUCCESS:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...action.dataChanged, is_loading: action.is_loading, ajax_text: action.ajax_text } : fs_image),
        }
      };
    case FINANCIAL_STATEMENT_IMAGE_GET_STATUS_FAILURE:
      return {
        ...state, ...{
          fs_images: action.fs_images.map(fs_image => fs_image.fs_image_id === action.fs_image_id ? { ...fs_image, is_loading: action.is_loading, ajax_text: action.ajax_text, status: action.status } : fs_image)
        }
      };


    // モーダル関連
    case FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_open: action.is_open, fs_image: action.fs_image, ajax_text: action.ajax_text }
      };

    case FINANCIAL_STATEMENT_IMAGE_MODAL_CLOSE:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_open: action.is_open }
      };

    case FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_open: action.is_open, is_loading: action.is_loading, ajax_text: action.ajax_text }
      };
    case FINANCIAL_STATEMENT_IMAGE_GET_RESULT_SUCCESS:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, ocrResult: action.ocrResult, is_loading: action.is_loading, ajax_text: action.ajax_text }
      };
    case FINANCIAL_STATEMENT_IMAGE_GET_RESULT_FAILURE:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_loading: action.is_loading, ajax_text: action.ajax_text }
      };

    // 再解析
    case FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_loading: action.is_loading, ajax_text: action.ajax_text }
      }

    case FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_loading: action.is_loading, ajax_text: action.ajax_text }
      };
    case FINANCIAL_STATEMENT_IMAGE_REANALYZE_SUCCESS:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, ocrResult: action.ocrResult, is_loading: action.is_loading, ajax_text: action.ajax_text }
      };
    case FINANCIAL_STATEMENT_IMAGE_REANALYZE_FAILURE:
      return {
        ...state,
        modalStatus: { ...state.modalStatus, is_loading: action.is_loading, ajax_text: action.ajax_text }
      };


    // 画像の選択
    case FINANCIAL_STATEMENT_IMAGE_SELECT_IMAGE:
      return { ...state, ...{ fs_images: action.fs_images } };

    default:
      return state

  }
}

export default financialStatementImageReducer;
