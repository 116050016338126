import {
  REFERENCE_AREA_INIT_PROPS,
  REFERENCE_AREA_CHANGE_PROPS,
  REFERENCE_AREA_REMOVE_REFERENCED,
  REFERENCE_AREA_ASSIGN_REFERENCED,
} from "../../../actions/financialActions/financialStatements/ReferenceAreaAction"

function referenceAreaReducer(state = {}, action) {
  let tmp_state = { ...state }
  switch (action.type) {
    case REFERENCE_AREA_INIT_PROPS:
      return {
        ...state, ...{
          ocrResultList: {
            ...state.ocrResultList, ...{ [action.index]: action.body }
          }
        }
      };


    case REFERENCE_AREA_CHANGE_PROPS:
      return {
        ...state, ...{
          ocrResultList: {
            ...state.ocrResultList,
            ...{ [action.index]: action.body }
          }
        }
      };
    
    case REFERENCE_AREA_REMOVE_REFERENCED:
      tmp_state.ocrResultList[action.image_index][action.item_index]["isReferenced"] = false;
      return tmp_state;

    case REFERENCE_AREA_ASSIGN_REFERENCED:
      tmp_state.ocrResultList[action.image_index][action.item_index]["isReferenced"] = true;
      return tmp_state;
    
    default: 
      return state
  }
}

export default referenceAreaReducer;
