export const PL_FIX_INIT_PROPS = "PL_FIX_INIT_PROPS";
export const PL_FIX_ADD_SMALL_ITEM = "PL_FIX_ADD_SMALL_ITEM";
export const PL_FIX_ADD_MIDDLE_ITEM = "PL_FIX_ADD_MIDDLE_ITEM";
export const PL_FIX_ADD_LARGE_ITEM = "PL_FIX_ADD_LARGE_ITEM";
export const PL_FIX_REMOVE_SMALL_ITEM = "PL_FIX_REMOVE_SMALL_ITEM";
export const PL_FIX_REMOVE_MIDDLE_ITEM = "PL_FIX_REMOVE_MIDDLE_ITEM";
export const PL_FIX_REMOVE_LARGE_ITEM = "PL_FIX_REMOVE_LARGE_ITEM";
export const PL_FIX_EDIT_SMALL_ITEM = "PL_FIX_EDIT_SMALL_ITEM";
export const PL_FIX_EDIT_MIDDLE_ITEM = "PL_FIX_EDIT_MIDDLE_ITEM";
export const PL_FIX_EDIT_LARGE_ITEM = "PL_FIX_EDIT_LARGE_ITEM";
export const PL_FIX_EDIT_OTHER_ITEM = "PL_FIX_EDIT_OTHER_ITEM";

export const PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS =
  "PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS";
export const PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS =
  "PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS";
export const PL_FIX_CALC_SUMS_ITEMS_BY_LARGE_ITEMS =
  "PL_FIX_CALC_SUMS_ITEMS_BY_LARGE_ITEMS";

import { ReducerHelper } from "../../../modules/financialStatements/reducerHelper";

// actionCreator
function plFixInitProps(body) {
  return {
    type: PL_FIX_INIT_PROPS,
    body: body,
  };
}

function plFixAddSmallItem(body, item_small) {
  let items_small = body.items_small;
  items_small.push(item_small);
  body.items_small = items_small;
  return {
    type: PL_FIX_ADD_SMALL_ITEM,
    body,
    item_small,
    items_small,
  };
}

function plFixAddMiddleItem(body, item_middle) {
  let items_middle = body.items_middle;
  items_middle.push(item_middle);
  body.items_middle = items_middle;
  return {
    type: PL_FIX_ADD_MIDDLE_ITEM,
    body,
    item_middle,
    items_middle,
  };
}

function plFixAddLargeItem(body, item_large) {
  let items_large = body.items_large;
  items_large.push(item_large);
  body.items_large = items_large;
  return {
    type: PL_FIX_ADD_LARGE_ITEM,
    body,
    item_large,
    items_large,
  };
}

function plFixRemoveSmallItem(body, small_id) {
  const items_small = ReducerHelper.removeItemById(
    body.items_small,
    "small_id",
    small_id
  );
  return {
    type: PL_FIX_REMOVE_SMALL_ITEM,
    body,
    small_id,
    items_small,
  };
}

function plFixRemoveMiddleItem(body, middle_id) {
  const items_middle = ReducerHelper.removeItemById(
    body.items_middle,
    "middle_id",
    middle_id
  );
  return {
    type: PL_FIX_REMOVE_MIDDLE_ITEM,
    body,
    middle_id,
    items_middle,
  };
}

function plFixRemoveLargeItem(body, large_id) {
  const items_large = ReducerHelper.removeItemById(
    body.items_large,
    "large_id",
    large_id
  );
  return {
    type: PL_FIX_REMOVE_LARGE_ITEM,
    body,
    large_id,
    items_large,
  };
}

function plFixEditSmallItem(body, small_id, name, value) {
  const items_small = ReducerHelper.editItemByIdAndElement(
    body.items_small,
    "small_id",
    small_id,
    name,
    value
  );
  return {
    type: PL_FIX_EDIT_SMALL_ITEM,
    body,
    small_id,
    name,
    value,
    items_small,
  };
}

function plFixEditMiddleItem(body, middle_id, name, value) {
  const items_middle = ReducerHelper.editItemByIdAndElement(
    body.items_middle,
    "middle_id",
    middle_id,
    name,
    value
  );
  body.items_middle = items_middle;
  return {
    type: PL_FIX_EDIT_MIDDLE_ITEM,
    body,
    middle_id,
    name,
    value,
    items_middle,
  };
}

function plFixEditLargeItem(body, large_id, name, value) {
  const items_large = ReducerHelper.editItemByIdAndElement(
    body.items_large,
    "large_id",
    large_id,
    name,
    value
  );
  body.items_large = items_large;
  return {
    type: PL_FIX_EDIT_LARGE_ITEM,
    body,
    large_id,
    name,
    value,
    items_large,
  };
}

function plFixEditOtherItem(body, large_id, name, value) {
  const items_other = ReducerHelper.editItemByIdAndElement(
    body.items_other,
    "large_id",
    large_id,
    name,
    value
  );
  body.items_other = items_other;
  return {
    type: PL_FIX_EDIT_OTHER_ITEM,
    body,
    large_id,
    name,
    value,
    items_other,
  };
}

// 副作用=======================================================

const plFixCalcMiddleItemsBySmallItems = (body) => {
  const items_middle = ReducerHelper.calcItemsByAnotherItems(
    body.items_middle,
    body.items_small,
    "middle_id"
  );
  body.items_middle = items_middle;
  return {
    type: PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
    body,
    items_middle,
  };
};

const plFixCalcLargeItemsByMiddleItemsAndOtherItems = (body) => {
  const items_middle_sums = ReducerHelper.calcItemsByAnotherItems(
    body.items_large,
    body.items_middle,
    "large_id"
  );
  const items_large = ReducerHelper.calcSumBetweenTwoItems(
    items_middle_sums,
    body.items_other
  );
  body.items_large = items_large;
  return {
    type: PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS,
    body,
    items_large,
  };
};

const plFixCalcSumsItemsByLargeItems = (body) => {
  const items_sums = ReducerHelper.plCalcSumsByLargeItems(
    body.items_sums,
    body.items_large
  );
  body.items_sums = items_sums;
  return {
    type: PL_FIX_CALC_SUMS_ITEMS_BY_LARGE_ITEMS,
    body,
    items_sums,
  };
};

export const ProfitAndLossFixAction = {
  plFixInitProps,
  plFixAddSmallItem,
  plFixAddMiddleItem,
  plFixAddLargeItem,
  plFixRemoveSmallItem,
  plFixRemoveMiddleItem,
  plFixRemoveLargeItem,
  plFixEditSmallItem,
  plFixEditMiddleItem,
  plFixEditLargeItem,
  plFixEditOtherItem,
  plFixCalcMiddleItemsBySmallItems,
  plFixCalcLargeItemsByMiddleItemsAndOtherItems,
  plFixCalcSumsItemsByLargeItems,
};
