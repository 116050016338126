import { ArrayTools } from "./arrayTools";
import { Association } from "./associationTools";

/**
 * バランスシート値を表に反映する
 * @param body
 * @param result
 * @param valuableItemStructure
 * @returns {{result: *, items_large_changed: *, body: {items_large: *, items_middle: *, items_small: null, items_other: null, items_category: null}, items_middle_changed: *}}
 */
const convertBsBody = (body, result, valuableItemStructure) => {
  let rResult = { ...result };
  const {
    large: largeStructure,
    middle: middleStructure,
    small: smallStructure,
  } = valuableItemStructure;

  // 大項目で変更点を見つけ表に反映する
  const itemsLarge = body.items_large;
  const itemsLargeChanged = convertBaseFunc(
    rResult,
    itemsLarge,
    largeStructure
  );
  rResult = itemsLargeChanged.result;

  // 中項目で変更点を見つけ表に反映する
  const itemsMiddle = body.items_middle;
  const itemsMiddleChanged = convertBaseFunc(
    rResult,
    itemsMiddle,
    middleStructure
  );
  rResult = itemsMiddleChanged.result;

  // 小項目で変更点を見つけ表に反映する
  const itemsSmall = body.items_small;
  const itemsSmallChanged = convertBaseFunc(
    rResult,
    itemsSmall,
    smallStructure
  );
  rResult = itemsMiddleChanged.result;

  return {
    body: {
      items_category: null,
      items_large: itemsLargeChanged.items,
      items_middle: itemsMiddleChanged.items,
      items_small: itemsSmallChanged.items,
      items_other: null,
    },
    items_large_changed: itemsLargeChanged.items,
    items_middle_changed: itemsMiddleChanged.items,
    items_small_changed: itemsSmallChanged.items,
    result: rResult,
    itemsMiddleAdd: itemsMiddleChanged.addItems,
    itemsSmallAdd: itemsSmallChanged.addItems,
  };
};

/**
 * PL
 * @param body
 * @param result
 * @param valuableItemStructure
 * @returns {{result: *, items_large_changed: *, body: {items_large: *, items_sums: null, items_middle: *, items_small: null, items_other: null}, items_middle_changed: *}}
 */
const convertPlBody = (body, result, valuableItemStructure) => {
  let rResult = { ...result };
  const {
    large: largeStructure,
    middle: middleStructure,
    small: smallStructure,
  } = valuableItemStructure;

  // 大項目で変更点を見つけ表に反映する
  const itemsLarge = body.items_large;
  const itemsLargeChanged = convertBaseFunc(
    rResult,
    itemsLarge,
    largeStructure
  );
  rResult = itemsLargeChanged.result;

  // 中項目で変更点を見つけ表に反映する
  const itemsMiddle = body.items_middle;
  const itemsMiddleChanged = convertBaseFunc(
    rResult,
    itemsMiddle,
    middleStructure
  );
  rResult = itemsMiddleChanged.result;

  // 小項目で変更点を見つけ表に反映する
  const itemsSmall = body.items_small;
  const itemsSmallChanged = convertBaseFunc(
    rResult,
    itemsSmall,
    smallStructure
  );
  rResult = itemsSmallChanged.result;

  return {
    body: {
      items_sums: null,
      items_large: itemsLargeChanged.items,
      items_middle: itemsMiddleChanged.items,
      items_small: itemsMiddleChanged.items,
      items_other: null,
    },
    items_large_changed: itemsLargeChanged.items,
    items_middle_changed: itemsMiddleChanged.items,
    items_small_changed: itemsSmallChanged.items,
    result: rResult,
    itemsMiddleAdd: itemsMiddleChanged.addItems,
    itemsSmallAdd: itemsSmallChanged.addItems,
  };
};

/**
 * 解析結果で表の項目名が一致する要素に値を反映する
 * @param result 解析結果
 * @param itemsAny
 * @param structure
 * @returns {{result: *, items: *}}
 */
const convertBaseFunc = (result, itemsAny, structure) => {
  let itemsAnyChanged = [];
  let addItems = [];
  let itemTmp = {};
  let rResult = { ...result };
  const resultIndex = Object.keys(result);
  const itemAnyNames = Object.values(itemsAny).map((item) => item["name"]);

  if (structure) {
    // 拡張辞書の追加
    resultIndex.forEach((index) => {
      const name = result[index]["項目名"];
      const info = structure[name];

      const has = itemAnyNames.indexOf(name) !== -1;
      if (info && !has) {
        // IDが同じ要素がすでに含まれている
        const matchItem = itemsAny.find((item) => {
          if (info["large"]) {
            return item.middle_id === info["id"];
          } else if (info["middle"]) {
            return item.small_id === info["id"];
          } else if (info["category"]) {
            return item.large_id === info["id"];
          } else {
            return false;
          }
        });

        if (matchItem) {
          itemsAny.forEach((itemAny, anyIndex) => {
            if (
              (info["large"] && info["id"] === itemAny["middle_id"]) ||
              (info["middle"] && info["id"] === itemAny["small_id"]) ||
              info["id"] === itemAny["large_id"]
            ) {
              itemTmp = { ...itemAny };

              // 簿価・評価額を更新する
              itemTmp["book_v"] = parseInt(result[index]["金額1"]);
              itemTmp["fixed_v"] = parseInt(result[index]["金額1"]);
              itemsAnyChanged[anyIndex] = itemTmp;

              // 反映済みフラグを立てる
              rResult[index]["isReferenced"] = true;
            }
          });
        } else {
          // 項目として新しく追加する
          addItems.push({
            book_v: parseInt(result[index]["金額1"]),
            fixed_v: parseInt(result[index]["金額1"]),
            code: info["code"],
            diff_v: "",
            name,
            ...(info["large"]
              ? {
                  large_id: info["large"]["id"],
                  middle_id: info["id"],
                  ...(info["large"]["category"]
                    ? { category_id: info["large"]["category"]["code"] }
                    : {}),
                }
              : {}),
            ...(info["middle"]
              ? {
                  middle_id: info["middle"]["id"],
                  small_id: info["id"],
                  ...(info["middle"]["large"]["category"]
                    ? {
                        category_id:
                          info["middle"]["large"]["category"]["code"],
                      }
                    : {}),
                }
              : {}),
          });

          // 反映済みフラグを立てる
          rResult[index]["isReferenced"] = true;
        }
      }
    });
  }

  // 基本項目の反映
  itemsAny.forEach((item_any, any_index) =>
    resultIndex.forEach((index) => {
      if (result[index]["項目名"] === item_any["name"]) {
        itemTmp = { ...item_any };

        // 簿価・評価額を更新する
        itemTmp["book_v"] = parseInt(result[index]["金額1"]);
        itemTmp["fixed_v"] = parseInt(result[index]["金額1"]);
        itemsAnyChanged[any_index] = itemTmp;

        // 反映済みフラグを立てる
        rResult[index]["isReferenced"] = true;
      }
    })
  );
  return { items: itemsAnyChanged, result: rResult, addItems };
};

/**
 * 解析結果を全て販管費の子要素に
 * @param body
 * @param result 解析結果
 * @returns {{result: *, body: {items_large: null, items_sums: null, items_middle: *, items_small: null, items_other: null}, items_middle_added: *}}
 */
const convertSagBody = (body, result) => {
  const items_large = body.items_large;
  const items_middle = body.items_middle;
  let next_middle_id = Association.getLastIdPlusOne(items_middle, "middle_id");
  const items_middle_added = addItemBase(result, items_middle, next_middle_id);

  return {
    body: {
      items_sums: null,
      items_large: null,
      items_middle: items_middle,
      items_small: null,
      items_other: null,
    },
    items_middle_added: items_middle_added,
    result: items_middle_added.result,
  };
};
// ある項目の補助科目に科目のリストを追加する
const addItemBase = (result, items_any, next_any_id) => {
  let items_any_changed = [];
  let item_tmp = {};
  let rResult = { ...result };
  Object.keys(result).forEach((index) => {
    item_tmp = {};
    item_tmp = {
      large_id: 2,
      middle_id: next_any_id + parseInt(index),
      name: result[index]["項目名"],
      book_v: result[index]["金額1"],
      diff_v: 0,
      fixed_v: result[index]["金額1"],
      addedIn: "book",
    };
    items_any_changed.push({ ...item_tmp });
    // 反映済みフラグを立てる
    rResult[index]["isReferenced"] = true;
  });
  return { items: items_any_changed, result: rResult };
};

export const autoInputReferencer = {
  convertBsBody,
  convertPlBody,
  convertSagBody,
};
