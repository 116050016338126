import React from 'react';
import { hot } from 'react-hot-loader'
import {
  InputName,
  InputBookV,
  InputDiffV,
  InputFixedV,
  InputComment,
  AddButton,
  DeleteButton,
} from '../input'

const SumsItemTr = props => {
  const explainText = convertNameToExplain(props.name);
  return (
    <SumsItemTrBase
      inputName={props.name}
      inputComment={<HTMLComponent htmlstring={explainText}/>}
      {...props}
    />
    );
} 

const SumsItemTrBase = props => {
  const editSmallItem = e => props.editSmallItem(e).bind(this);
  return (
    <tr>
      <td colSpan={5} className="indent-3">
        {props.inputName}
      </td>
      <td colSpan={1} className="icon"></td>
      <td colSpan={4}>
        <InputBookV
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="small"
        />
      </td>
      <td colSpan={1} className="text-center">+</td>
      <td colSpan={3}>
        <InputDiffV
          value={props.diff_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="small"
        />
      </td>
      <td colSpan={1} className="text-center">=</td>
      <td colSpan={3}>
        <InputFixedV
          value={props.fixed_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="small"
        />
      </td>
      <td colSpan={6}>
        {props.inputComment}
      </td>
    </tr>
  )
}

SumsItemTr.defaultProps = {
  book_v: null,
}

function convertNameToExplain(name) {
  switch (name) {
    case "売上総利益":
      return "=【売上高】-【売上原価】";
    case "営業利益":
      return "= 売上総利益 -【販売費及び管理費】";
    case "経常利益":
      return "= 営業利益 +【営業外収益】<br>-【営業外費用】";
    case "税引前当期純利益":
      return "= 経常利益 +【特別利益】<br>-【特別損益】";
    case "当期純利益":
      return "= 税引前当期純利益 <br>-【法人税等合計】";
    default:
      return "";
  };
}
const HTMLComponent = (props) => {
  return(
      <span dangerouslySetInnerHTML={{__html: props.htmlstring}}></span>
  );
}

export default hot(module)(SumsItemTr);
