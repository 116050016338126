import * as math from "mathjs";

//成り行き計算用のjs
export const estm = {
  setProportialValuesToTr: (lastTermVal, tds, grossRate) => {
    let values = estm.calcProportial(tds, lastTermVal, grossRate);
    estm.setValuesToTr(tds, values);
  },

  /**
   * 出力個数分の値を成長率で計算
   */

  calcProportial: (tds, lastTermVal, grossRate) => {
    let r = [];
    tds.each((i, _) =>
      r.push(parseInt(lastTermVal * Math.pow(1 + grossRate * 0.01, i + 1)))
    );
    return r;
  },

  setValuesToTr: (tds, values) =>
    tds.each((i, ele) =>
      $(ele).find("input").val(values[i]).effect("highlight")
    ),

  setLabel: (code, grossRate, _options) => {
    let options;
    if (_options === undefined) {
      options = {};
    } else {
      options = _options;
    }
    let growthLabel = options["growthLabel"] || "";
    let label = growthLabel === "" ? "" : `${growthLabel}: `;
    $(`#base_gross_rate_${String(code)}`).text(`${label + grossRate}%`);
  },

  getLastTermValues: () => ({
    GP_NS: parseInt($("#achievement input[name='subject[GP_NS][0]']").val()),
    GP_CS: parseInt($("#achievement input[name='subject[GP_CS][0]']").val()),
    OP_SA: parseInt($("#achievement input[name='subject[OP_SA][0]']").val()),
    OP_SA_D: parseInt(
      $("#achievement input[name='subject[OP_SA_D][0]']").val()
    ),
    OT_CAPEX: parseInt(
      $("#achievement input[name='subject[OT_CAPEX][0]']").val()
    ),
  }),

  getPlanTds: (fcf_id) => ({
    GP_NS: $(`#base_plan .GP_NS_${String(fcf_id)}`).children("td"),
    GP_CS: $(`#base_plan .GP_CS_${String(fcf_id)}`).children("td"),
    OP_SA: $(`#base_plan .OP_SA_${String(fcf_id)}`).children("td"),
    OP_SA_D: $(`#base_plan .OP_SA_D_${String(fcf_id)}`).children("td"),
    OT_CAPEX: $(`#base_plan .OT_CAPEX_${String(fcf_id)}`).children("td"),
  }),

  getAchieveVals: () => ({
    GP_NS: estm.extractValues($("#achievement_GP_NS").children("td")),
    GP_CS: estm.extractValues($("#achievement_GP_CS").children("td")),
    OP_SA: estm.extractValues($("#achievement_OP_SA").children("td")),
    OP_SA_D: estm.extractValues($("#achievement_OP_SA_D").children("td")),
    OT_CAPEX: estm.extractValues($("#achievement_OT_CAPEX").children("td")),
  }),

  extractValues: (DOM) => {
    let r = [];
    // let v_for_NaN = DOM.parent().attr("id") === "achievement_GP_NS" ? NaN : 0;
    DOM.each((i, ele) => {
      let value = parseInt($(ele).find("input").val());
      if (value === "") {
        value = NaN;
      }
      r.push(value);
    });
    return r;
  },

  fitGP_NSLength: (achieveVals) => {
    let r = { GP_NS: [], GP_CS: [], OP_SA: [], OP_SA_D: [], OT_CAPEX: [] };
    let addFlag = false;
    achieveVals["GP_NS"].forEach((v, i, _) => {
      if (!isNaN(v) || addFlag === true) {
        addFlag = true;
        r["GP_NS"].push(achieveVals["GP_NS"][i]);
        r["GP_CS"].push(achieveVals["GP_CS"][i]);
        r["OP_SA"].push(achieveVals["OP_SA"][i]);
        r["OP_SA_D"].push(achieveVals["OP_SA_D"][i]);
        r["OT_CAPEX"].push(achieveVals["OT_CAPEX"][i]);
      }
    });
    return r;
  },

  // 年成長率に応じて値をセットする
  baseCalcMotion: (code, lastTermVals, planTds, grossRate, baseType) => {
    if (baseType === "all" || baseType === code) {
      estm.setLabel(code, grossRate);
      estm.setProportialValuesToTr(
        lastTermVals[code],
        planTds[code],
        grossRate
      );
    }
  },

  estmCalcMotion: (
    code,
    lastTermVals,
    planTds,
    grossRate,
    estmPlans,
    options
  ) => {
    estm.setLabel(code, grossRate, options);
    estm.setValuesToTr(planTds[code], estmPlans[code]);
  },

  calcGrowthList: (argList) => {
    let growthList = [];
    argList.forEach((v, i, a) => {
      if (i + 1 >= a.length) {
        return true;
      }
      let growth;
      if (a[i] === 0 || a[i + 1] === 0) {
        growthList.push(0);
      } else {
        growth = (a[i + 1] - a[i]) / a[i];
        if (growth >= 0 && growth <= 0.1) {
          growthList.push(growth);
        } else if (growth > 0.1) {
          growthList.push(0.1);
        } else {
          growthList.push(0);
        }
      }
      return null;
    });
    return growthList;
  },

  /**
   * リストから前年度比平均を算出する
   */

  calcGrowthAvg: (argList) => {
    let growthList = estm.calcGrowthList(argList);
    return math.round(math.mean(growthList), 4);
  },

  calcVersusRatioList: (targetList, opponentList) => {
    let versusRatios = [];
    opponentList.forEach((v, i) => {
      let tmp = v === 0 ? 0 : targetList[i] / v;
      versusRatios.push(tmp);
    });
    return versusRatios;
  },

  /**
   * リストから対売上比、対売上原価比等の平均を算出する
   */
  calcVersusRatioAvg: (targetList, opponentList) => {
    let versusRatios = estm.calcVersusRatioList(targetList, opponentList);
    return math.round(math.mean(versusRatios), 4);
  },

  calcGrowthPlan: (lastTermVal, growthRate) => {
    let r = [];
    let v;
    for (let i = 1; i <= 5; i++) {
      v = math.round(lastTermVal * Math.pow(1.0 + growthRate, i));
      r.push(v);
    }
    return r;
  },

  calcVersusRatioPlan: (opponentList, versusRatio) => {
    let r = [];
    let v;
    for (let i = 0; i <= 4; i++) {
      v = math.round(opponentList[i] * versusRatio);
      r.push(v);
    }
    return r;
  },

  convertNaNtoZero: (asArray) => {
    let r = {};
    for (let key in asArray) {
      let tmpArray = [];
      // noinspection JSUnfilteredForInLoop
      asArray[key].forEach((v) => tmpArray.push(isNaN(v) ? 0 : v));
      // noinspection JSUnfilteredForInLoop
      r[key] = tmpArray;
    }
    return r;
  },

  setNumWithITPL: (code, RowDom, achieveVals, achieveVals2, i) => {
    RowDom.append(
      `<td widtg="130" class="text-center"><label>${math.round(
        achieveVals[String(code)][i]
      )}</label><br></td>`
    );
    if (achieveVals2[String(code)][i] !== achieveVals[String(code)][i]) {
      RowDom.children("td:last")
        .children("label:first")
        .addClass("text-danger font-weight-bold");
    }
  },
};
