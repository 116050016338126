/**
 * テキストエリア
 */
export function saveTextarea({ $answerBlockVisible, prevArr, submitDisplay }) {
  // テキストエリアが入力されていれば
  if ($answerBlockVisible.data("next_question_number")) {
    // 質問に次の質問IDがあれば
    const next_question_number = $answerBlockVisible.data(
      "next_question_number"
    );

    const $answerEditQuestionNumber = $(
      `#interview_answer_edit #question_${next_question_number}`
    );

    const number = $answerBlockVisible.data("number");
    if ($answerEditQuestionNumber.length) {
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      $answerEditQuestionNumber.fadeIn("slow");
    } else {
      const number = $answerBlockVisible.data("number");
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      submitDisplay();
    }
  } else {
    const number = $answerBlockVisible.data("number");
    prevArr.push(`#question_${number}`);
    $answerBlockVisible.hide();
    submitDisplay();
  }
}
