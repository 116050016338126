$(() => {

    // change_status  # application.js:change_select
    // ============================================================================================
    $(document).on("change", "#projects-show .trigger--change_status", function() {
        changeSelect($(this), "/projects/change_status");
    });

    // change_scheme  # application.js:change_select
    $(document).on("change", "#projects-show .trigger--change_scheme", function() {
        changeSelect($(this), "/projects/change_scheme");
    });

});
