$(() => {
  // 一時的にデータ属性を隠しボタンに渡す。
  $(
    "#financial_statement_fix-index .trigger--click-modal-button, #financial_statement_fix_step_form .trigger--click-modal-button"
  ).on("click", function () {
    const data_period = $(this).data("period");
    const data_category = $(this).data("category");
    const data_fsf_id = $(this).data("fsf_id");

    const modal_button = $("#modal-button");
    modal_button.attr({
      "data-period": data_period,
      "data-category": data_category,
      "data-fsf_id": data_fsf_id,
    });
    modal_button.click();
  });

  $('[data-toggle="tooltip"]').tooltip();
});
