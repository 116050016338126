import React from 'react';
import { hot } from 'react-hot-loader'
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(0),
    fontSize: "0.9rem",
  }
}));

const AddButton = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const color = props.hasInfo ? "primary" : "inherit"
  return (
    <IconButton
      aria-label="info"
      className={classes.margin}
      size="small"
      tabIndex={-1}
      onClick={(e) => props.onClick(e)}
    >
      <AddCircleIcon fontSize="inherit" color={color}  />
    </IconButton>
  );
});

AddButton.defaultProps = {
  onClick: null,
  hasInfo: false,
  onDrop: null,
}

export default hot(module)(AddButton);
