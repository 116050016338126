$(() =>
  $(document).on(
    "click",
    "#schedules-index .trigger--smooth-scroll",
    function () {
      let pos;
      let sh;
      const width = $(window).width();

      const target = $(this).attr("href");
      const th = $(target).position();

      if (width >= 992) {
        const $sidearMain = $(".sidebar-main");
        sh = $sidearMain.scrollTop();
        pos = th.top + sh;
        $sidearMain.animate({ scrollTop: pos - 130 }, "slow", "swing");
      } else {
        sh = $("body").scrollTop();
        pos = th.top + sh;
        $("html,body").animate({ scrollTop: pos - 10 }, "slow", "swing");
        $("#sidebarToggle").prop("checked", false);
      }
    }
  )
);
