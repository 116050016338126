$(() => {
  $(
    "#so_report-show_similarity input[name='similar'], #so_report-approach input[name='similar']"
  ).on("click", function () {
    const element = $(this);
    const report_id = element.data("report-id");
    const company_id = element.data("company-id");

    $.ajax({
      type: "GET",
      url: `/reports/${report_id}/so_report_similarities/${company_id}/toggle`,
      data: { flag: element.prop("checked") },
      dataType: "json",
      success: (_response) => console.log("success"),
      error: (_response) => console.log("error"),
    });
  });

  $("#so_report-form #so_report_expiration_period").on("keyup", function () {
    const element = $(this);
    const btn = $("#calc_exec_at");
    if (element.val() === "") {
      btn.addClass("disabled");
    } else {
      btn.removeClass("disabled");
    }
  });

  $("#so_report-form #calc_exec_at").on("click", () => {
    // const element = $(this);
    const issued_at = $("#so_report_issued_at");
    const expiration_period = $("#so_report_expiration_period");
    const exec_at = $("#so_report_exec_at");

    let date = issued_at.val().split("-");

    date[0] = parseInt(date[0]) + parseInt(expiration_period.val());
    date[0] = String(date[0]);
    console.log(date);
    date = date.join("-");
    exec_at.val(date).effect("highlight");
  });

  $(
    "#so_report-form #volat_quater, #so_report-form #volat_half, #so_report-form #volat_one_year, #so_report-form #volat_one_half"
  ).on("click", function () {
    const element = $(this);
    // hidden
    const volat_end = $("#volat_end");
    // フォームを取得
    const form_volat = $("#so_report_volatility");
    const form_volat_start = $("#so_report_volat_start");
    const form_volat_end = $("#so_report_volat_end");
    const form_calculated_at = $("#so_report_calculated_at");

    form_volat.val(element.data("volat")).effect("highlight");
    form_volat_start.val(element.data("volat_start")).effect("highlight");
    form_volat_end.val(volat_end.val()).effect("highlight");
    form_calculated_at.val(volat_end.val()).effect("highlight");
  });

  $("#so_report-form #set_dividend").on("click", function () {
    const element = $(this);
    const form = $("#so_report_dividend_rate");
    form.val(element.data("dividend")).effect("highlight");
  });

  $("#so_report-form #calc_remained_period").on("click", () => {
    // const element = $(this);
    const issued_at = $("#so_report_issued_at");
    const expiration_period = $("#so_report_expiration_period");
    const remained_period = $("#so_report_remained_period");
    const exec_at = $("#so_report_exec_at");
    const risk_free_rate = $("#so_report_risk_free_rate");

    let date_list;
    if (issued_at.val() === "" || expiration_period.val() === "") {
      alert("「発行日」と「満期までの期間(年)を入力して下さい。");
    } else {
      remained_period.val(expiration_period.val() * 0.5);
      remained_period.effect("highlight");
      date_list = issued_at.val().split("-");
      date_list[0] = String(
        parseInt(date_list[0]) + parseInt(expiration_period.val())
      );
      date_list = date_list.join("-");
      exec_at.val(date_list).effect("highlight");
    }

    if (expiration_period.val() === 10) {
      risk_free_rate.val("0.01").effect("highlight");
    }
  });
});
