function removeItemById(items, idName = "sid", id) {
  let r = items;
  items.forEach((v, i) => {
    if (v[idName] === id) {
      r.splice(i, 1);
    }
  });
  return r;
}

function forceParseInt(value) {
  return (
    parseInt(typeof value === "number" ? value : value.replace(/,/g, "")) || 0
  );
}

/**
 * 未使用
 */
// noinspection JSUnusedLocalSymbols
function parseIntExist(value) {
  return [value, typeof value === "number" || !isNaN(value.replace(/,/g, ""))];
}

/**
 * 決算書の指定したIDの要素を更新する
 * items [Array] 決算書情報
 * idName [String] "large_id,middle_id,small_id"
 * id [Number] 更新対象のID
 * name [string] 更新する項目
 * value 新しい値
 */
function editItemByIdAndElement(items, idName = "sid", id, name, value) {
  let r = [];

  const tmpValue = ["book_v", "diff_v", "fixed_v"].includes(name)
    ? forceParseInt(value)
    : value;
  items.forEach((item) => {
    const tmpHash = { ...item };
    if (item[idName] === Number(id)) {
      tmpHash[name] = value;
      switch (name) {
        case "book_v":
          tmpHash["fixed_v"] = tmpValue - forceParseInt(tmpHash["diff_v"]);
          break;
        case "diff_v":
          tmpHash["fixed_v"] = tmpValue + forceParseInt(tmpHash["book_v"]);
          break;
        case "fixed_v":
          tmpHash["diff_v"] = tmpValue - forceParseInt(tmpHash["book_v"]);
          break;
        default:
          break;
      }
    }
    r.push({ ...tmpHash });
  });
  return r;
}
//===========================================================

/**
 * 集計 を行う
 */
function calcItemsByAnotherItems(itemsA, itemsB, aidName) {
  const rItems_a = [];

  itemsA.forEach((itemA, indexA) => {
    const tmp_hash = { ...itemsA[indexA] };

    // items_aに子要素がある場合のみ値を初期化
    itemsB.forEach((itemB, _) => {
      if (itemB[aidName] === itemA[aidName]) {
        tmp_hash["book_v"] = 0;
        tmp_hash["diff_v"] = 0;
        tmp_hash["fixed_v"] = 0;
      }
    });
    // items_aの子要素であるitems_bから合計値を算出する
    itemsB.forEach((itemB, indexB) => {
      if (itemB[aidName] === itemA[aidName]) {
        tmp_hash["book_v"] += forceParseInt(itemsB[indexB]["book_v"]);
        tmp_hash["diff_v"] += forceParseInt(itemsB[indexB]["diff_v"]);
        tmp_hash["fixed_v"] += forceParseInt(itemsB[indexB]["fixed_v"]);
      }
    });
    rItems_a.push({ ...tmp_hash });
  });
  return rItems_a;
}

// 未使用
// noinspection JSUnusedLocalSymbols
function calcDiffBetweenItemsLAndItemsM(itemsL, itemsM) {
  // noinspection JSUnresolvedFunction
  const itemsMSum = calcItemsLByItemsM(itemsL, itemsM);
  return calcDiffBetweenTwoItems(itemsL, itemsMSum);
}

function calcDiffBetweenTwoItems(itemsA, itemsB) {
  let r = [];
  let tmp_hash = {};
  itemsA.forEach((item_a, index) => {
    tmp_hash = {};
    tmp_hash = { ...itemsA[index] };
    if (![null, undefined].includes(itemsB[index])) {
      tmp_hash["book_v"] =
        forceParseInt(itemsA[index]["book_v"]) -
        forceParseInt(itemsB[index]["book_v"]);
      tmp_hash["diff_v"] =
        forceParseInt(itemsA[index]["diff_v"]) -
        forceParseInt(itemsB[index]["diff_v"]);
      tmp_hash["fixed_v"] =
        forceParseInt(itemsA[index]["fixed_v"]) -
        forceParseInt(itemsB[index]["fixed_v"]);
    }
    r.push({ ...tmp_hash });
  });
  return r;
}

/**
 * 2つの配列の間の合計値を計算する
 */
function calcSumBetweenTwoItems(itemsA, itemsB) {
  let r = [];
  itemsA.forEach((item_a, index) => {
    const tmpHash = { ...itemsA[index] };
    if (![null, undefined].includes(itemsB[index])) {
      tmpHash["book_v"] =
        forceParseInt(itemsA[index]["book_v"]) +
        forceParseInt(itemsB[index]["book_v"]);
      tmpHash["diff_v"] =
        forceParseInt(itemsA[index]["diff_v"]) +
        forceParseInt(itemsB[index]["diff_v"]);
      tmpHash["fixed_v"] =
        forceParseInt(itemsA[index]["fixed_v"]) +
        forceParseInt(itemsB[index]["fixed_v"]);
    }
    r.push({ ...tmpHash });
  });
  return r;
}
//==================================
//BS Fix
// noinspection JSUnusedLocalSymbols
function initItemsValues(itemsA) {
  let r = [];
  let tmp_hash = {};
  itemsA.forEach((item_a) => {
    tmp_hash = { ...item_a };
    tmp_hash["book_v"] = 0;
    tmp_hash["diff_v"] = 0;
    tmp_hash["fixed_v"] = 0;
    r.push({ ...tmp_hash });
  });
  return r;
}
//================================
// PL
function plCalcSumsByLargeItems(itemsSums, itemsLarge) {
  let r = itemsSums;
  // 売上総利益 = 売上高 - 売上原価
  Object.assign(r[0], calcDiffTwoDic(itemsLarge[0], itemsLarge[1]));
  // 営業利益 = 売上総利益 - 販管費
  Object.assign(r[1], calcDiffTwoDic(r[0], itemsLarge[2]));
  // 経常利益 = 営業利益 + 営業外収益 - 営業外費用
  Object.assign(r[2], calcDiffThreeDic(r[1], itemsLarge[3], itemsLarge[4]));
  // 税引前当期純利益 = 経常利益 + 特別利益 - 特別損失
  Object.assign(r[3], calcDiffThreeDic(r[2], itemsLarge[5], itemsLarge[6]));
  //　当期純利益 = 税引前当期純利益 - 法人税等合計
  Object.assign(r[4], calcDiffTwoDic(r[3], itemsLarge[7]));
  return r;
}

function calcDiffTwoDic(dic1, dic2) {
  let r = { book_v: 0, diff_v: 0, fixed_v: 0 };
  r["book_v"] = forceParseInt(dic1["book_v"]) - forceParseInt(dic2["book_v"]);
  r["diff_v"] = forceParseInt(dic1["diff_v"]) - forceParseInt(dic2["diff_v"]);
  r["fixed_v"] =
    forceParseInt(dic1["fixed_v"]) - forceParseInt(dic2["fixed_v"]);
  return r;
}

// dic1 + dic2 - dic3
function calcDiffThreeDic(dic1, dic2, dic3) {
  let r = { book_v: 0, diff_v: 0, fixed_v: 0 };
  r["book_v"] =
    forceParseInt(dic1["book_v"]) +
    forceParseInt(dic2["book_v"]) -
    forceParseInt(dic3["book_v"]);
  r["diff_v"] =
    forceParseInt(dic1["diff_v"]) +
    forceParseInt(dic2["diff_v"]) -
    forceParseInt(dic3["diff_v"]);
  r["fixed_v"] =
    forceParseInt(dic1["fixed_v"]) +
    forceParseInt(dic2["fixed_v"]) -
    forceParseInt(dic3["fixed_v"]);
  return r;
}

// =======================================
// SAG
// noinspection JSUnusedLocalSymbols
function sagCalcSumsByItems(items) {
  let tmp_list = { book_v: [], diff_v: [], fixed_v: [] };
  items.forEach((v, _) =>
    ["book_v", "diff_v", "fixed_v"].forEach((txt) =>
      tmp_list[txt].push(forceParseInt(v[txt]))
    )
  );
  return {
    book_v: math.sum(tmp_list["book_v"]),
    diff_v: math.sum(tmp_list["diff_v"]),
    fixed_v: math.sum(tmp_list["fixed_v"]),
  };
}

export const ReducerHelper = {
  removeItemById,
  editItemByIdAndElement,
  calcItemsByAnotherItems,
  calcDiffBetweenTwoItems,
  calcSumBetweenTwoItems,
  plCalcSumsByLargeItems,
};
