import { triggerButton } from "./triggers";

$(() => {
  // change_status
  // ============================================================================================
  $(document).on(
    "change",
    "#user_clients-index .trigger--change_status",
    function () {
      const element = $(this);
      const id = element.data("id");

      $.ajax({
        type: "GET",
        url: "/user_clients/change_status",
        data: { id, status: element.find(":selected").val() },
        dataType: "json",
        error: () => console.log("error"),
      });
    }
  );

  // 案件の登録  # application.js:trigger_button
  // ============================================================================================
  // 案件に追加する
  $(document).on(
    "click",
    "#user_clients-index .trigger--add_project",
    function () {
      triggerButton($(this), "add", "projects", "project", "マッチング先", {
        route: "report",
        type: "client",
      });
    }
  );
  // 案件から外す
  $(document).on(
    "click",
    "#user_clients-index .trigger--remove_project",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project",
        "マッチング先へ登録",
        { route: "report", type: "client" }
      );
    }
  );
});
