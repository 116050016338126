import React, { useState } from "react";
import { hot } from "react-hot-loader";

const InputName = (props) => {
  const { data_item_id, data_item_name } = props;
  const [is_controlled, setIsControlled] = useState(true);
  const value = props.value || "";
  const value_property = is_controlled
    ? { value: value }
    : { defaultValue: value };

  function handleOnFocus(e) {
    setIsControlled(false);
  }
  function handleOnBlur(e) {
    setIsControlled(true);
  }
  return (
    <React.Fragment>
      {props.edittable && (
        <label className={`fs-label fs-${props.kindOfItem} name`}>
          <input
            className={`fs-input fs-${props.kindOfItem} name`}
            name="name"
            {...value_property}
            onChange={(e) => props.onChange(e)}
            autoComplete="off"
            data-item_id={data_item_id || ""}
            data-item_name={data_item_name || ""}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            data-lpignore="true"
          />
        </label>
      )}
      {!props.edittable && (
        <React.Fragment>
          <label className={`fs-label fs-${props.kindOfItem} name solid`}>
            <input
              className={`fs-input fs-${props.kindOfItem} name solid`}
              name="name"
              {...value_property}
              readOnly
              autoComplete="off"
              data-lpignore="true"
              tabIndex={-1}
              data-item_id={data_item_id || ""}
              data-item_name={data_item_name || ""}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </label>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

InputName.defaultProps = {
  value: "",
  kindOfItem: "small",
  edittable: false,
  onChange: null,
  "data-lpignore": "true",
};

export default hot(module)(InputName);
