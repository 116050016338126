function checkHash(hash) {
  return ![null, undefined].includes(hash) && !!Object.keys(hash).length;
}

/**
 * 2つのハッシュを集計して合計値を出す
 */
function sumTwoHashes(hashA, hashB) {
  let r = {};
  Object.keys(hashA).forEach((key) => {
    r[key] = parseInt(hashA[key]) + parseInt(hashB[key]);
  });
  return r;
}

export const HashTools = {
  checkHash,
  sumTwoHashes,
};
