import React from "react";
import { connect } from "react-redux";
import SmallItemTr from "../share/fix/SmallItemTr";
import MiddleItemTr from "../share/fix/MiddleItemTr";
import LargeItemTr from "../share/fix/LargeItemTr";
import OtherMiddleItemTr from "../share/fix/OtherMiddleItemTr";
import { hot } from "react-hot-loader";
import { BalanceSheetFixAction } from "../../../../../actions/financialActions/financialStatements/BalanceSheetFixAction";
import { HashTools } from "../../../../../modules/financialStatements/hashTools";
import { ArrayTools } from "../../../../../modules/financialStatements/arrayTools";
import { Association } from "../../../../../modules/financialStatements/associationTools";

import TableHeader from "../share/fix/TableHeader";
import TableFooter from "../share/fix/TableFooter";

import BalanceSheetSummary from "./BalanceSheetSummary";

import { debounce } from "throttle-debounce";

class BalanceSheetFix extends React.Component {
  constructor(props) {
    super(props);
    this.items_category = this.props.defaultProps.items_category;
    this.items_large = this.props.defaultProps.items_large;
    this.items_middle = this.props.defaultProps.items_middle;
    this.items_small = this.props.defaultProps.items_small;
    this.items_other = this.props.defaultProps.items_other;
    this.body = {
      items_category: this.items_category,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    this.bookValueKeeperName = "(決算書記載)";
    this.handleInit(this.body);
    this.editSmallItem = this.editSmallItem.bind(this);
    this.editMiddleItem = this.editMiddleItem.bind(this);
    this.editLargeItem = this.editLargeItem.bind(this);
    this.editSmallItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditSmallItem(this.body, id, name, value)
    );
    this.editMiddleItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditMiddleItem(this.body, id, name, value)
    );
    this.editLargeItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditLargeItem(this.body, id, name, value)
    );
  }

  handleInit(body) {
    this.props.handleInit(body);
  }

  addSmallItem(middleId) {
    const small_id = Association.getLastIdPlusOne(this.items_small, "small_id");
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    const middleItemHasChildren = ArrayTools.checkArray(smallItems);
    const parentMiddleItem = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const book_v = middleItemHasChildren ? 0 : parentMiddleItem["book_v"];
    const diff_v = middleItemHasChildren ? 0 : parentMiddleItem["diff_v"];
    const fixed_v = middleItemHasChildren ? 0 : parentMiddleItem["fixed_v"];
    // 中項目の子要素が存在しない場合、追加される小項目の簿価は中項目の簿価になる

    // 決算書記載額その他を追加
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    if (
      !middleItemHasChildren &&
      (book_v !== 0 || diff_v !== 0 || fixed_v !== 0)
    ) {
      this.body = this.props.financialStatements["balanceSheet"]["fix"];
      const item_small_dash = {
        small_id: small_id + 1,
        middle_id: middleId,
        name: item_middle.name + this.bookValueKeeperName,
        book_v,
        diff_v,
        fixed_v,
        comment: `「${item_middle.name}」の決算書記載金額`,
        addedIn: "book",
      };
      this.props.handleAddSmallItem(this.body, item_small_dash);
    }

    const item_small = {
      small_id,
      middle_id: middleId,
      name: "",
      book_v: 0,
      diff_v: 0,
      fixed_v: 0,
      addedIn: "fix",
    };
    this.props.handleAddSmallItem(this.body, item_small);
  }

  /**
   * 空のMiddleItemを追加
   * @param largeId
   */
  addMiddleItem(largeId) {
    const middle_id = Association.getLastIdPlusOne(
      this.items_middle,
      "middle_id"
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const largeItemHasChildren = ArrayTools.checkArray(middleItems);
    const parentLargeItem = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const book_v = largeItemHasChildren ? 0 : parentLargeItem["book_v"];
    const diff_v = largeItemHasChildren ? 0 : parentLargeItem["diff_v"];
    const fixed_v = largeItemHasChildren ? 0 : parentLargeItem["fixed_v"];
    const item_middle = {
      middle_id: middle_id,
      large_id: largeId,
      name: "",
      book_v: book_v,
      diff_v: diff_v,
      fixed_v: fixed_v,
      addedIn: "fix",
    };
    this.props.handleAddMiddleItem(this.body, item_middle);
  }
  // addLargeItem(e) {
  //   const large_id = Association.getLastIdPlusOne(this.items_large, "large_id")
  //   const item_large = {"large_id": large_id, "name": "", "book_v": 0, "diff_v": 0, "fixed_v": 0, "addedIn": "book"}
  //   this.props.handleAddLargeItem(this.body, this.items_large, item_large)
  // }
  removeSmallItem(smallId) {
    const item_small = Association.getItemById(
      this.items_small,
      "small_id",
      smallId
    );
    const middle_id = item_small.middle_id;
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middle_id
    );

    this.props.handleRemoveSmallItem(this.body, smallId);

    // 決算書記載金額として追加した科目を削除
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middle_id
    );
    if (
      ArrayTools.checkArray(smallItems) &&
      smallItems.length === 1 &&
      smallItems[0].name === item_middle.name + this.bookValueKeeperName
    ) {
      this.props.handleRemoveSmallItem(this.body, smallItems[0].small_id);
    }
  }
  removeMiddleItem(middleId) {
    this.props.handleRemoveMiddleItem(this.body, middleId);
  }

  // removeLargeItem() {

  // }

  /**
   * Smallを編集
   */
  editSmallItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    const id = e.target.dataset.item_id;
    this.editSmallItemDebounce(id, name, value);
    e.preventDefault();
  }

  /**
   * Middleを修正
   */
  editMiddleItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    const id = e.target.dataset.item_id;
    this.editMiddleItemDebounce(id, name, value);
    e.preventDefault();
  }

  /**
   * Largeを修正
   */
  editLargeItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    const id = e.target.dataset.item_id;
    this.editLargeItemDebounce(id, name, value);
    e.preventDefault();
  }

  /**
   * その他を修正
   */
  editOtherItem(largeId, e) {
    const name = e.target.name;
    const value = e.target.value;
    this.props.handleEditOtherItem(this.body, largeId, name, value);
    e.preventDefault();
  }

  /**
   * Smallを修正
   */
  renderSmallItem(smallId) {
    const item_small = Association.getItemById(
      this.items_small,
      "small_id",
      smallId
    );
    if (
      ArrayTools.checkArray(this.items_small) &&
      HashTools.checkHash(item_small)
    ) {
      return (
        <SmallItemTr
          key={smallId}
          item_middle={item_small}
          name={item_small["name"]}
          book_v={item_small["book_v"]}
          diff_v={item_small["diff_v"]}
          fixed_v={item_small["fixed_v"]}
          comment={item_small["comment"]}
          addedIn={item_small["addedIn"]}
          removeSmallItem={(e) => this.removeSmallItem(smallId)}
          editSmallItem={this.editSmallItem}
          data_item_id={smallId}
          data_item_name="small_id"
        />
      );
    } else {
      return false;
    }
  }

  /**
   * Smallを描画
   */
  renderSmallItems(middleId) {
    let list = [];
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    if (ArrayTools.checkArray(smallItems)) {
      smallItems.forEach((v, i) => {
        list.push(this.renderSmallItem(v["small_id"]));
      });
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  /**
   * Middleを描画
   */
  renderMiddleItem(middleId) {
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    return (
      <React.Fragment key={middleId}>
        <MiddleItemTr
          key={middleId}
          item_middle={item_middle}
          name={item_middle["name"]}
          book_v={item_middle["book_v"]}
          diff_v={item_middle["diff_v"]}
          fixed_v={item_middle["fixed_v"]}
          comment={item_middle["comment"]}
          addedIn={item_middle["addedIn"]}
          items_small={smallItems}
          addSmallItem={(e) => this.addSmallItem(middleId)}
          removeMiddleItem={(e) => this.removeMiddleItem(middleId)}
          editMiddleItem={this.editMiddleItem}
          data_item_id={middleId}
          data_item_name="middle_id"
        />
        {this.renderSmallItems(middleId)}
      </React.Fragment>
    );
  }

  /**
   * Middlesを描画
   */
  renderMiddleItems(largeId) {
    let list = [];
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    if (ArrayTools.checkArray(middleItems)) {
      middleItems.forEach((v, i) => {
        list.push(this.renderMiddleItem(v["middle_id"]));
      });
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  /**
   * Largeを描画
   * @param largeId
   * @returns {*}
   */
  renderLargeItem(largeId) {
    const item_large = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const otherItem = Association.getItemById(
      this.items_other,
      "large_id",
      largeId
    );
    const hasMiddleItems = ArrayTools.checkArray(middleItems);
    return (
      <React.Fragment key={largeId}>
        <LargeItemTr
          key={largeId}
          item_large={item_large}
          name={item_large["name"]}
          book_v={item_large["book_v"]}
          diff_v={item_large["diff_v"]}
          fixed_v={item_large["fixed_v"]}
          comment={item_large["comment"]}
          addedIn={item_large["addedIn"]}
          items_middle={middleItems}
          addMiddleItem={(e) => this.addMiddleItem(largeId)}
          removeLargeItem={(e) => this.removeLargeItem(largeId)}
          editLargeItem={this.editLargeItem}
          data_item_id={largeId}
          data_item_name="large_id"
        />
        {this.renderMiddleItems(largeId)}
        {hasMiddleItems && (
          <OtherMiddleItemTr
            book_v={otherItem["book_v"]}
            diff_v={otherItem["diff_v"]}
            fixed_v={otherItem["fixed_v"]}
            comment={otherItem["comment"]}
            editOtherItem={(e) => this.editOtherItem(largeId, e)}
          />
        )}
      </React.Fragment>
    );
  }

  /**
   * Largesを描画
   */
  renderLargeItems(categoryId) {
    const list = [];
    const largeItems = Association.getItemsById(
      this.items_large,
      "category_id",
      categoryId
    );
    if (ArrayTools.checkArray(largeItems)) {
      largeItems.forEach((v) => list.push(this.renderLargeItem(v["large_id"])));
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  /**
   * テーブルを描画
   * 画面中央
   */
  renderPartial(categoryId) {
    const name = Association.getItemById(
      this.items_category,
      "category_id",
      categoryId
    )["name"];
    const book_v = Association.getItemById(
      this.items_category,
      "category_id",
      categoryId
    )["book_v"];
    const category = Association.getItemById(
      this.items_category,
      "category_id",
      categoryId
    );
    return (
      <table className="w-100">
        {/* テーブルヘッダー */}
        <TableHeader name={`${name}の部`} />
        {/* テーブル */}
        <tbody>{this.renderLargeItems(categoryId)}</tbody>
        {/* テーブルフッター */}
        <TableFooter
          name={`${name}の部合計`}
          book_v={category["book_v"]}
          diff_v={category["diff_v"]}
          fixed_v={category["fixed_v"]}
        />
      </table>
    );
  }

  /**
   * 資産の部合計 / 負債の部合計 / 純資産の部合計 / 負債・純資産の部合計
   * 画面右
   */
  renderSummary() {
    const assetSum = Association.getItemById(
      this.items_category,
      "category_id",
      "AS"
    );
    const debtSum = Association.getItemById(
      this.items_category,
      "category_id",
      "DE"
    );
    const equitySum = Association.getItemById(
      this.items_category,
      "category_id",
      "NA"
    );
    const deSum = HashTools.sumTwoHashes(debtSum, equitySum);
    return (
      <React.Fragment>
        <BalanceSheetSummary
          name="資産"
          book_v={assetSum["book_v"]}
          diff_v={assetSum["diff_v"]}
          fixed_v={assetSum["fixed_v"]}
        />
        <BalanceSheetSummary
          name="負債"
          book_v={debtSum["book_v"]}
          diff_v={debtSum["diff_v"]}
          fixed_v={debtSum["fixed_v"]}
        />
        <BalanceSheetSummary
          name="純資産"
          book_v={equitySum["book_v"]}
          diff_v={equitySum["diff_v"]}
          fixed_v={equitySum["fixed_v"]}
        />
        <BalanceSheetSummary
          name="負債・純資産"
          book_v={deSum["book_v"]}
          diff_v={deSum["diff_v"]}
          fixed_v={deSum["fixed_v"]}
        />
      </React.Fragment>
    );
  }

  render() {
    const state = this.props.financialStatements["balanceSheet"]["fix"];
    this.items_category = state["items_category"];
    this.items_large = state["items_large"];
    this.items_middle = state["items_middle"];
    this.items_small = state["items_small"];
    this.items_other = state["items_other"];
    this.body = {
      items_category: this.items_category,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    if (this.items_category == null) {
      return false;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-9 scroll-area">
              <div className="contents-block fs-11 px-2">
                <div className="report-title fs-15 mb-0">資産の部</div>
                {this.renderPartial("AS")}
              </div>
              <div className="contents-block fs-11 px-2 mt-4">
                <div className="report-title fs-15">負債の部</div>
                {this.renderPartial("DE")}
              </div>
              <div className="contents-block fs-11 px-2 mt-4">
                <div className="report-title fs-15">純資産の部</div>
                {this.renderPartial("NA")}
              </div>
            </div>
            <div className="col-3">{this.renderSummary()}</div>
          </div>
          <input
            type="hidden"
            name="financial_statement_fix[body]"
            value={JSON.stringify(this.body)}
          />
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    handleInit(body) {
      dispatch(BalanceSheetFixAction.bsFixInitProps(body));
    },
    handleAddSmallItem(body, item_small) {
      dispatch(BalanceSheetFixAction.bsFixAddSmallItem(body, item_small));
    },
    handleAddMiddleItem(body, item_middle) {
      dispatch(BalanceSheetFixAction.bsFixAddMiddleItem(body, item_middle));
    },
    handleAddLargeItem(body, item_large) {
      dispatch(BalanceSheetFixAction.bsFixAddLargeItem(body, item_large));
    },
    handleRemoveSmallItem(body, small_id) {
      dispatch(BalanceSheetFixAction.bsFixRemoveSmallItem(body, small_id));
    },
    handleRemoveMiddleItem(body, middle_id) {
      dispatch(BalanceSheetFixAction.bsFixRemoveMiddleItem(body, middle_id));
    },
    handleRemoveLargeItem(body, large_id) {
      dispatch(BalanceSheetFixAction.bsFixRemoveLargeItem(body, large_id));
    },
    handleEditSmallItem(body, small_id, name, value) {
      dispatch(
        BalanceSheetFixAction.bsFixEditSmallItem(body, small_id, name, value)
      );
    },
    handleEditMiddleItem(body, middle_id, name, value) {
      dispatch(
        BalanceSheetFixAction.bsFixEditMiddleItem(body, middle_id, name, value)
      );
    },
    handleEditLargeItem(body, large_id, name, value) {
      dispatch(
        BalanceSheetFixAction.bsFixEditLargeItem(body, large_id, name, value)
      );
    },
    handleEditOtherItem(body, large_id, name, value) {
      dispatch(
        BalanceSheetFixAction.bsFixEditOtherItem(body, large_id, name, value)
      );
    },
  };
}

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(BalanceSheetFix)
);
