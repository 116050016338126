import { catchError, exhaustMap, map, filter } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import { of } from "rxjs";
import {
  TOOLTIPS_REQUEST,
  TOOLTIPS_SUCCESS,
  TOOLTIPS_FAILURE,
  TooltipsAction,
} from "../../actions/financialActions/financialStatements/TooltipsAction";
import { FinancialStatementFixApi } from "../../apis/FinancialStatementFixApi";

const tooltipsRequestEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === TOOLTIPS_REQUEST),
    exhaustMap((action) => {
      const { report_id } = action;
      return FinancialStatementFixApi.financialStatementFixTooltips({
        report_id,
      }).pipe(
        map((res) => TooltipsAction.tooltipsSuccess(res)),
        catchError((error) => of(TooltipsAction.tooltipsFailure({ error })))
      );
    })
  );

export const tooltipsEpic = combineEpics(tooltipsRequestEpic);
