/**
 * インタビュー表データのバリデーションと更新
 */
export function saveTable({
  $answerBlockVisible,
  prevArr,
  submitDisplay,
  idName,
}) {
  // 表の場合
  if ($answerBlockVisible.data("required_flag")) {
    // 回答必須の場合
    let emp = false;
    $("#interview_answer_edit .answer-block:visible .form-item-input").each(
      (index, dom) => {
        if ($(dom).val() === "") {
          emp = true;
        }
      }
    );
    if (emp) {
      // 未回答項目がある場合
      alert("入力されていない項目があります。");
    } else if (idName === "next") {
      // 全回答されている場合
      const next_question_number = $answerBlockVisible.data(
        "next_question_number"
      );

      const $answerEditQuestionNumber = $(
        `#interview_answer_edit #question_${next_question_number}`
      );

      const number = $answerBlockVisible.data("number");

      if ($answerEditQuestionNumber.length) {
        // 次の質問IDのブロックが存在する場合
        prevArr.push(`#question_${number}`);
        $answerBlockVisible.hide();
        $answerEditQuestionNumber.fadeIn("slow");
      } else {
        // 次の質問IDのブロックが存在しない場合
        prevArr.push(`#question_${number}`);
        $answerBlockVisible.hide();
        submitDisplay();
      }
    }
  } else if (!$answerBlockVisible.data("required_flag") && idName === "next") {
    // 回答必須ではない場合
    const next_question_number = $answerBlockVisible.data(
      "next_question_number"
    );

    const $answerEditQuestionNumber = $(
      `#interview_answer_edit #question_${next_question_number}`
    );

    const number = $answerBlockVisible.data("number");
    if ($answerEditQuestionNumber.length) {
      // 次の質問IDのブロックが存在する場合
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      $answerEditQuestionNumber.fadeIn("slow");
    } else {
      // 次の質問IDのブロックが存在しない場合
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      submitDisplay();
    }
  }
}
