$(() => {
  let tmp = "1111";
  // 候補とリレーションからの選択による入力
  $("#report_contracts-modal .trigger--select-modal-value").on(
    "click",
    function () {
      const dataId = $(this).data("id");
      const value = $(this).data("value");
      console.log(dataId);

      $(`#event--show_text_${dataId}`).html(value.replace(/\n/g, "<br>"));
      $(`#txt_${dataId}`).val(value);
      $(`.event--input_modal_text[data-id=${dataId}]`).val(value).focus();
    }
  );

  // エンター時に確定
  $("#report_contracts-modal .trigger--enter_fix").on("keypress", function (e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      $(this).parents(".remodal").find(".trigger--fix_modal").click();
      return false;
    }
    return true;
  });

  // クリアボタン
  $("#report_contracts-modal .trigger--clear_value").on("click", function () {
    const dataId = $(this).data("id");
    const data_label = $(this).data("label");
    $(`#event--show_text_${dataId}`).html(`${data_label}を入力`);
    $(`#txt_${dataId}`).val("");
    $(`.event--input_modal_text[data-id=${dataId}]`).val("");
  });

  // 確定ボタン
  $("#report_contracts-modal .trigger--fix_modal").on("click", function () {
    const element = $(this)
      .parents(".remodal")
      .find(".event--input_modal_text");
    const data_id = element.data("id");
    const value = element.val();
    // テキストエリアの内容をビューに反映する
    if (value !== "") {
      $(`#event--show_text_${data_id}`).html(value.replace(/\n/g, "<br>"));
      $(`#txt_${data_id}`).val(value);
    }
  });

  // モーダル表示時にテキストフィールドをフォーカス
  $(document).on("opening", ".trigger--modal_text", function (e) {
    const data_id = $(this).data("id");
    $(`.event--input_modal_text[data-id=${data_id}]`).focus();
  });
});
