import { map, filter } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import {
  PL_FIX_INIT_PROPS,
  PL_FIX_ADD_SMALL_ITEM,
  PL_FIX_ADD_MIDDLE_ITEM,
  PL_FIX_ADD_LARGE_ITEM,
  PL_FIX_REMOVE_SMALL_ITEM,
  PL_FIX_REMOVE_MIDDLE_ITEM,
  PL_FIX_REMOVE_LARGE_ITEM,
  PL_FIX_EDIT_SMALL_ITEM,
  PL_FIX_EDIT_MIDDLE_ITEM,
  PL_FIX_EDIT_LARGE_ITEM,
  PL_FIX_EDIT_OTHER_ITEM,
  PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS,
  PL_FIX_CALC_SUMS_ITEMS_BY_LARGE_ITEMS,
  ProfitAndLossFixAction,
} from "../../actions/financialActions/financialStatements/ProfitAndLossFixAction";

const profitAndLossFixAddSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_ADD_SMALL_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixAddMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_ADD_MIDDLE_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixRemoveSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_REMOVE_SMALL_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixRemoveMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_REMOVE_MIDDLE_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

// const profitAndLossFixRemoveLargeItemEpic = (action$, state$) => action$.pipe(
//   filter( action => action.type === PL_FIX_REMOVE_LARGE_ITEM),
//   map(ProfitAndLossFixAction.plFixCalcSumsItemsByLargeItems(state$.value.financialStatements.profitAndLoss.fix)),
// )

const profitAndLossFixEditSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_EDIT_SMALL_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixEditMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_EDIT_MIDDLE_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixEditLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_EDIT_LARGE_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcSumsItemsByLargeItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixEditOtherItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_EDIT_OTHER_ITEM),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

//===============================================================

const profitAndLossFixCalcMiddleItemsBySmallItemsEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

const profitAndLossFixCalcLargeItemsByMiddleItemsAndOtherItemsEpic = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      (action) =>
        action.type === PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS
    ),
    map((action) =>
      ProfitAndLossFixAction.plFixCalcSumsItemsByLargeItems(
        state$.value.financialStatements.profitAndLoss.fix
      )
    )
  );

export const profitAndLossFixEpic = combineEpics(
  profitAndLossFixAddSmallItemEpic,
  profitAndLossFixAddMiddleItemEpic,
  profitAndLossFixRemoveSmallItemEpic,
  profitAndLossFixRemoveMiddleItemEpic,
  // profitAndLossFixRemoveLargeItemEpic,
  profitAndLossFixEditSmallItemEpic,
  profitAndLossFixEditMiddleItemEpic,
  profitAndLossFixEditLargeItemEpic,
  profitAndLossFixEditOtherItemEpic,

  profitAndLossFixCalcMiddleItemsBySmallItemsEpic,
  profitAndLossFixCalcLargeItemsByMiddleItemsAndOtherItemsEpic
  // profitAndLossFixCalcSumsItemsByLargeItemsEpic,
);
