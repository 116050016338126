import { removeButton, triggerButton } from "./triggers";

$(() => {
  $("#reports-edit #company_json").on("change", function () {
    if (window.confirm("解析には時間が掛かりますがよろしいですか？")) {
      $(this).closest("form").submit();
    } else {
      $("#company_json").val("");
      window.alert("キャンセルされました");
    }
  });

  $("#reports-edit #trigger--upload").on("click", () => {
    $("#company_json").click();
    return false;
  });

  $("#reports-crawl .crawl-image").on("error", function () {
    $(this).parents(".col-2").remove();
  });

  // change_status  # application.js:change_select
  $(document).on(
    "change",
    ".reports-index .trigger--change_status",
    function () {
      changeSelect($(this), "/reports/change_status");
    }
  );

  // 案件の登録 # application.js:trigger_button
  // ============================================================================================
  // 案件に追加する
  $(document).on(
    "click",
    "#reports-select_pair .trigger--add_project_buy",
    function () {
      triggerButton($(this), "add", "projects", "project_buy", "マッチング先", {
        route: "report",
        type: "report",
      });
    }
  );
  $(document).on(
    "click",
    "#reports-select_pair .trigger--add_project_sell",
    function () {
      triggerButton(
        $(this),
        "add",
        "projects",
        "project_sell",
        "マッチング先",
        { route: "report", type: "report" }
      );
    }
  );
  // 案件から外す
  $(document).on(
    "click",
    "#reports-select_pair .trigger--remove_project_buy",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project_buy",
        "マッチング先へ登録",
        { route: "report", type: "report" }
      );
    }
  );
  $(document).on(
    "click",
    "#reports-select_pair .trigger--remove_project_sell",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project_sell",
        "マッチング先へ登録",
        { route: "report", type: "report" }
      );
    }
  );

  // 顧客の連絡先に登録  # application.js:trigger_button
  // ============================================================================================
  // 顧客の連絡先に登録
  $(document).on(
    "click",
    "#reports-select_pair .trigger--add_contact.report",
    function () {
      const name = $(this).data("name");
      triggerButton($(this), "add", "contacts", "contact", name, {
        route: "report",
      });
    }
  );
  // 顧客の連絡先から外す
  $(document).on(
    "click",
    "#reports-select_pair .trigger--remove_contact.report",
    function () {
      const name = $(this).data("name");
      triggerButton($(this), "remove", "contacts", "contact", `${name}へ登録`, {
        route: "report",
      });
    }
  );

  // 案件提供者の連絡先に登録  # application.js:trigger_button
  // ストップワードに登録
  $(document).on(
    "click",
    "#reports-crawl .trigger--remove_stopword",
    function () {
      removeButton(
        $(this),
        "remove",
        "stopword",
        "stopword",
        "/stopwords/add_stopword"
      );
    }
  );
  $(document).on("click", "#reports-crawl .trigger--add_stopword", function () {
    removeButton(
      $(this),
      "add",
      "stopword",
      "stopword",
      "/stopwords/add_stopword"
    );
  });

  // 入力フォームの案件提供者入力を新規の場合表示
  $(document).on("change", ".trigger--show-adviser-form", function () {
    const element = $(this);
    const form = $(".event--show-adviser-form");

    if (element.val() === "new") {
      form.removeClass("d-none");
    } else {
      form.addClass("d-none");
    }
  });

  $(document).on("change", ".trigger--show-scheme-form", function () {
    const element = $(this);
    const form = $(".event--show-scheme-form");

    if (element.val() === "sell") {
      form.removeClass("d-none");
    } else {
      form.addClass("d-none");
    }
  });
});
