import { map, filter } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import {
  BS_FIX_INIT_PROPS,
  BS_FIX_ADD_SMALL_ITEM,
  BS_FIX_ADD_MIDDLE_ITEM,
  BS_FIX_ADD_LARGE_ITEM,
  BS_FIX_REMOVE_SMALL_ITEM,
  BS_FIX_REMOVE_MIDDLE_ITEM,
  BS_FIX_REMOVE_LARGE_ITEM,
  BS_FIX_EDIT_SMALL_ITEM,
  BS_FIX_EDIT_MIDDLE_ITEM,
  BS_FIX_EDIT_LARGE_ITEM,
  BS_FIX_EDIT_OTHER_ITEM,
  BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS,
  BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS,
  BS_FIX_AUTO_INPUT_BY_OCR_RESULT,
  BalanceSheetFixAction,
} from "../../actions/financialActions/financialStatements/BalanceSheetFixAction";

/**
 * Smallが追加
 */
const balanceSheetFixAddSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_ADD_SMALL_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Middleが追加
 */
const balanceSheetFixAddMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_ADD_MIDDLE_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * 初期化を行う: Middle
 */
const balanceSheetFixInitMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_INIT_PROPS),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * 初期化を行う: Small
 */
const balanceSheetFixInitSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_INIT_PROPS),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * 初期化を行う: Large
 */
const balanceSheetFixInitLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_INIT_PROPS),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcCategoryItemsByLargeItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Smallが削除
 */
const balanceSheetFixRemoveSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_REMOVE_SMALL_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Middleが削除
 */
const balanceSheetFixRemoveMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_REMOVE_MIDDLE_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Largeが削除
 */
const balanceSheetFixRemoveLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_REMOVE_LARGE_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcCategoryItemsByLargeItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Smallが修正されたときに修正
 */
const balanceSheetFixEditSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_EDIT_SMALL_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Middleが変更されたときに修正
 */
const balanceSheetFixEditMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_EDIT_MIDDLE_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * Largeが修正されたときに変更
 */
const balanceSheetFixEditLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_EDIT_LARGE_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcCategoryItemsByLargeItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * その他が修正された時に再計算
 */
const balanceSheetFixEditOtherItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_EDIT_OTHER_ITEM),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 * MiddleのSmall
 */
const balanceSheetFixCalcMiddleItemsBySmallItemsEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcLargeItemsByMiddleItemsAndOtherItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

/**
 *
 */
const balanceSheetFixCalcLargeItemsByMiddleItemsAndOtherItemsEpic = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      (action) =>
        action.type === BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS
    ),
    map((action) =>
      BalanceSheetFixAction.bsFixCalcCategoryItemsByLargeItems(
        state$.value.financialStatements.balanceSheet.fix
      )
    )
  );

// const balanceSheetFixCalcCategoryItemsByLargeItemsEpic = (action$, state$)  => action$.pipe(
//   filter( action => action.type === BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS),
//   map(action => BalanceSheetFixAction.bsCalcOtherItemsByMiddleItemsAndLargeItems(state$.value.financialStatements.BalanceSheet.fix))
// )

export const balanceSheetFixEpic = combineEpics(
  balanceSheetFixAddSmallItemEpic,
  balanceSheetFixAddMiddleItemEpic,
  balanceSheetFixRemoveSmallItemEpic,
  balanceSheetFixRemoveMiddleItemEpic,
  balanceSheetFixRemoveLargeItemEpic,
  balanceSheetFixEditSmallItemEpic,
  balanceSheetFixEditMiddleItemEpic,
  balanceSheetFixEditLargeItemEpic,
  balanceSheetFixEditOtherItemEpic,
  balanceSheetFixCalcMiddleItemsBySmallItemsEpic,
  balanceSheetFixCalcLargeItemsByMiddleItemsAndOtherItemsEpic,
  balanceSheetFixInitMiddleItemEpic,
  balanceSheetFixInitSmallItemEpic,
  balanceSheetFixInitLargeItemEpic
  // balanceSheetFixCalcCategoryItemsByLargeItemsEpic,
);
