import { triggerButton } from "./triggers";

$(() => {
  // 案件の登録  # application.js:trigger_button
  // ============================================================================================
  // 案件に追加する
  $(document).on(
    "click",
    "#stopwords-index .trigger--add_stopword",
    function () {
      triggerButton($(this), "add", "stopwords", "stopword", "削除済み", {
        route: "report",
      });
    }
  );
  // 案件から外す
  $(document).on(
    "click",
    "#stopwords-index .trigger--remove_stopword",
    function () {
      triggerButton($(this), "remove", "stopwords", "stopword", "削除へ登録", {
        route: "report",
      });
    }
  );
});
