/**
 * 次の画面へ移動
 */
export function nextDisplay() {
  $(".submit-block").hide();
  $("#interview_answer_edit #next").show();
  $("#interview_answer_edit #submit_button").hide();
}

/**
 * 送信
 */
export function submitDisplay() {
  $(".submit-block").show();
  $("#interview_answer_edit #next").hide();
  $("#interview_answer_edit #submit_button").show();
}
