import { BalanceSheetAction } from "./BalanceSheetAction";
import { ProfitAndLossAction } from "./ProfitAndLossAction";
import {OCR_RESULT_CATEGORY} from "../../../constants";

export const REFERENCE_AREA_INIT_PROPS = "REFERENCE_AREA_INIT_PROPS";
export const REFERENCE_AREA_REMOVE_REFERENCED =
  "REFERENCE_AREA_REMOVE_REFERENCED";
export const REFERENCE_AREA_ASSIGN_REFERENCED =
  "REFERENCE_AREA_ASSIGN_REFERENCED";
export const REFERENCE_AREA_CHANGE_PROPS = "REFERENCE_AREA_CHANEGE_PROPS";
export const REFERENCE_AREA_CHANEGE_PROPS = "REFERENCE_AREA_CHANEGE_PROPS";

function referenceAreaInitProps(index, body) {
  return {
    type: REFERENCE_AREA_INIT_PROPS,
    index: index,
    body: body,
  };
}

/**
 * 同科目名に自動反映
 * @param index
 * @param financialStatements
 * @param category
 * @param valuableItemStructure
 * @returns {Function}
 */
function referenceAreaAutoInput(index, financialStatements, category, valuableItemStructure) {
  const ocrResult = financialStatements.referenceArea.ocrResultList[index];

  return (dispatch) => {
    switch (category) {
      case OCR_RESULT_CATEGORY.BALANCE_SHEET:
        dispatch(
          BalanceSheetAction.bsAutoInputByOcrResult(
            ocrResult,
            financialStatements,
            valuableItemStructure
          )
        );
        break;
      case OCR_RESULT_CATEGORY.PROFIT_AND_LOSS_STATEMENT:
        dispatch(
          ProfitAndLossAction.plAutoInputByOcrResult(
            ocrResult,
            financialStatements,
            valuableItemStructure
          )
        );
        break;
      default:
        console.log("err! category_id:", category);
    }
  };

}

/**
 * 販売費及び管理費に反映
 * @param index
 * @param financialStatements
 * @param category
 * @returns {Function}
 */
function referenceAreaAutoSagInput(index, financialStatements, category) {
  const ocrResult = financialStatements.referenceArea.ocrResultList[index];
  return (dispatch) => {
    switch (category) {
      case OCR_RESULT_CATEGORY.PROFIT_AND_LOSS_STATEMENT:
        dispatch(
          ProfitAndLossAction.plAutoSagInputByOcrResult(
            ocrResult,
            financialStatements
          )
        );
        break;
      default:
        console.log("err!");
    }
  };
}

function referenceAreaChangeProps(index, financialStatements) {
  return {
    type: REFERENCE_AREA_CHANEGE_PROPS,
    index,
  };
}

function referenceAreaRemoveReferenced(imageIndex, itemIndex) {
  return {
    type: REFERENCE_AREA_REMOVE_REFERENCED,
    image_index: imageIndex,
    item_index: itemIndex,
  };
}

function referenceAreaAssignReferenced(imageIndex, itemIndex) {
  return {
    type: REFERENCE_AREA_ASSIGN_REFERENCED,
    image_index: imageIndex,
    item_index: itemIndex,
  };
}

export const ReferenceAreaAction = {
  referenceAreaInitProps,
  referenceAreaAutoInput,
  referenceAreaAutoSagInput,
  referenceAreaChangeProps,
  referenceAreaRemoveReferenced,
  referenceAreaAssignReferenced,
};
