/**
 * 複数サービスで共通して読み込む汎用的なJSをまとめる
 */

global.$ = $;
global.jQuery = $;
global.JQuery = $;
import "jquery-ujs";
import "turbolinks";
import "../../vendor/remodal";
import "bootstrap";
import "data-confirm-modal";
import "dropzone";
import "select2/dist/js/select2.min";
import "select2/dist/js/i18n/ja";
import "intro.js/intro.js";
import "jquery.cookie";
import "readmore-js";
import "./autosize";
export * from "./modal_iframe";
export * from "./is_ie";
export * from "./change_select";
export * from "./introjs_setting";


