export const getQueryIntegerValue = (queryName) => {
  const reg = new RegExp(`^${queryName}=`);
  return parseInt(
    location.search
      .split(/\?|&/g)
      .filter((q) => q.match(reg))[0]
      .slice(-1)
  );
};

//urlの'?'以降を取得
export const getQuery = () => {
  return location.search.split(/\?/g).slice(-1)[0];
};

export const getValueByHiddenInputName = (name) => {
  const elm = document.getElementById(`financial_statement_fix_${name}`);
  return elm ? parseInt(elm.value) : 0;
};

export const getReportId = () => {
  return parseInt(location.pathname.split("/")[2]);
};
