/**
 * セレクトボックス
 */
export function saveSelectbox({ $answerBlockVisible, prevArr, submitDisplay }) {
  // セレクトボックスが選択されていれば
  if (
    $("#interview_answer_edit .answer-block:visible option:selected").val() ===
      0 &&
    $answerBlockVisible.data("required_flag")
  ) {
    // 回答必須で選択肢が選択されていなければ
    alert("選択または入力してください。");
  } else if (
    $answerBlockVisible
      .find("option:selected")
      .data("item_next_question_number")
  ) {
    // 選択肢に次の質問IDがあれば
    const item_next_question_number = $answerBlockVisible
      .find("option:selected")
      .data("item_next_question_number");

    const $answerEditQuestionNumber = $(
      `#interview_answer_edit #question_${item_next_question_number}`
    );
    const number = $answerBlockVisible.data("number");
    if ($answerEditQuestionNumber.length) {
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      $answerEditQuestionNumber.fadeIn("slow");
    } else {
      const number = $answerBlockVisible.data("number");
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      submitDisplay();
    }
  } else if ($answerBlockVisible.data("next_question_number")) {
    // 質問に次の質問IDがあれば
    const next_question_number = $answerBlockVisible.data(
      "next_question_number"
    );

    const $answerEditQuestionNumber = $(
      `#interview_answer_edit #question_${next_question_number}`
    );

    const number = $answerBlockVisible.data("number");
    if ($answerEditQuestionNumber.length) {
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      $answerEditQuestionNumber.fadeIn("slow");
    } else {
      const number = $answerBlockVisible.data("number");
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      submitDisplay();
    }
  } else {
    // 回答必須ではなく、質問に次の質問IDがなければ
    const number = $answerBlockVisible.data("number");
    prevArr.push(`#question_${number}`);
    $answerBlockVisible.hide();
    submitDisplay();
  }
}
