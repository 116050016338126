import { createStructuredSelector } from "reselect";

/**
 * 決算書入力画面情報のセレクタ
 */
export const financialStatementSelector = createStructuredSelector({
  referenceArea: (state) => state.financialStatements.referenceArea,
  balanceSheet: (state) => state.financialStatements.balanceSheet,
  profitAndLoss: (state) => state.financialStatements.profitAndLoss,
  tooltips: (state) => state.financialStatements.tooltips,
  statementCategoryFix: (state) =>
    state.financialStatements.statementCategoryFix,
});
