import { triggerButton } from "./triggers";

$(() => {
  // 顧客の登録
  // ============================================================================================
  // 売り手顧客に登録する
  $(document).on(
    "click",
    "#platforms-index .trigger--add_report_deal_sell",
    function () {
      triggerButton($(this), "add", "reports", "report_deal", "売り手顧客", {
        only: "add",
      });
    }
  );
  $(document).on(
    "click",
    "#platforms-index .trigger--add_report_deal_buy",
    function () {
      triggerButton($(this), "add", "reports", "report_deal", "買い手顧客", {
        only: "add",
      });
    }
  );

  // 顧客・案件の登録 (売り手)
  // ============================================================================================
  // 案件に追加する
  $(document).on(
    "click",
    "#platforms-index .trigger--add_project_sell",
    function () {
      triggerButton(
        $(this),
        "add",
        "projects",
        "project_sell",
        "マッチング先",
        { route: "report", type: "deal" }
      );
    }
  );
  // 案件から外す
  $(document).on(
    "click",
    "#platforms-index .trigger--remove_project_sell",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project_sell",
        "マッチング先へ登録",
        { route: "report", type: "deal" }
      );
    }
  );

  // 顧客・案件の登録 (買い手)
  // ============================================================================================
  // 案件に追加する
  $(document).on(
    "click",
    "#platforms-index .trigger--add_project_buy",
    function () {
      triggerButton($(this), "add", "projects", "project_buy", "マッチング先", {
        route: "report",
        type: "deal",
      });
    }
  );
  // 案件から外す
  $(document).on(
    "click",
    "#platforms-index .trigger--remove_project_buy",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project_buy",
        "マッチング先へ登録",
        { route: "report", type: "deal" }
      );
    }
  );
});
