import React from "react";
import { hot } from "react-hot-loader";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OcrResultList from "./OcrResultList";
import OcrSpreadSheetList from "./OcrSpreadSheetList";
import PdfDisplaySheetList from "./PdfDisplaySheetList";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import Button from "@material-ui/core/Button";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const displayStyle =
    value === index ? { display: "block" } : { display: "none" };
  return (
    <React.Fragment>
      <div style={displayStyle}>{children}</div>
    </React.Fragment>
  );
}

export class ReferenceArea extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
    this.state = {
      name: props.name,
      value: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {}
  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }
  render() {
    const props = this.props;
    const { referenceAreaInput, linkToOcr } = props;
    const value = this.state.value;
    return (
      <div id="reference_area">
        <AppBar position="static" style={{ flexDirection: "row" }}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            style={{ backgroundColor: blue[500], width: "70%" }}
          >
            <Tab
              label="解析結果"
              style={{ width: "34%", minWidth: "25%" }}
              data-step="8"
              data-intro="OCR後、反映された状態を表示します（確認とドラッグ＆ドロップで入力できます）"
            />
            <Tab
              label="PDF"
              style={{ width: "33%", minWidth: "25%" }}
              data-step="7"
              data-intro="スキャンした決算書のPDFを確認する事ができます"
            />
            <Tab
              label="テーブル"
              style={{ width: "33%", minWidth: "25%" }}
              data-step="6"
              data-intro="OCRスキャンしたテキストが全て反映されています。コピーして、シートへペーストできます"
            />
          </Tabs>
          <Button
            style={{
              width: "30%",
              minWidth: "25%",
              backgroundColor: green[400],
              color: "white",
              margin: "0.5rem",
              boxSizing: "content-box",
            }}
            href={linkToOcr}
            data-step={"5"}
            data-intro={
              "こちらから決算書のPDFをアップロードしてOCRスキャンを行ってください"
            }
          >
            OCRへ
          </Button>
        </AppBar>
        {referenceAreaInput.length > 0 && (
          <>
            <TabPanel value={value} index={0} style={{ padding: "0" }}>
              <OcrResultList {...props} />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ padding: "0" }}>
              <PdfDisplaySheetList {...props} />
            </TabPanel>
            <TabPanel value={value} index={2} style={{ padding: "0" }}>
              <OcrSpreadSheetList {...props} />
            </TabPanel>
          </>
        )}
        {referenceAreaInput.length === 0 && (
          <>
            <div
              className="fs-24 text-center mt-4 mx-2"
              style={{
                height: "30vh",
                color: red[500],
                border: "dashed 2px #ccc",
              }}
            >
              <div style={{ paddingTop: "8vh" }}>解析結果は未選択です</div>
              <Button
                variant="contained"
                style={{
                  width: "60%",
                  backgroundColor: green[400],
                  color: "white",
                  marginTop: "1rem",
                }}
                href={linkToOcr}
              >
                OCR解析・選択画面へ
              </Button>
            </div>
            <input
              type="hidden"
              name={`financial_statement_fix[ocr_result][0]`}
            />
          </>
        )}
      </div>
    );
  }
}

export default hot(module)(ReferenceArea);
