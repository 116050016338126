import React, { useState } from "react"
import { hot } from 'react-hot-loader'


const InputComment = props => {
  const { data_item_id, data_item_name } = props
  const [is_controlled, setIsControlled] = useState(true);
  const value = props.value || ""
  const value_property = is_controlled ? { value: value } : { defaultValue: value }

  function handleOnFocus(e) {
    setIsControlled(false)
  }
  function handleOnBlur(e) {
    setIsControlled(true)
  }
  return (
    <label className={`fs-label fs-${props.kindOfItem} comment`}>
      <input
        className={`fs-input fs-${props.kindOfItem} comment`}
        name="comment"
        {...value_property}
        onChange={(e) => props.onChange(e)}
        autoComplete="off"
        data-item_id={data_item_id || ''}
        data-item_name={data_item_name || ''}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </label>
  )
}


InputComment.defaultProps = {
  kindOfItem: "small",
  value: "",
  onChange: null,
  editting: true,
}

export default hot(module)(InputComment);