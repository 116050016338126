import React from 'react';
import { hot } from 'react-hot-loader'
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import red from '@material-ui/core/colors/red'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(0),
    fontSize: "0.9rem",
  }
}));

const DeleteButton = props => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="delete"
      className={classes.margin}
      size="small"
      tabIndex={-1}
      onClick={(e) => props.onClick(e)} >
      <DeleteForeverIcon fontSize="inherit" style={{ color: red[500] }} />
    </IconButton>
  );
}

DeleteButton.defaultProps = {
  onClick: null,
}

export default hot(module)(DeleteButton);