$(() => {
  // サイドバーを閉じる時、中の項目も全て閉じる処理
  $(".sidebarCollapse").on("click", () => {
    const $sidebar = $("#sidebar");
    $sidebar.toggleClass("active");
    $sidebar.find("li a").addClass("collapsed").attr("aria-expanded", "false");
    $sidebar.find("li ul").removeClass("show");
  });

  $(document).on("click", "#sidebar .collapsed", () =>
    $("#sidebar").removeClass("active")
  );
});
