import React from "react";
import { hot } from "react-hot-loader";

const TooltipButton = (props) => {
  const { tooltip_text } = props;

  return (
    <td colSpan={1}>
      <div className="fs-13 tooltip1">
        <i className="fa fa-question-circle blue"></i>
        <span>
          <p>{tooltip_text}</p>
        </span>
      </div>
    </td>
  );
};

TooltipButton.defaultProps = {
  tooltip_text: "",
};
export default hot(module)(TooltipButton);
