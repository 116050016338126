// noinspection FunctionNamingConventionJS
export function url_request(nextUrl) {
  const report_id = $("#next_url").data("id");
  const element = $("#trigger--parse_website");
  const parse_text = $("#trigger--parse_text");

  const parseWebsite = setInterval(() => {
    // 取得状況の取得
    $.ajax({
      type: "GET",
      url: `/reports/${report_id}/check_status`,
      dataType: "json",
    })
      .done((data) => {
        // 1.request：リクエスト中
        if (data.response === "request") {
          parse_text.text("サーバーへリクエストを送っています...");
        }

        // 2.pending：待機中
        else if (data.response === "pending") {
          parse_text.text("解析を開始しています。少々お待ちください...");
        }

        // 3.running：取得中
        else if (data.response === "running") {
          parse_text.text("サイト情報を取得中です...");
          // テーブルの更新処理
          $.ajax({
            type: "GET",
            dataType: "json",
            url: `/reports/${report_id}/crawling_table`,
          })
            .done((data) =>
              $.each(data.response, (index, value) => {
                const crawling_table = $("#trigger--crawling_table");
                if (crawling_table.find(`#list-${value.id}`).length === 0) {
                  crawling_table.prepend(
                    `<li class='list-group-item mb-1' id='list-${value.id}'>${value.url} <strong>のデータを取得しました</strong></li>`
                  );
                  $(`#list-${value.id}`)
                    .hide()
                    .effect("slide", "slow")
                    .effect("highlight", "slow");
                }
              })
            )
            .fail((_data) => console.log("error"));
        }

        // 4.scraped：解析中
        else if (data.response === "scraped") {
          parse_text.text("サイト情報を解析中です...");
        }

        // 5.success：解析完了
        else if (data.response === "success") {
          element.html(
            "<i class='fa fa-check fs-72 green mt-5 mb-3'></i><h2 class='green'><strong>解析が完了しました！<strong></h2><div class='fs-18'>間も無く画面が移動します...</div>"
          );
          setTimeout(() => {
            location.href = nextUrl;
          }, 3000);
        }
        // -.failed：失敗
        else {
          element.html(
            "<i class='fa fa-minus fs-72 red mt-5 mb-3'></i><h2 class='red'><strong>解析に失敗しました<strong></h2>"
          );
          clearInterval(parseWebsite);
        }
      })
      .fail((_data) => {
        clearInterval(parseWebsite);
        element.html(
          "<i class='fa fa-minus fs-72 red mt-5 mb-3'></i><h2 class='red'><strong>解析に失敗しました<strong></h2>"
        );
      });
  }, 5000);
}

global.url_request = url_request;
