import {
  TOOLTIPS_REQUEST,
  TOOLTIPS_SUCCESS,
  TOOLTIPS_FAILURE,
} from "../../../actions/financialActions/financialStatements/TooltipsAction"

function tooltipsReducer(state = {}, action){
  switch (action.type) {
    case TOOLTIPS_REQUEST:
      return { ...state, ...{ ...state.tooltips, ...{ is_fetching: action.is_fetching } } }

    case TOOLTIPS_SUCCESS:
      return { ...state, ...{ ...action.tooltips, ...{ body: action.body, is_fetching: action.is_fetching } } }

    case TOOLTIPS_FAILURE:
      return { ...state, ...{ ...state.tooltips, ...{ is_fetching: action.is_fetching } } }
    default:
      return { ...state }
  }
}

export default tooltipsReducer;
