import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
} from "../../../../constants";

/**
 * JSONを回答
 */
export function answerJson({ questionCount }) {
  const answer = {};
  for (let i = 1; i <= questionCount; i++) {
    const $question = $(`#interview_answer_edit #question_${i}`);
    const questionType = $question.data("type");

    switch (questionType) {
      case ANSWER_TYPE__RADIO_BUTTON:
        if ($question.find("input:checked").val()) {
          const question_id = $question.data("id");
          const question_title = $question.data("question_title");
          const item_id = $question.find("input:checked").val();
          const item_item = $question.find("input:checked").data("item_item");
          answer[question_id] = {
            title: question_title,
            result: {
              [item_id]: item_item,
            },
          };
        }
        break;
      case ANSWER_TYPE__SELECT_BOX:
        if (0 !== $question.find("option:selected").val()) {
          const question_id = $question.data("id");
          const question_title = $question.data("question_title");
          const item_id = $question.find("option:selected").val();
          const item_item = $question.find("option:selected").data("item_item");
          answer[question_id] = {
            title: question_title,
            result: {
              [item_id]: item_item,
            },
          };
        }
        break;
      case ANSWER_TYPE__CHECK_BOX:
        if ($question.find("input:checked").val()) {
          const question_id = $question.data("id");
          const question_title = $question.data("question_title");
          const check_hash = {};
          $(`#interview_answer_edit #question_${i} input:checked`).map(
            function () {
              check_hash[$(this).val()] = $(this).data("item_item");
            }
          );
          answer[question_id] = {
            title: question_title,
            result: check_hash,
          };
        }
        break;
      case ANSWER_TYPE__TEXT_BOX:
        if ("" !== $question.find("textarea").val()) {
          const question_id = $question.data("id");
          const question_title = $question.data("question_title");
          const text = $question.find("textarea").val();
          answer[question_id] = {
            title: question_title,
            result: {
              "-1": text,
            },
          };
        }
        break;
      case ANSWER_TYPE__TABLE:
        const question_id = $question.data("id");
        const question_title = $question.data("title");
        const itemsId = $(`#interview_answer_edit #question_${i} table`)
          .find("th")
          .map(function () {
            return $(this).data("item_id");
          })
          .get();
        let row_count = 1;
        const table_hash = {};
        $(`#interview_answer_edit #question_${i} table tbody tr`).map(
          function () {
            let send_flag = false;
            const row_hash = {};

            $(this)
              .find(".form-item-input")
              .map(function () {
                if ($(this).val()) {
                  send_flag = true;
                }
              });
            if (send_flag) {
              $(this)
                .find(".form-item-input")
                .map(function (n) {
                  row_hash[itemsId[n]] = $(this).val();
                });
              table_hash[row_count] = row_hash;
              row_count += 1;
            }
          }
        );
        if ("1" in table_hash) {
          answer[question_id] = {
            title: question_title,
            result: table_hash,
          };
        }
        break;
      default:
        break;
    }
  }
  return answer;
}
