export const FINANCIAL_STATEMENT_FIXES_CATEGORY_SET =
  "FINANCIAL_STATEMENT_FIXES_CATEGORY_SET";
export const FINANCIAL_STATEMENT_FIXES_PERIOD_SET =
  "FINANCIAL_STATEMENT_FIXES_PERIOD_SET";
export const FINANCIAL_STATEMENT_FIXES_UNIT_SET =
  "FINANCIAL_STATEMENT_FIXES_UNIT_SET";
export const FINANCIAL_STATEMENT_VALUABLE_ITEM_STRUCTURE_SET =
  "FINANCIAL_STATEMENT_VALUABLE_ITEM_STRUCTURE_SET";

const setCategory = ({ category }) => ({
  type: FINANCIAL_STATEMENT_FIXES_CATEGORY_SET,
  category,
});

const setPeriod = ({ period }) => ({
  type: FINANCIAL_STATEMENT_FIXES_PERIOD_SET,
  period,
});

const setUnit = ({ unit }) => ({
  type: FINANCIAL_STATEMENT_FIXES_UNIT_SET,
  unit,
});

const setValuableItemStructure = ({ structure }) => ({
  type: FINANCIAL_STATEMENT_VALUABLE_ITEM_STRUCTURE_SET,
  structure,
});

export const FinancialStatementScreenFixesAction = {
  setCategory,
  setPeriod,
  setUnit,
  setValuableItemStructure,
};
