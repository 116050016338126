import React from 'react'
import { hot } from 'react-hot-loader'

const TableFooter = props => {
  return(
    <tfoot className="text-center font-weight-bold fs-15">
    <tr>
      <td colSpan={6} >{props.name}</td>
      <td colSpan={4} >{props.book_v}</td>
      <td colSpan={4} >{props.diff_v}</td>
      <td colSpan={4} >{props.fixed_v}</td>
      <td colSpan={6} >-</td>
    </tr>            
  </tfoot>
  )
}

TableFooter.defaultProps = {
  name: "can't find name of TableFooter",
  book_v: null,
  diff_v: null,
  fixed_v: null,
}

export default hot(module)(TableFooter);