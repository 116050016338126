import React from 'react';
import { hot } from 'react-hot-loader'
import {
  InputName,
  InputBookV,
  InputDiffV,
  InputFixedV,
  InputComment,
  AddButton,
  DeleteButton,
} from '../input'

const LargeItemTr = props => {
  const has_items_middle = props.items_middle.length > 0
  const addedInFix = props.addedIn == "fix"

  //子要素がある　=> コメントのみ編集可能       x    追加     x    x     x      o      has_items_small
  //子要素がない => name以外編集可能          x    追加      x    o     o      o      !has_items_small
  //fixで追加されたもの => 全て編集可能       o     削除      x     o     o      o      addedInFix
  return (
    <React.Fragment>
      {has_items_middle && !addedInFix && <LargeItemTrWithItemsMiddle {...props} />}
      {!has_items_middle && !addedInFix && <LargeItemTrWithoutItemsMiddle {...props} />}
      {addedInFix && <LargeItemTrAddedInFix {...props} />}
    </React.Fragment>
  );
}
const LargeItemTrBase = props => {
  return (
    <tr>
      <td colSpan={5}>{props.inputName}</td>
      <td colSpan={1} className="icon">{props.iconButton}</td>
      <td colSpan={4}>{props.inputBookV}</td>
      <td colSpan={1} className="text-center">+</td>
      <td colSpan={3}>{props.inputDiffV}</td>
      <td colSpan={1} className="text-center">=</td>
      <td colSpan={3}>{props.inputFixedV}</td>
      <td colSpan={4}>{props.inputComment}</td>
    </tr>
  );
}
const LargeItemTrWithoutItemsMiddle = props => {
  return (
    <LargeItemTrBase
    inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="large" />}
      iconButton={
        <AddButton onClick={(e) => props.addMiddleItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="large" />}
      inputDiffV={
        <InputDiffV
          {...props}
          value={props.diff_v}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
      inputFixedV={
        <InputFixedV
          {...props}
          value={props.fixed_v}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
      inputComment={
        <InputComment
          {...props}
          value={props.comment}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
    />
  );
}

const LargeItemTrWithItemsMiddle = props => {
  return (
    <LargeItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="large" />}
      iconButton={
        <AddButton
          hasInfo={true}
          onClick={(e) => props.addMiddleItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="large" />}
      inputDiffV={
        <InputDiffV
          {...props}
          value={props.diff_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="large" />}
      inputFixedV={
        <InputFixedV
          {...props}
          value={props.fixed_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="large" />}
      inputComment={
        <InputComment
          {...props}
          value={props.comment}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
    />
  );
}

const LargeItemTrAddedInFix = props => {
  return (
    <LargeItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
      iconButton={
        <DeleteButton
          onClick={(e) => props.addMiddleItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="large" />}
      inputDiffV={
        <InputDiffV
          {...props}
          value={props.diff_v}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
      inputFixedV={
        <InputFixedV
          {...props}
          value={props.fixed_v}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
      inputComment={
        <InputComment
          {...props}
          value={props.comment}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large" />}
    />
  );
}

LargeItemTr.defaultProps = {
  name: "",
  book_v: null,
  diff_v: null,
  fixed_v: null,
  comment: "",
  code: "",
  addedIn: "default",
}

// default or bookでaddIconかdeleteIconか判断する
export default hot(module)(LargeItemTr);

