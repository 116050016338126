const getRecommendValue = (name, ocrResultList) => {
  const ocr_items =
    ocrResultList.length > 0 ? ocrResultList.flatMap((x) => x) : [];
  return ocrResultList.length > 0 ? calcItemValue(name, ocr_items) : null;
};

const calcItemValue = (name, ocrItems) => {
  switch (name) {
    case "売上高":
      return null;
    case "売上原価":
      return (
        parseInt(
          ocrItems.filter((item) => item["項目名"].match("^売上高$|^売上$"))[0][
            "金額1"
          ]
        ) -
        parseInt(
          ocrItems.filter((item) => item["項目名"].match("^売上総利益$"))[0][
            "金額1"
          ]
        )
      );
    case "販売費及び管理費":
      return (
        parseInt(
          ocrItems.filter((item) => item["項目名"].match("^売上総利益$"))[0][
            "金額1"
          ]
        ) -
        parseInt(
          ocrItems.filter((item) => item["項目名"].match("^営業利益$"))[0][
            "金額1"
          ]
        )
      );
    case "営業外収益":
      return null;
    case "営業外費用":
      return null;
    case "特別収益":
      return null;
    case "特別損失":
      return null;
    case "法人税等":
      return (
        parseInt(
          ocrItems.filter((item) =>
            item["項目名"].match("^税引前当期純利益$")
          )[0]["金額1"]
        ) -
        parseInt(
          ocrItems.filter((item) => item["項目名"].match("^当期純利益$"))[0][
            "金額1"
          ]
        )
      );
    default:
      break;
  }
  return null;
};

export const PlRecommender = {
  getRecommendValue,
};
