import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { hot } from 'react-hot-loader';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowBack from '@material-ui/icons/ArrowBack';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    padding: '30px',
    width: '130vh',
    height: '90vh', 
  },
};

class FinancialStatementModalWindow extends React.Component {

  constructor(props) {
    super(props);
    this.classes = useStyles;
    this.state = {
      opened: false,
      fsf_id: null,
      report_id: props.report_id,
      period: null,
      category: null,
      unit: {},
      title: null,
      apiUrl: null,
      codeValueDataset: [],
      correctionDataset: {
        items_large_corrected: [],
        items_middle_corrected: [],
      }
    }
    this.buttonRef = React.createRef();
  }

  handleOpen = () => {
    this.setState({ opened: true });
    this.setState({
      fsf_id: this.buttonRef.current.dataset.fsf_id,
      period: this.buttonRef.current.dataset.period,
      category: this.buttonRef.current.dataset.category,
    }, () => {
      this.getKeyValue()
    })
  };
  handleClose = () => {
    this.setState({ opened: false });
  };

  getKeyValue = () => {
    var apiUrl = `/reports/${this.state.report_id}/financial_statement_fixes/${this.state.fsf_id}`
    axios
      .get(apiUrl + "/form_for_reflect", { params: {} })
      .then((results) => {
        console.log(results.data)
        this.setState({
          codeValueDataset: results.data.fsf,
          correctionDataset: results.data.financial_corrections,
          title: results.data.title,
          unit: results.data.unit,
          apiUrl: apiUrl
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleUrlClick = (url) => {
    console.log(url)
    window.location.href = url;
  }

  renderTable() {
    let list = []
    const dataset = this.state.codeValueDataset
    if (![[], undefined, null].includes(dataset)) {
      if (dataset.length > 1) {
        dataset.forEach((v, i) => {
          list.push(this.renderTr(i, false))
        });
      } else if (dataset.length == 1) {
        list.push(this.renderTr(0, false))
      }
    }
    return (
      <React.Fragment>{list}</React.Fragment>
    );
  }

  renderTr(index, isCorrection, dataset=this.state.codeValueDataset) {
    return (
      <tr key={index} >
        {isCorrection &&
          <td className="text-center">{dataset[index]["parent_item_name"]}</td>
        }
        <td className="text-center">{dataset[index]["name"]}</td>
        <td>{dataset[index]["book_v"]}</td>
        {isCorrection &&
          <>
            <td>{dataset[index]["diff_v"]}</td>
            <td>{dataset[index]["fixed_v"]}</td>
          </>
        }
      </tr>
    );
  }

  renderCorrectionTable(items_corrected) {
    let list = []
    const dataset = items_corrected
    if (![[], undefined, null].includes(dataset)) {
      if (dataset.length > 1) {
        dataset.forEach((v, i) => {
          list.push(this.renderTr(i, true, items_corrected))
        });
      } else if (dataset.length == 1) {
        list.push(this.renderTr(0, true, items_corrected))
      }
    }
    return (
      <React.Fragment>{list}</React.Fragment>
    );
  }

  renderMainModal() {
    const title = this.state.title || ""
    const apiUrl = this.state.apiUrl || ""
    const category = this.state.category || ""
    const unitName = this.state.unit["name"] || ""
    const sag = category == 3
    const bs = category == 1
    const items_large_corrected = this.state.correctionDataset.items_large_corrected || null
    const items_middle_corrected = this.state.correctionDataset.items_middle_corrected || null
    return (
      <div>
        <button id="modal-button" type="button" onClick={this.handleOpen} style={{ display: "none" }} ref={this.buttonRef}>
          react-transition-group
      </button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={this.classes.modal}
          open={this.state.opened}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.opened}>
            <div style={this.classes.paper} id="financial_statement_fix-modal">
              <div className="report-title">以下の内容をフォームに反映します。</div>
              <div className="text-right fs-13">単位：{unitName}</div>
              <div className="row">
                <div className="col-5">
                  <div className="modal-scroll-area p-3">
                    <table className="table table-sm text-center mt-1">
                      <thead>
                        <tr><th colSpan={3}>{title}(簿価)</th></tr>
                        <tr>
                          <th>科目名</th>
                          <th>簿価</th>
                          {/* <th>評価額</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderTable()}
                      </tbody>
                    </table>
                  </div>
                  {sag && <div className="text-right text-secondary fs-13 mt-2">※ 追加情報により合計値が反映されます。</div>}
                  {bs && <div className="text-right text-secondary fs-13 mt-2">※ 有利子負債 = 短期借入金 + 長期借入金 + リース債務 + 社債</div>}
                </div>
                <div className="col-7">
                  <div className="modal-scroll-area p-3">
                    <table className="table table-sm text-center mt-1">
                      <thead>
                        <tr><th colSpan={5}>修正を適応する科目一覧</th></tr>
                        <tr>
                          <th>種別</th>
                          <th>科目名</th>
                          <th>簿価</th>
                          <th>修正額</th>
                          <th>評価額</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderCorrectionTable(items_large_corrected)}
                        {this.renderCorrectionTable(items_middle_corrected)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <Button
                  variant="contained"
                  style={{ backgroundColor: grey[600], color: "white" }}
                  startIcon={<ArrowBack>send</ArrowBack>}
                  onClick={this.handleClose}
                >
                  キャンセル
              </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[600], color: "white" }}
                  startIcon={<CloudUploadIcon>send</CloudUploadIcon>}
                  onClick={() => this.handleUrlClick(`${apiUrl}/reflect_to_financial?value_type=book_v&next_url=${location.href}&next_params=${location.search}`)}
                  className="ml-2"
                >
                  フォームに反映
              </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div >
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderMainModal()}
        {/* {this.renderLoadingModal()} */}
      </React.Fragment>
    );
  }
}

const ModalWindowContainer = hot(module)(FinancialStatementModalWindow);
export default ModalWindowContainer
