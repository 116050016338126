import React, { useState, useRef, useEffect} from 'react'
import { hot } from 'react-hot-loader'
import InputNumber from './InputNumber';


const InputBookV = props => {
  const valueStatus = props.valueStatus
  const tabIndex = valueStatus == "solid" || valueStatus == "sum" ? -1 : 0

  return (
    <React.Fragment>
      {props.edittable && (
        <label className="fs-label book-v">
          <InputNumber
            {...props}
            className={`fs-input fs-${props.kindOfItem} book-v`}
            name="book_v"
            value={props.value}
            onChange={(e) => props.onChange(e)}
          />
        </label>
      )}
      {!props.edittable && (
        <label className={`fs-label ${props.valueStatus} book-v`}>
          <InputNumber
            {...props}
            className={`fs-input fs-${props.kindOfItem} book-v ${props.valueStatus}`}
            name="book_v"
            value={props.value}
            tabIndex={tabIndex}
            readOnly />
        </label>
      )}
    </React.Fragment>
  );
};


InputBookV.defaultProps = {
  value: "",
  kindOfItem: "small",
  valueStatus: "",
  edittable: false,
  onChange: null,
  inputRef: null,
  style: {},
}

export default hot(module)(InputBookV);
