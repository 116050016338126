import {
  FINANCIAL_STATEMENT_FIXES_CATEGORY_SET,
  FINANCIAL_STATEMENT_FIXES_PERIOD_SET,
  FINANCIAL_STATEMENT_FIXES_UNIT_SET,
  FINANCIAL_STATEMENT_VALUABLE_ITEM_STRUCTURE_SET,
} from "../../../actions/financialActions/financialStatementScreens/FinancialStatementScreenFixesAction";

function financialStatementScreensFixesReducer(state = {}, action) {
  switch (action.type) {
    case FINANCIAL_STATEMENT_FIXES_CATEGORY_SET:
      const { category } = action;
      return { ...state, category };
    case FINANCIAL_STATEMENT_FIXES_PERIOD_SET:
      const { period } = action;
      return { ...state, period };
    case FINANCIAL_STATEMENT_FIXES_UNIT_SET:
      const { unit } = action;
      return { ...state, unit };
    case FINANCIAL_STATEMENT_VALUABLE_ITEM_STRUCTURE_SET:
      const { structure } = action;
      return { ...state, structure };
    default:
      return { ...state };
  }
}

export default financialStatementScreensFixesReducer;
