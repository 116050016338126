import {
  STATEMENT_CATEGORY_FIX_INIT_PROPS,
  STATEMENT_CATEGORY_FIX_EDIT_ITEM,
  STATEMENT_CATEGORY_FIX_ADD_ITEM,
  STATEMENT_CATEGORY_FIX_DELETE_ITEM
} from '../../../actions/financialActions/financialStatements/StatementCategoryFixAction';

function statementCategoryFixReducer(state = {}, action) {
  let dataset = []
  let datasetKey = ""
  switch (action.type) {
    
    case STATEMENT_CATEGORY_FIX_INIT_PROPS:
      return Object.assign({}, state, { dataset: action.dataset })

    case STATEMENT_CATEGORY_FIX_EDIT_ITEM:
      dataset = action.dataset;
      datasetKey= getDatasetKey(action.e)
      //項目名か金額1かの判断をしたい
      // name = action.e.target.name.split('_')[-1]
      dataset[action.index][datasetKey] = action.e.target.value
      return Object.assign({}, state, { dataset: dataset })
    
    case STATEMENT_CATEGORY_FIX_ADD_ITEM:
      dataset = action.dataset
      dataset.push(action.item)
      return Object.assign( {}, {dataset: dataset })
    
    case STATEMENT_CATEGORY_FIX_DELETE_ITEM:
      dataset = action.dataset
      dataset.splice(action.index, 1)
      return Object.assign( {}, {dataset: dataset })
    
    default:
      return state;
  }
}

function getDatasetKey(e) {
  let r = []
  r = e.target.name.split(/\[|\]/).filter(function (el) {
    return el != "";
  });
  switch (r[0]) {
    case "name":
      return "項目名"
    case "value":
      return "金額1"
    default:
      return ""
  }
  
}

export default statementCategoryFixReducer;
