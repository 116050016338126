import {
  PL_FIX_INIT_PROPS,
  PL_FIX_ADD_SMALL_ITEM,
  PL_FIX_ADD_MIDDLE_ITEM,
  PL_FIX_ADD_LARGE_ITEM,
  PL_FIX_REMOVE_SMALL_ITEM,
  PL_FIX_REMOVE_MIDDLE_ITEM,
  PL_FIX_REMOVE_LARGE_ITEM,
  PL_FIX_EDIT_SMALL_ITEM,
  PL_FIX_EDIT_MIDDLE_ITEM,
  PL_FIX_EDIT_LARGE_ITEM,
  PL_FIX_EDIT_OTHER_ITEM,

  PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS,  
  PL_FIX_CALC_SUMS_ITEMS_BY_LARGE_ITEMS,
  
} from "../../../actions/financialActions/financialStatements/ProfitAndLossFixAction";

function profitAndLossFixReducer(state = {}, action) {

  switch (action.type) {
  
    case PL_FIX_INIT_PROPS:
      return Object.assign({}, state, action.body)

    case PL_FIX_ADD_SMALL_ITEM:
      return {...state, ...{items_small: action.items_small}}

    case PL_FIX_ADD_MIDDLE_ITEM:
      return {...state, ...{items_middle: action.items_middle}}

    case PL_FIX_ADD_LARGE_ITEM:
      return Object.assign({}, state, {})

    case PL_FIX_REMOVE_SMALL_ITEM:
      return { ...state, ...{ items_small: action.items_small } }
    
    case PL_FIX_REMOVE_MIDDLE_ITEM:
      return { ...state, ...{ items_middle: action.items_middle } }

    case PL_FIX_REMOVE_LARGE_ITEM:
      return Object.assign({}, state, {})
    
    case PL_FIX_EDIT_SMALL_ITEM:
      return { ...state, ...{ items_small: action.items_small } }

    case PL_FIX_EDIT_MIDDLE_ITEM:
      return { ...state, ...{ items_middle: action.items_middle } }

    case PL_FIX_EDIT_LARGE_ITEM:
      return { ...state, ...{ items_large: action.items_large } }

    case PL_FIX_EDIT_OTHER_ITEM:
      return { ...state, ...{ items_other: action.items_other } }

      case PL_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS:
        return { ...state, ...{ items_middle: action.items_middle } }

      case PL_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS:
        return { ...state, ...{ items_large: action.items_large } }

      case PL_FIX_CALC_SUMS_ITEMS_BY_LARGE_ITEMS:
        return { ...state, ...{ items_sums: action.items_sums } }


    default:
      return state;
  }
}
export default profitAndLossFixReducer;
