import React from "react";
import { connect } from "react-redux";
import SearchAppProvider from "../../../../appProviders/FinancialAppProvider";
import SmallItemTr from "../share/book/SmallItemTr";
import MiddleItemTr from "../share/book/MiddleItemTr";
import LargeItemTr from "../share/book/LargeItemTr";
import OtherMiddleItemTr from "../share/book/OtherMiddleItemTr";
import SumsItemTr from "../share/book/SumsItemTr";
import { hot } from "react-hot-loader";
import { ProfitAndLossAction } from "../../../../../actions/financialActions/financialStatements/ProfitAndLossAction";
import { HashTools } from "../../../../../modules/financialStatements/hashTools";
import { ArrayTools } from "../../../../../modules/financialStatements/arrayTools";
import { Association } from "../../../../../modules/financialStatements/associationTools";
import { refsCreator } from "../../../../../modules/financialStatements/refsCreator";
import { PlRecommender } from "../../../../../modules/financialStatements/plRecommender";
import { debounce } from "throttle-debounce";
import { TooltipsAction } from "../../../../../actions/financialActions/financialStatements/TooltipsAction";
import { getReportId } from "../../../../../modules/financialStatements/querySearcher";

class ProfitAndLoss extends React.Component {
  // noinspection DuplicatedCode
  constructor(props) {
    super(props);
    this.items_sums = this.props.items_sums;
    this.items_large = this.props.items_large;
    this.items_middle = this.props.items_middle;
    this.items_small = this.props.items_small;
    this.items_other = this.props.items_other;
    this.body = {
      items_sums: this.items_sums,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    this.editSmallItem = this.editSmallItem.bind(this);
    this.editMiddleItem = this.editMiddleItem.bind(this);
    this.editLargeItem = this.editLargeItem.bind(this);
    this.editSmallItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditSmallItem(this.body, id, name, value)
    );
    this.editMiddleItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditMiddleItem(this.body, id, name, value)
    );
    this.editLargeItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditLargeItem(this.body, id, name, value)
    );
    this.referenceRecommendValue = this.referenceRecommendValue.bind(this);

    this.handleInit(this.body);
    this.report_id = getReportId();
    this.props.initTooltips(this.report_id);
  }

  handleInit(body) {
    this.props.handleInit(body);
  }

  addSmallItem(middleId) {
    const small_id = Association.getLastIdPlusOne(this.items_small, "small_id");
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    const middleItemHasChildren = ArrayTools.checkArray(smallItems);
    const parentMiddleItem = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const book_v = middleItemHasChildren ? 0 : parentMiddleItem["book_v"];
    const diff_v = middleItemHasChildren ? 0 : parentMiddleItem["diff_v"];
    const fixed_v = middleItemHasChildren ? 0 : parentMiddleItem["fixed_v"];
    // 中項目の子要素が存在しない場合、追加される小項目の簿価は中項目の簿価になる
    const item_small = {
      small_id,
      middle_id: middleId,
      name: "",
      book_v,
      diff_v,
      fixed_v,
      addedIn: "book",
    };
    this.props.handleAddSmallItem(this.body, item_small);
  }
  addMiddleItem(largeId) {
    const middle_id = Association.getLastIdPlusOne(
      this.items_middle,
      "middle_id"
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const largeItemHasChildren = ArrayTools.checkArray(middleItems);
    const parentLargeItem = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const book_v = largeItemHasChildren ? 0 : parentLargeItem["book_v"];
    const diff_v = largeItemHasChildren ? 0 : parentLargeItem["diff_v"];
    const fixed_v = largeItemHasChildren ? 0 : parentLargeItem["fixed_v"];
    const item_middle = {
      middle_id,
      large_id: largeId,
      name: "",
      book_v,
      diff_v,
      fixed_v,
      addedIn: "book",
    };
    this.props.handleAddMiddleItem(this.body, item_middle);
  }
  // addLargeItem(e) {
  //   const large_id = Association.getLastIdPlusOne(this.items_l, "large_id")
  //   const item_l = {"large_id": large_id, "name": "", "book_v": 0, "diff_v": 0, "fixed_v": 0, "addedIn": "book"}
  //   this.props.handleAddLargeItem(this.body, this.items_l, item_l)
  // }
  removeSmallItem(smallId) {
    this.props.handleRemoveSmallItem(this.body, smallId);
  }
  removeMiddleItem(middleId) {
    this.props.handleRemoveMiddleItem(this.body, middleId);
  }
  // removeLargeItem() {

  // }
  editSmallItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editSmallItemDebounce(id, name, value);
    e.preventDefault();
  }
  editMiddleItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editMiddleItemDebounce(id, name, value);
    e.preventDefault();
  }
  editLargeItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editLargeItemDebounce(id, name, value);
    e.preventDefault();
  }

  // ==================================
  // Drag & Drop
  handleDragOver(e, ref) {
    ref.current.style.opacity = "0.4";
    ref.current.style.backgroundColor = "Yellow";
    e.preventDefault();
  }

  handleDragLeave(e, ref) {
    ref.current.style.opacity = "1";
    ref.current.style.backgroundColor = "white";
    e.preventDefault();
  }

  handleDropAddSmallItem(e, ref, middleId) {
    ref.current.style.opacity = "1";
    ref.current.style.backgroundColor = "white";
    const small_id = Association.getLastIdPlusOne(this.items_small, "small_id");
    let item_small = JSON.parse(e.dataTransfer.getData("text/plain"));
    item_small = Object.assign(item_small, {
      middle_id: middleId,
      small_id,
    });
    e.preventDefault();
    this.props.handleAddSmallItem(this.body, item_small);
  }
  handleDropAddMiddleItem(e, ref, largeId) {
    ref.current.style.backgroundColor = "white";
    ref.current.style.opacity = "1";
    const middle_id = Association.getLastIdPlusOne(
      this.items_middle,
      "middle_id"
    );
    let item_middle = JSON.parse(e.dataTransfer.getData("text/plain"));
    item_middle = Object.assign(item_middle, {
      middle_id,
      large_id: largeId,
    });
    e.preventDefault();
    this.props.handleAddMiddleItem(this.body, item_middle);
  }

  // noinspection DuplicatedCode
  handleDropSelfValue(e, ref, idName, id) {
    ref.current.style.backgroundColor = "white";
    ref.current.style.opacity = "1";
    let item = JSON.parse(e.dataTransfer.getData("text/plain"));
    e.preventDefault();
    switch (idName) {
      case "large_id":
        this.props.handleEditLargeItem(this.body, id, "book_v", item["book_v"]);
        if (
          this.items_large.filter((item_large) => item_large.large_id === id)[0]
            .name === ""
        ) {
          this.props.handleEditLargeItem(
            this.props.financialStatements.profitAndLoss.book,
            id,
            "name",
            item["name"]
          );
        }
        break;
      case "middle_id":
        this.props.handleEditMiddleItem(
          this.body,
          id,
          "book_v",
          item["book_v"]
        );
        if (
          this.items_middle.filter(
            (item_middle) => item_middle.middle_id === id
          )[0].name === ""
        ) {
          this.props.handleEditMiddleItem(
            this.props.financialStatements.profitAndLoss.book,
            id,
            "name",
            item["name"]
          );
        }
        break;
      case "small_id":
        this.props.handleEditSmallItem(this.body, id, "book_v", item["book_v"]);
        if (
          this.items_small.filter((item_small) => item_small.small_id === id)[0]
            .name === ""
        ) {
          this.props.handleEditSmallItem(
            this.props.financialStatements.profitAndLoss.book,
            id,
            "name",
            item["name"]
          );
        }
        break;
      default:
        break;
    }
  }
  handleDropSelfName(e, ref, idName, id) {
    ref.current.style.backgroundColor = "white";
    ref.current.style.opacity = "1";
    let item = JSON.parse(e.dataTransfer.getData("text/plain"));
    e.preventDefault();
    switch (idName) {
      case "large_id":
        this.props.handleEditLargeItem(this.body, id, "name", item["name"]);
        break;
      case "middle_id":
        this.props.handleEditMiddleItem(this.body, id, "name", item["name"]);
        break;
      case "small_id":
        this.props.handleEditSmallItem(this.body, id, "name", item["name"]);
        break;
      default:
        break;
    }
  }

  referenceRecommendValue(e) {
    const name = e.target.dataset.name;
    const value = e.target.dataset.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    // noinspection JSUnresolvedVariable,JSUnusedLocalSymbols
    const id_name = e.target.dataset.id_name;
    this.props.handleEditLargeItem(this.body, id, name, value);
    e.preventDefault();
  }

  renderSmallItem(smallId) {
    const item_small = Association.getItemById(
      this.items_small,
      "small_id",
      smallId
    );
    if (
      ArrayTools.checkArray(this.items_small) &&
      HashTools.checkHash(item_small)
    ) {
      const selfValueRef = refsCreator.findRefFromRefsDictById(
        this.smallItemsRefs,
        "small_id",
        "selfValueRef",
        smallId
      );
      const selfNameRef = refsCreator.findRefFromRefsDictById(
        this.smallItemsRefs,
        "small_id",
        "selfNameRef",
        smallId
      );
      const refs = { selfValueRef, selfNameRef };
      return (
        <SmallItemTr
          refs={refs}
          key={smallId}
          name={item_small["name"]}
          book_v={item_small["book_v"]}
          diff_v={item_small["diff_v"]}
          fixed_v={item_small["fixed_v"]}
          comment={item_small["comment"]}
          addedIn={item_small["addedIn"]}
          removeSmallItem={() => this.removeSmallItem(smallId)}
          editSmallItem={this.editSmallItem}
          onDropSelfValueRef={(e) =>
            this.handleDropSelfValue(e, selfValueRef, "small_id", smallId)
          }
          onDropSelfNameRef={(e) =>
            this.handleDropSelfName(e, selfNameRef, "small_id", smallId)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
          data_item_id={smallId}
          data_item_name="small_id"
        />
      );
    } else {
      return false;
    }
  }

  renderSmallItems(middleId) {
    let list = [];
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    if (ArrayTools.checkArray(smallItems)) {
      smallItems.forEach((v) => list.push(this.renderSmallItem(v["small_id"])));
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  renderMiddleItem(middleId) {
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    const addItemRef = refsCreator.findRefFromRefsDictById(
      this.middleItemsRefs,
      "middle_id",
      "addChildRef",
      middleId
    );
    const selfValueRef = refsCreator.findRefFromRefsDictById(
      this.middleItemsRefs,
      "middle_id",
      "selfValueRef",
      middleId
    );
    const selfNameRef = refsCreator.findRefFromRefsDictById(
      this.middleItemsRefs,
      "middle_id",
      "selfNameRef",
      middleId
    );
    const refs = {
      addItemRef,
      selfValueRef,
      selfNameRef,
    };
    const tooltip_text = this.props.financialStatements.tooltips.body[
      item_middle.code
    ];
    return (
      <React.Fragment key={middleId}>
        <MiddleItemTr
          refs={refs}
          key={middleId}
          name={item_middle["name"]}
          book_v={item_middle["book_v"]}
          diff_v={item_middle["diff_v"]}
          fixed_v={item_middle["fixed_v"]}
          comment={item_middle["comment"]}
          addedIn={item_middle["addedIn"]}
          items_small={smallItems}
          addSmallItem={() => this.addSmallItem(middleId)}
          removeMiddleItem={() => this.removeMiddleItem(middleId)}
          editMiddleItem={this.editMiddleItem}
          onDropAddItemRef={(e) =>
            this.handleDropAddSmallItem(e, addItemRef, middleId)
          }
          onDropSelfValueRef={(e) =>
            this.handleDropSelfValue(e, selfValueRef, "middle_id", middleId)
          }
          onDropSelfNameRef={(e) =>
            this.handleDropSelfName(e, selfNameRef, "middle_id", middleId)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
          data_item_id={middleId}
          data_item_name="middle_id"
          tooltip_text={tooltip_text}
        />
        {this.renderSmallItems(middleId)}
      </React.Fragment>
    );
  }

  renderMiddleItems(largeId) {
    let list = [];
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    if (ArrayTools.checkArray(middleItems)) {
      middleItems.forEach((v) =>
        list.push(this.renderMiddleItem(v["middle_id"]))
      );
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  renderLargeItem(largeId) {
    const item_large = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const otherItem = Association.getItemById(
      this.items_other,
      "large_id",
      largeId
    );
    const addItemRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "addChildRef",
      largeId
    );
    const selfValueRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "selfValueRef",
      largeId
    );
    const selfNameRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "selfNameRef",
      largeId
    );
    const refs = {
      addItemRef,
      selfValueRef,
      selfNameRef,
    };
    const ocrResultList = Object.values(
      this.props.financialStatements.referenceArea.ocrResultList
    );

    const recommend_value =
      item_large["book_v"] === 0
        ? PlRecommender.getRecommendValue(item_large["name"], ocrResultList)
        : null;
    return (
      <React.Fragment key={largeId}>
        <LargeItemTr
          refs={refs}
          key={largeId}
          item_large={item_large}
          name={item_large["name"]}
          book_v={item_large["book_v"]}
          diff_v={item_large["diff_v"]}
          fixed_v={item_large["fixed_v"]}
          comment={item_large["comment"]}
          addedIn={item_large["addedIn"]}
          items_middle={middleItems}
          addMiddleItem={() => this.addMiddleItem(largeId)}
          removeLargeItem={() => {}}
          editLargeItem={this.editLargeItem}
          onDropAddItemRef={(e) =>
            this.handleDropAddMiddleItem(e, addItemRef, largeId)
          }
          onDropSelfValueRef={(e) =>
            this.handleDropSelfValue(e, selfValueRef, "large_id", largeId)
          }
          onDropSelfNameRef={(e) =>
            this.handleDropSelfValue(e, selfNameRef, "large_id", largeId)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
          data_item_id={largeId}
          data_item_name="large_id"
          recommend_value={recommend_value}
          referenceRecommendValue={this.referenceRecommendValue}
        />
        {this.renderMiddleItems(largeId)}
        {/* <AddMiddleItemTr addMiddleItem={(e)=> this.addMiddleItem(large_id,e)}/> */}
        <OtherMiddleItemTr book_v={otherItem["book_v"]} />
      </React.Fragment>
    );
  }

  renderSumsItem(sumsId) {
    const item_sum = Association.getItemById(
      this.items_sums,
      "sums_id",
      sumsId
    );
    const ocrResultList = Object.values(
      this.props.financialStatements.referenceArea.ocrResultList
    );
    const items_reference_warning =
      ocrResultList.length > 0
        ? ocrResultList
            .flatMap((x) => x)
            .filter(
              (item) =>
                item["項目名"] === item_sum["name"] &&
                parseInt(item["金額1"]) !== item_sum["book_v"]
            )
        : null;
    const reference_warning =
      items_reference_warning != null && items_reference_warning.length > 0;
    const warning_text = reference_warning ? "OCR結果と不一致" : "";

    return (
      <SumsItemTr
        name={item_sum["name"]}
        book_v={item_sum["book_v"]}
        diff_v={item_sum["diff_v"]}
        fixed_v={item_sum["fixed_v"]}
        comment={item_sum["comment"]}
        warning={reference_warning}
        warning_text={warning_text}
      />
    );
  }

  render() {
    const state = this.props.financialStatements["profitAndLoss"]["book"];
    this.items_sums = state["items_sums"];
    this.items_large = state["items_large"];
    this.items_middle = state["items_middle"];
    this.items_small = state["items_small"];
    this.items_other = state["items_other"];
    this.body = {
      items_sums: this.items_sums,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    this.largeItemsRefs = refsCreator.createRefsDictByItems(
      this.items_large,
      "large_id"
    );
    this.middleItemsRefs = refsCreator.createRefsDictByItems(
      this.items_middle,
      "middle_id"
    );
    this.smallItemsRefs = refsCreator.createRefsDictByItems(
      this.items_small,
      "small_id"
    );
    if (this.items_sums == null) {
      return false;
    } else {
      return (
        <React.Fragment>
          <div className="fs-11 px-2 contents-block">
            <div className="row scroll-area px-2">
              <div className="col-12">
                <table className="w-100">
                  <thead>
                    <tr>
                      <td
                        width={"100%"}
                        colSpan={24}
                        className="text-center font-weight-bold fs-15"
                      >
                        損益計算書
                      </td>
                    </tr>
                    <tr>
                      <td
                        width={"45%"}
                        colSpan={11}
                        className="text-center font-weight-bold fs-13"
                      >
                        科目
                      </td>
                      <td width={"5%"} colSpan={1}></td>
                      <td
                        colSpan={12}
                        className="text-center font-weight-bold fs-13"
                      >
                        金額
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderLargeItem(0)}
                    {this.renderLargeItem(1)}
                    {this.renderSumsItem(0)}
                    {this.renderLargeItem(2)}
                    {this.renderSumsItem(1)}
                    {this.renderLargeItem(3)}
                    {this.renderLargeItem(4)}
                    {this.renderSumsItem(2)}
                    {this.renderLargeItem(5)}
                    {this.renderLargeItem(6)}
                    {this.renderSumsItem(3)}
                    {this.renderLargeItem(7)}
                    {this.renderSumsItem(4)}
                  </tbody>
                </table>
              </div>
              <input
                type="hidden"
                name="financial_statement_fix[body]"
                value={JSON.stringify(this.body)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    handleInit(body) {
      dispatch(ProfitAndLossAction.plInitProps(body));
    },
    handleAddSmallItem(body, item_small) {
      dispatch(ProfitAndLossAction.plAddSmallItem(body, item_small));
    },
    handleAddMiddleItem(body, item_middle) {
      dispatch(ProfitAndLossAction.plAddMiddleItem(body, item_middle));
    },
    handleAddLargeItem(body, item_large) {
      dispatch(ProfitAndLossAction.plAddLargeItem(body, item_large));
    },
    handleRemoveSmallItem(body, small_id) {
      dispatch(ProfitAndLossAction.plRemoveSmallItem(body, small_id));
    },
    handleRemoveMiddleItem(body, middle_id) {
      dispatch(ProfitAndLossAction.plRemoveMiddleItem(body, middle_id));
    },
    handleRemoveLargeItem(body, large_id) {
      dispatch(ProfitAndLossAction.plRemoveLargeItem(body, large_id));
    },
    handleEditSmallItem(body, small_id, name, value) {
      dispatch(
        ProfitAndLossAction.plEditSmallItem(body, small_id, name, value)
      );
    },
    handleEditMiddleItem(body, middle_id, name, value) {
      dispatch(
        ProfitAndLossAction.plEditMiddleItem(body, middle_id, name, value)
      );
    },
    handleEditLargeItem(body, large_id, name, value) {
      dispatch(
        ProfitAndLossAction.plEditLargeItem(body, large_id, name, value)
      );
    },
    initTooltips(report_id) {
      dispatch(TooltipsAction.tooltipsRequest(report_id));
    },
  };
}

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(ProfitAndLoss)
);
