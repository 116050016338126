import React from 'react';
import { hot } from 'react-hot-loader'

import {
  InputName,
  InputBookV,
  DeleteButton,
}
  from '../input'

const SmallItemTr = props => {
  return (
    <React.Fragment>
      <SmallItemTrBase {...props} />
    </React.Fragment>
  )
}

const SmallItemTrBase = props => {
  const selfValueRef = props.refs != undefined ? props.refs.selfValueRef : null
  const selfNameRef = props.refs != undefined ? props.refs.selfNameRef : null
  return (
    <tr>
      <td
        colSpan={11}
        className="indent-3"
        ref={selfNameRef}
        onDragLeave={(e) => props.onDragLeave(e, selfNameRef)}
        onDragOver={(e) => props.onDragOver(e, selfNameRef)}
        onDrop={(e) => props.onDropSelfNameRef(e)}
      >
        <InputName
          {...props}
          value={props.name}
          edittable={true}
          onChange={(e) => props.editSmallItem(e)}
          kindOfItem="small"
        />
      </td>
      <td colSpan={1} className="icon">
        <DeleteButton
          {...props}
          onClick={(e) => props.removeSmallItem(e)}
        />
      </td>
      <td
        colSpan={12}
        ref={selfValueRef}
        onDragLeave={(e) => props.onDragLeave(e, selfValueRef)}
        onDragOver={(e) => props.onDragOver(e, selfValueRef)}
        onDrop={(e) => props.onDropSelfValueRef(e)}
      >
        <InputBookV
          {...props}
          value={props.book_v}
          edittable={true}
          onChange={(e) => props.editSmallItem(e)}
          kindOfItem="small"
        />
      </td>
    </tr>
  )
}

SmallItemTr.defaultProps = {
  name: "",
  book_v: null,
  diff_v: null,
  fixed_v: null,
  comment: "",
  code: "",
  addedIn: "default",
}

export default hot(module)(SmallItemTr);
