import React from 'react';
import { hot } from 'react-hot-loader'


import {
  InputName,
  AddButton,
  DeleteButton,
  InputBookV,
} from '../input'


const LargeItemTr = props => {
  const has_items_middle = props.items_middle.length > 0
  const addedInDefault = props.addedIn == "default" || !props.addedIn
  const addedInBook = props.addedIn == "book"


  return (
    <React.Fragment>
      {addedInDefault &&
        <LargeItemTrAddedInDefault
          {...props}
          has_items_middle={has_items_middle}
        />}
      {addedInBook &&
        <LargeItemTrAddedInBook
          {...props}
        />}
    </React.Fragment>
  );
}

const LargeItemTrAddedInDefault = props => {
  const addItemRef = props.refs != undefined ? props.refs.addItemRef : null
  const selfValueRef = props.refs != undefined ? props.refs.selfValueRef : null
  const selfNameRef = props.refs != undefined ? props.refs.selfNameRef : null
  const {recommend_value, data_item_id, data_item_name} = props
  return (
    <tr>
      <td colSpan={11} >
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="large"
        />
      </td>
      <td colSpan={1} className="icon"
        ref={addItemRef}
        onDragLeave={(e) => props.onDragLeave(e, addItemRef)}
        onDragOver={(e) => props.onDragOver(e, addItemRef)}
        onDrop={(e) => props.onDropAddItemRef(e)}
      >
        <AddButton
          hasInfo={props.has_items_middle}
          onClick={(e) => props.addMiddleItem(e)}
        />
      </td>
      {recommend_value && 
      <>
        <td colSpan={5} className="text-center text-warning">
          「{recommend_value}」ではありませんか？
        </td>
        <td colSpan={1}>
          <div
            className="btn btn-xs btn-pill btn-outline-warning"
            data-item_id={data_item_id}
            data-item_name={data_item_name}
            data-value={recommend_value}
            data-name="book_v"
            onClick={props.referenceRecommendValue}
          >
            反映する<i className="fa fa-arrow-circle-right"></i>
          </div>
        </td>
      </>
      }
      <td
        colSpan={recommend_value ? 6 : 12}
        ref={selfValueRef}
        onDragLeave={(e) => props.onDragLeave(e, selfValueRef)}
        onDragOver={(e) => props.onDragOver(e, selfValueRef)}
        onDrop={(e) => props.onDropSelfValueRef(e)}
      >
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="sum"
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large"
        />
      </td>
    </tr>
  )
}

const LargeItemTrAddedInBook = props => {
  const addItemRef = props.refs != undefined ? props.refs.addItemRef : null
  const selfValueRef = props.refs != undefined ? props.refs.selfValueRef : null
  const selfNameRef = props.refs != undefined ? props.refs.selfNameRef : null
  return (
    <tr>
      <td colSpan={11}
        ref={selfNameRef}
        onDragLeave={(e) => props.onDragLeave(e, selfNameRef)}
        onDragOver={(e) => props.onDragOver(e, selfNameRef)}
        onDrop={(e) => props.onDropSelfNameRef(e)}
      >
        <InputName
          {...props}
          value={props.name}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="middle"
        />
      </td>
      <td colSpan={1} className="icon">
        <DeleteButton
          onClick={(e) => props.removeLargeItem(e)} />
      </td>
      <td colSpan={12}
        ref={selfValueRef}
        onDragLeave={(e) => props.onDragLeave(e, selfValueRef)}
        onDragOver={(e) => props.onDragOver(e, selfValueRef)}
        onDrop={(e) => props.onDropSelfValueRef(e)}
      >
        <InputBookV
          {...props}
          value={props.book_v}
          edittable={true}
          onChange={(e) => props.editLargeItem(e)}
          kindOfItem="large"
        />
      </td>
    </tr>
  )
}


LargeItemTr.defaultProps = {
  name: "",
  book_v: null,
  diff_v: null,
  fixed_v: null,
  comment: "",
  code: "",
  addedIn: "default",
  recommend_value: null,
}

// default or bookでaddIconかdeleteIconか判断する
export default hot(module)(LargeItemTr);

