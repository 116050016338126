import {CURRENT_USER_CHECK_LOGIN} from "../actions/CurrentUserAction";

function currentUserReducer(state = {}, action) {
    switch (action.type) {
        case CURRENT_USER_CHECK_LOGIN:
            return {...state, isLogged: action.isLogged};
        default:
            return state;
    }
}

export default currentUserReducer;
