import React, { useState } from "react";

const InputNumber = (props) => {
  const [isControlled, setIsControlled] = useState(true);

  const value_tmp = String(props.value) || "";

  const value =
    value_tmp
      .replace(/[^\d.-]/g, "")
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") || "";
  //const value = value_tmp
  const valueProperty = isControlled ? { value } : { defaultValue: value };
  const { data_item_id, data_item_name } = props;
  const style = props.style || {};

  const handleChange = (e) => {
    e.target.value = e.target.value
      .replace(/[^\d.-]/g, "")
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    props.onChange(e);
  };

  const handleOnFocus = (e) => {
    setIsControlled(false);
  };
  const handleOnBlur = (e) => {
    setIsControlled(true);
  };

  return (
    <input
      type="text"
      className={props.className}
      placeholder={props.placeholder}
      disabled={props.disabled}
      name={props.name}
      {...valueProperty}
      onChange={(e) => handleChange(e)}
      maxLength={20}
      readOnly={props.readOnly}
      indent={props.indent}
      tabIndex={props.tabIndex}
      autoComplete={props.autoComplete}
      maxLength={15}
      step={0.001}
      ref={props.inputRef}
      data-item_id={data_item_id || ""}
      data-item_name={data_item_name || ""}
      data-lpignore="true"
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      style={style}
    />
  );
};

InputNumber.defaultProps = {
  className: "",
  placeholder: "",
  disabled: false,
  name: "",
  value: "",
  devalutValue: "",
  readOnly: false,
  indent: 0,
  tabIndex: 0,
  autoComplete: "off",
  maxLength: 15,
  step: 0.001,
  inputRef: null,
  style: {},
  "data-lpignore": "true",
};

export default InputNumber;
