import React from "react";
import FinancialStatementFixesContainer from "./FinancialStatementFixesContainer";
import FinancialAppProvider from "../../../appProviders/FinancialAppProvider";

/**
 * 決算書入力画面
 */
class FinancialStatementFixesViewContainer extends React.Component {
  render() {
    return (
      <FinancialAppProvider>
        <FinancialStatementFixesContainer {...this.props} />
      </FinancialAppProvider>
    );
  }
}
export default FinancialStatementFixesViewContainer;
