$(() =>
  $(
    "#q_purchase_cont, #q_target_cont,\
      #q_date_gteq, #q_date_lteq,\
      #q_title_cont,\
      #q_method_eq,\
      #q_edit_flag_eq,\
      #q_tar_category_eq, #q_pur_category_eq,\
      #q_tar_country_eq, #q_pur_country_eq,\
      #q_cross_border_eq,\
      #q_price_null"
  ).on("change", () => {
    $("form").submit();
  })
);
