import {
  BS_INIT_PROPS,
  BS_ADD_SMALL_ITEM,
  BS_ADD_MIDDLE_ITEM,
  BS_ADD_LARGE_ITEM,
  BS_REMOVE_SMALL_ITEM,
  BS_REMOVE_MIDDLE_ITEM,
  BS_REMOVE_LARGE_ITEM,
  BS_EDIT_SMALL_ITEM,
  BS_EDIT_MIDDLE_ITEM,
  BS_EDIT_LARGE_ITEM,
  BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  BS_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS,
  BS_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS,
  BS_AUTO_INPUT_BY_OCR_RESULT,
  BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS,
} from "../../../actions/financialActions/financialStatements/BalanceSheetAction";

function balanceSheetReducer(state = {}, action) {
  switch (action.type) {
    case BS_INIT_PROPS:
      return Object.assign({}, state, action.body);

    case BS_ADD_SMALL_ITEM:
      return {
        ...state,
        ...{
          items_small: action.items_small,
        },
      };

    case BS_ADD_MIDDLE_ITEM:
      return {
        ...state,
        ...{
          items_middle: action.items_middle,
        },
      };

    case BS_ADD_LARGE_ITEM:
      return Object.assign({}, state, {});

    case BS_REMOVE_SMALL_ITEM:
      return {
        ...state,
        ...{
          items_small: action.items_small,
        },
      };

    case BS_REMOVE_MIDDLE_ITEM:
      return {
        ...state,
        ...{
          items_middle: action.items_middle,
        },
      };

    //　大項目の削除は想定しない
    // case BS_REMOVE_LARGE_ITEM:
    //   return Object.assign({}, state, {})

    case BS_EDIT_SMALL_ITEM:
      return {
        ...state,
        ...{
          items_small: action.items_small,
        },
      };

    case BS_EDIT_MIDDLE_ITEM:
      return {
        ...state,
        ...{
          items_middle: action.items_middle,
        },
      };

    case BS_EDIT_LARGE_ITEM:
      return {
        ...state,
        ...{
          items_large: action.items_large,
        },
      };

    case BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS:
      return { ...state, ...{ items_middle: action.items_middle } };

    case BS_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS:
      return { ...state, ...{ items_other: action.items_other } };

    case BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS:
      return { ...state, ...{ items_category: action.items_category } };

    case BS_AUTO_INPUT_BY_OCR_RESULT:
      return {
        ...state,
        ...{
          balanceSheet: action.body,
        },
      };

    default:
      return state;
  }
}

export default balanceSheetReducer;
