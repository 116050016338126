/**
 * メール通知のON/OFF
 */
export function changeUserMailer(element, send) {
  const checked = element.prop("checked");
  const url = checked
    ? `/user_mailers/add_send_${send}`
    : `/user_mailers/remove_send_${send}`;
  $.ajax({ type: "GET", url, dataType: "json" }).done(() => {
    const elm = $(".event--mail-send").find("label");
    elm.addClass(checked ? "orange" : "black");
    elm.removeClass(checked ? "black" : "orange");
    elm.find("span").text(checked ? "メール配信を停止" : "メール配信を開始");
  });
}

$(document).on("change", ".trigger--mail-send-deal-search", function () {
  changeUserMailer($(this), "deal_search");
});
$(document).on("change", ".trigger--mail-send-reminder", function () {
  changeUserMailer($(this), "reminder");
});
