import { removeButton, triggerButton } from "./triggers";

$(() => {
  // 案件の登録 # application.js:trigger_button
  // ============================================================================================
  // 案件に追加する, py_company_idを使用
  $(document).on(
    "click",
    "#lists-index .trigger--add_project_buy",
    function () {
      triggerButton($(this), "add", "projects", "project_buy", "マッチング先", {
        route: "report",
        type: "company",
        is_copy: true,
      });
    }
  );
  $(document).on(
    "click",
    "#lists-index .trigger--add_project_sell",
    function () {
      triggerButton(
        $(this),
        "add",
        "projects",
        "project_sell",
        "マッチング先",
        { route: "report", type: "company", is_copy: true }
      );
    }
  );
  // 案件から外す
  $(document).on(
    "click",
    "#lists-index .trigger--remove_project_buy",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project_buy",
        "マッチング先へ登録",
        { route: "report", type: "company" }
      );
    }
  );
  $(document).on(
    "click",
    "#lists-index .trigger--remove_project_sell",
    function () {
      triggerButton(
        $(this),
        "remove",
        "projects",
        "project_sell",
        "マッチング先へ登録",
        { route: "report", type: "company" }
      );
    }
  );

  // ストップワードに登録
  $(document).on(
    "click",
    "#lists-index .trigger--remove_stopword",
    function () {
      removeButton(
        $(this),
        "remove",
        "stopword",
        "stopword",
        "/stopwords/add_stopword"
      );
    }
  );
  $(document).on("click", "#lists-index .trigger--add_stopword", function () {
    removeButton(
      $(this),
      "add",
      "stopword",
      "stopword",
      "/stopwords/remove_stopword"
    );
  });

  //
  // ============================================================================================
  const $parseOpen = $("#parse-open");
  $(document).on("click", "#lists-index #filter input[type='submit']", () =>
    $parseOpen.css("display", "block")
  );

  $(document).on("click", "#lists-index .word", () =>
    $parseOpen.css("display", "block")
  );

  $(document).on("click", "#lists-index .trigger--filter_word", function () {
    const element = $(this);
    const word = element.data("word");
    $(".default-text").remove();
    $(".event--filter_word").append(
      `
                                        <span class='c_word'>
                                        <label class='badge badge-secondary fs-12 p-2'>${word} <span class='trigger--cancel_word'>×</span></label>
                                        <input type='hidden' name='c_words[]' value='${word}'>
                                        </span>`
    );
  });

  $(document).on(
    "click",
    "#lists-index #filter .trigger--cancel_word",
    function () {
      const element = $(this);
      element.parents("span.c_word").remove();
    }
  );

  // 優先ワードに登録
  $(document).on(
    "click",
    "#lists-index .trigger--add_priority_word",
    function () {
      addButton(
        $(this),
        "add",
        "priority_word",
        "priority_word",
        "/priority_words/add_priority_word"
      );
    }
  );
  $(document).on(
    "click",
    "#lists-index .trigger--remove_priority_word",
    function () {
      removeButton(
        $(this),
        "remove",
        "priority_word",
        "priority_word",
        "/priority_words/remove_priority_word"
      );
    }
  );
});
