import { triggerButton } from "./triggers";

$(() => {
  // 顧客の連絡先に登録  # application.js:trigger_button
  // ============================================================================================
  // 顧客の連絡先に登録
  $(document).on(
    "click",
    "#contacts-select .trigger--add_contact.report",
    function () {
      const name = $(this).data("name");
      triggerButton($(this), "add", "contacts", "contact", name, {
        route: "report",
      });
    }
  );
  // 顧客の連絡先から外す
  $(document).on(
    "click",
    "#contacts-select .trigger--remove_contact.report",
    function () {
      const name = $(this).data("name");
      triggerButton($(this), "remove", "contacts", "contact", `${name}へ登録`, {
        route: "report",
      });
    }
  );

  // 案件提供者の連絡先に登録  # application.js:trigger_button
  // ============================================================================================
  // 案件提供者の連絡先に登録
  $(document).on(
    "click",
    "#contacts-select .trigger--add_contact.adviser",
    function () {
      const name = $(this).data("name");
      triggerButton($(this), "add", "contacts", "contact", name, {
        route: "adviser",
      });
    }
  );
  // 案件提供者の連絡先から外す
  $(document).on(
    "click",
    "#contacts-select .trigger--remove_contact.adviser",
    function () {
      const name = $(this).data("name");
      triggerButton($(this), "remove", "contacts", "contact", `${name}へ登録`, {
        route: "adviser",
      });
    }
  );
});
