import Axios from "axios-observable";
import {ApiEndpoint} from "../utils/ApiEndpoint";
import {titleForUrl} from "../utils/titleForUrl";

export const FinancialStatementImageApi = {
  financialStatementImagesExecOcr:({ fs_image_id, report_id }) => Axios.get(
    `/reports/${report_id}/financial_statement_images/${fs_image_id}/exec_ocr`
  ),
  financialStatementImagesGetStatus: ({ fs_image_id, report_id }) => Axios.get(
    `/reports/${report_id}/financial_statement_images/${fs_image_id}/get_status`
  ),
  financialStatementImagesGetResult: ({ fs_image_id, report_id }) => Axios.get(
    `/reports/${report_id}/financial_statement_images/${fs_image_id}/get_result`
  ),
  financialStatementImagesReanalyze: ({fs_image_id, report_id})　=> Axios.get(
    `/reports/${report_id}/financial_statement_images/${fs_image_id}/reanalyze`
  ),
};

