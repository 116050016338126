import React from "react";
import { hot } from "react-hot-loader";

const BalanceSheetSummary = (props) => {

  const { book_v, diff_v, fixed_v } = props;
  const bookV = book_v || 0;
  const diffV = diff_v || 0;
  const fixedV = fixed_v || 0;

  return (
    <div className="contents-block">
      <div className="report-title">{props.name}の部合計</div>
      <table className="w-100">
        <tbody className="text-center font-weight-bold fs-13">
        <tr>
          <td colSpan={4}>簿価</td>
          <td colSpan={4}>調整額</td>
          <td colSpan={4}>評価額</td>
        </tr>
        <tr>
          <td colSpan={4}>{bookV}</td>
          <td colSpan={4}>{diffV}</td>
          <td colSpan={4}>{fixedV}</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

BalanceSheetSummary.defaultProps = {
  name: "",
  book_v: 0,
  diff_v: 0,
  fixed_v: 0,
};

export default hot(module)(BalanceSheetSummary);
