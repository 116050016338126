import React from "react"
import { hot } from 'react-hot-loader'

const TableHeader = props => {
  return (
    <thead className="text-center font-weight-bold fs-13">
      <tr>
        <td colSpan={6} className="w-25 ">科目</td>
        <td colSpan={4}>簿価
          <span className="fs-10 font-weight-light">(※編集不可)</span>
        </td>
        <td colSpan={4}>調整額</td>
        <td colSpan={4}>評価額</td>
        <td colSpan={6} className="w-25 ">コメント</td>
      </tr>
    </thead>
  )
}

// TableHeader.defaultProps = {
//   name: "cant't find name of TableHeader"
// }

export default hot(module)(TableHeader);