import { map, filter } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import {
  PL_INIT_PROPS,
  PL_ADD_SMALL_ITEM,
  PL_ADD_MIDDLE_ITEM,
  PL_ADD_LARGE_ITEM,
  PL_REMOVE_SMALL_ITEM,
  PL_REMOVE_MIDDLE_ITEM,
  PL_REMOVE_LARGE_ITEM,
  PL_EDIT_SMALL_ITEM,
  PL_EDIT_MIDDLE_ITEM,
  PL_EDIT_LARGE_ITEM,
  PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  PL_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS,
  PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS,

  // PL_AUTO_INPUT_BY_OCR_RESULT,
  ProfitAndLossAction,
} from "../../actions/financialActions/financialStatements/ProfitAndLossAction";

const profitAndLossAddSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_ADD_SMALL_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossAddMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_ADD_MIDDLE_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossRemoveSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_REMOVE_SMALL_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossRemoveMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_REMOVE_MIDDLE_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

// const profitAndLossRemoveLargeItemEpic = (action$, state$) => action$.pipe(
//   filter( action => action.type === PL_REMOVE_LARGE_ITEM),
//   map(ProfitAndLossAction.plCalcSumsItemsByLargeItems(state$.value.financialStatements.profitAndLoss.book)),
// )

const profitAndLossEditSmallItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_EDIT_SMALL_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcMiddleItemsBySmallItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossEditMiddleItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_EDIT_MIDDLE_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossEditLargeItemEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_EDIT_LARGE_ITEM),
    map((action) =>
      ProfitAndLossAction.plCalcSumsItemsByLargeItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossCalcMiddleItemsBySmallItemsEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS),
    map((action) =>
      ProfitAndLossAction.plCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

const profitAndLossCalcSumsItemsByLargeItemsEpic = (action$, state$) =>
  action$.pipe(
    filter((action) => action.type === PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS),
    map((action) =>
      ProfitAndLossAction.plCalcOtherItemsByMiddleItemsAndLargeItems(
        state$.value.financialStatements.profitAndLoss.book
      )
    )
  );

export const profitAndLossEpic = combineEpics(
  profitAndLossAddSmallItemEpic,
  profitAndLossAddMiddleItemEpic,
  profitAndLossRemoveSmallItemEpic,
  profitAndLossRemoveMiddleItemEpic,
  // profitAndLossRemoveLargeItemEpic,
  profitAndLossEditSmallItemEpic,
  profitAndLossEditMiddleItemEpic,
  profitAndLossEditLargeItemEpic,
  profitAndLossCalcMiddleItemsBySmallItemsEpic,
  profitAndLossCalcSumsItemsByLargeItemsEpic
);
