import React from 'react';
import { hot } from 'react-hot-loader'
import {
  InputName,
  InputBookV,
  InputDiffV,
  InputFixedV,
  InputComment,
}
  from '../input'

const OtherMiddleItemTr = props => {
  const { book_v, diff_v, fixed_v } = props
  if (book_v || diff_v || fixed_v) {
    return (
      <OtherMiddleItemTrBase
        inputName={
          <InputName
            value="その他"
            edittable={false}
            kindOfItem="middle" />}
        inputBookV={
          <InputBookV
            value={props.book_v}
            valueStatus="solid"
            edittable={false}
            kindOfItem="middle" />}
        inputDiffV={
          <InputDiffV
            value={props.diff_v}
            edittable={true}
            onChange={(e) => props.editOtherItem(e)}
            kindOfItem="middle" />}
        inputFixedV={
          <InputFixedV
            value={props.fixed_v}
            edittable={true}
            onChange={(e) => props.editOtherItem(e)}
            kindOfItem="middle" />}
        inputComment={
          <InputComment
            value={props.comment}
            edittable={true}
            onChange={(e) => props.editOtherItem(e)}
            kindOfItem="middle" />}
      />
    );
  }
  return false;
}



OtherMiddleItemTr.defaultProps = {
  book_v: null,
}

export default hot(module)(OtherMiddleItemTr);


const OtherMiddleItemTrBase = props => {
  return (
    <tr>
      <td colSpan={5} className="indent-2">{props.inputName}</td>
      <td colSpan={1} className="icon">{props.iconButton}</td>
      <td colSpan={4}>{props.inputBookV}</td>
      <td colSpan={1} className="text-center">+</td>
      <td colSpan={3}>{props.inputDiffV}</td>
      <td colSpan={1} className="text-center">=</td>
      <td colSpan={3}>{props.inputFixedV}</td>
      <td colSpan={4}>{props.inputComment}</td>
    </tr>
  );
}
