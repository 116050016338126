import React from "react";
import { hot } from 'react-hot-loader'
const PdfDisplaySheetList = props => {
  const fs_images = props.referenceAreaInput.map(item => { return item.fs_image })
  return (
    <React.Fragment>
      <div className="scroll-area">
      {fs_images.map((fs_image, index) =>
        <React.Fragment key={index}>
          <div className="contents-block">
          <div className="report-title">{fs_image.file_name}</div>
            <OnePdfSheet {...fs_image} key={index} />
          </div>
        </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

const OnePdfSheet = props => {

  const report_id = props.report_id
  const fs_image_id = props.fs_image_id
  const content_type = props.content_type
  const file_name = props.file_name
  const data_path = `/reports/${report_id}/financial_statement_images/${fs_image_id}`

  return (
    <React.Fragment>
      <div className="text-center mt-3 px-3">
        <div className="image-wrapper">
          <object
            data={`${data_path}#toolbar=0`}
            className="image-content pdfview"
            type={content_type}
            style={{
              display: "block",
              boxShadow: "2px 2px 2px rgba(0,0,0,0.4)"
            }}
            height={"100%"}
            width={"100%"}
          ></object>
        </div>
      </div>
    </React.Fragment>
  )
}

export default hot(module)(PdfDisplaySheetList);