import React from "react";
import ProfitAndLoss from "../v3/pl/ProfitAndLoss";
import BalanceSheet from "../v3/bs/BalanceSheet";
import { ReferenceArea } from "../referenceArea/ReferenceArea";
import ProfitAndLossFix from "../v3/pl/ProfitAndLossFix";
import BalanceSheetFix from "../v3/bs/BalanceSheetFix";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import { FinancialStatementScreenFixesAction } from "../../../../actions/financialActions/financialStatementScreens/FinancialStatementScreenFixesAction";
import { financialStatementScreenFixesSelector } from "../../../../selectors/financial/financialStatementScreens/financialStatementScreenFixesSelector";

/**
 * 決算書入力画面
 */
class FinancialStatementFixesContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      setCategory,
      setPeriod,
      setUnit,
      setValuableItemStructure,
      categoryBeforeTypeCast,
      valuableItemStructure,
      period,
      unit,
    } = props;
    this.renderTable = this.renderTable.bind(this);
    this.renderFixTable = this.renderFixTable.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.changeUnit = this.changeUnit.bind(this);
    setCategory(categoryBeforeTypeCast);
    setPeriod(period);
    setUnit(unit);
    setValuableItemStructure(valuableItemStructure);
  }

  renderTable() {
    const { categoryBeforeTypeCast } = this.props;
    switch (categoryBeforeTypeCast) {
      case 2:
        return <ProfitAndLoss {...this.props} />;
      case 1:
        return <BalanceSheet {...this.props} />;
      default:
        return null;
    }
  }

  renderFixTable() {
    const { categoryBeforeTypeCast } = this.props;
    switch (categoryBeforeTypeCast) {
      case 2:
        return <ProfitAndLossFix defaultProps={this.props} />;
      case 1:
        return <BalanceSheetFix defaultProps={this.props} />;
      default:
        return null;
    }
  }

  changePeriod(event) {
    const { setPeriod } = this.props;
    setPeriod(event.target.value);
  }

  changeUnit(event) {
    const { setPeriod } = this.props;
    setPeriod(event.target.value);
  }

  render() {
    const { props } = this;
    const { step, unit, categoryBeforeTypeCast, period, cancelPath } = props;

    return (
      <>
        {step === "book" ? (
          <div className="row">
            <div className="col-8">
              <div className="text-right">
                <label className="fs-18 mt-2">単位：</label>
                <select
                  name="financial_statement_fix[unit]"
                  className="form-control mb-3 float-right"
                  style={{ width: "200px" }}
                  data-step="2"
                  data-intro="決算書に合わせて単位を変更してください"
                  defaultValue={unit}
                  id="financial_statement_fix_unit"
                  onChange={this.changeUnit}
                >
                  <option value="円">円</option>
                  <option value="千円">千円</option>
                  <option value="百万円">百万円</option>
                </select>
                <div className="clearfix" />
              </div>
              <div
                data-step="3"
                data-intro="こちらから、直接数字の記入や編集ができます。またOCR後デフォルトで用意されてる科目は自動反映ボタンで反映が可能です。それ以外の科目は「＋」ボタンで親科目に対する子科目を追加し入力できます"
              >
                {this.renderTable()}
              </div>
            </div>
            <div className="col-4 no-padding-left">
              <ReferenceArea {...props} />
            </div>
          </div>
        ) : null}

        {step === "fix" ? this.renderFixTable() : null}

        <input
          type="hidden"
          name="financial_statement_fix[category]"
          value={categoryBeforeTypeCast}
          id="financial_statement_fix_category"
        />
        <input
          type="hidden"
          name="financial_statement_fix[period]"
          value={period}
          id="financial_statement_fix_period"
        />

        <div className="btn-fixed-area">
          <input
            type="submit"
            value="決算情報を保存する"
            className="btn btn-primary mr-2"
            style={{ width: "200px" }}
          />
          <a
            href={cancelPath}
            className="btn btn-light"
            data-confirm="キャンセルすると入力した内容が破棄されます。本当にキャンセルしてよろしいですか？"
          >
            キャンセル
          </a>
        </div>
      </>
    );
  }
}
const mapStateToProps = () => ({
  financialStatementScreenFixes: financialStatementScreenFixesSelector,
});
const mapDispatchToProps = (dispatch) => ({
  setCategory(category) {
    dispatch(FinancialStatementScreenFixesAction.setCategory({ category }));
  },
  setPeriod(period) {
    dispatch(FinancialStatementScreenFixesAction.setPeriod({ period }));
  },
  setUnit(unit) {
    dispatch(FinancialStatementScreenFixesAction.setUnit({ unit }));
  },
  setValuableItemStructure(structure) {
    dispatch(
      FinancialStatementScreenFixesAction.setValuableItemStructure({
        structure,
      })
    );
  },
});

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(FinancialStatementFixesContainer)
);
