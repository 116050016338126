import React from "react";
import { hot } from "react-hot-loader";

import {
  InputName,
  InputBookV,
  AddButton,
  DeleteButton,
} from "../input";

import TooltipButton from "../TooltipButton";

const MiddleItemTr = (props) => {
  const has_items_small = props.items_small.length > 0;
  const addedInDefault = props.addedIn === "default" || props.addedIn === "";
  const addedInBook = props.addedIn === "book";

  return (
    <React.Fragment>
      {!has_items_small && addedInDefault && (
        <MiddleItemTrWithoutItemsS
          {...props}
          addedInDefault={true}
          has_items_small={false}
        />
      )}
      {has_items_small && addedInDefault && (
        <MiddleItemTrWithItemsS
          {...props}
          addedInDefault={true}
          has_items_small={true}
        />
      )}
      {addedInBook && (
        <MiddleItemTrAddedInBook {...props} addedInDefault={false} />
      )}
    </React.Fragment>
  );
};

const MiddleItemTrWithoutItemsS = (props) => {
  return (
    <MiddleItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="middle"
        />
      }
      iconButton={<AddButton onClick={(e) => props.addSmallItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle"
        />
      }
      {...props}
    />
  );
};

const MiddleItemTrWithItemsS = (props) => {
  return (
    <MiddleItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={false}
          kindOfItem="middle"
        />
      }
      iconButton={
        <AddButton hasInfo={true} onClick={(e) => props.addSmallItem(e)} />
      }
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="middle"
        />
      }
      {...props}
    />
  );
};

const MiddleItemTrAddedInBook = (props) => {
  return (
    <MiddleItemTrBase
      inputName={
        <InputName
          {...props}
          value={props.name}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle"
        />
      }
      iconButton={<DeleteButton onClick={(e) => props.removeMiddleItem(e)} />}
      inputBookV={
        <InputBookV
          {...props}
          value={props.book_v}
          edittable={true}
          onChange={(e) => props.editMiddleItem(e)}
          kindOfItem="middle"
        />
      }
      {...props}
    />
  );
};

const MiddleItemTrBase = (props) => {
  const addItemRef = props.refs != undefined ? props.refs.addItemRef : null;
  const selfValueRef = props.refs != undefined ? props.refs.selfValueRef : null;
  const selfNameRef = props.refs != undefined ? props.refs.selfNameRef : null;
  const addedInDefault = props.addedInDefault;
  const has_items_small = props.has_items_small;
  const { tooltip_text } = props;
  const has_tooltip = tooltip_text.length > 0;

  if (addedInDefault && !has_items_small) {
    return (
      <tr>
        <td colSpan={has_tooltip ? 10 : 11} className="indent-2">
          {props.inputName}
        </td>
        {has_tooltip && <TooltipButton tooltip_text={tooltip_text} />}
        <td
          colSpan={1}
          className="icon"
          ref={addItemRef}
          onDragLeave={(e) => props.onDragLeave(e, addItemRef)}
          onDragOver={(e) => props.onDragOver(e, addItemRef)}
          onDrop={(e) => props.onDropAddItemRef(e)}
        >
          {props.iconButton}
        </td>
        <td
          colSpan={12}
          ref={selfValueRef}
          onDragLeave={(e) => props.onDragLeave(e, selfValueRef)}
          onDragOver={(e) => props.onDragOver(e, selfValueRef)}
          onDrop={(e) => props.onDropSelfValueRef(e)}
        >
          {props.inputBookV}
        </td>
      </tr>
    );
  } else if (addedInDefault && has_items_small) {
    return (
      <tr>
        <td colSpan={has_tooltip ? 10 : 11} className="indent-2">
          {props.inputName}
        </td>
        {has_tooltip && <TooltipButton tooltip_text={tooltip_text} />}
        <td
          colSpan={1}
          className="icon"
          ref={addItemRef}
          onDragLeave={(e) => props.onDragLeave(e, addItemRef)}
          onDragOver={(e) => props.onDragOver(e, addItemRef)}
          onDrop={(e) => props.onDropAddItemRef(e)}
        >
          {props.iconButton}
        </td>
        <td colSpan={12}>{props.inputBookV}</td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td
          colSpan={11}
          className="indent-2"
          onDragLeave={(e) => props.onDragLeave(e, selfNameRef)}
          onDragOver={(e) => props.onDragOver(e, selfNameRef)}
          onDrop={(e) => props.onDropSelfNameRef(e)}
          ref={selfNameRef}
        >
          {props.inputName}
        </td>
        <td colSpan={1} className="icon">
          {props.iconButton}
        </td>
        <td
          colSpan={12}
          ref={selfValueRef}
          onDragLeave={(e) => props.onDragLeave(e, selfValueRef)}
          onDragOver={(e) => props.onDragOver(e, selfValueRef)}
          onDrop={(e) => props.onDropSelfValueRef(e)}
        >
          {props.inputBookV}
        </td>
      </tr>
    );
  }
};

MiddleItemTr.defaultProps = {
  name: "",
  book_v: "",
  diff_v: "",
  fixed_v: "",
  comment: "",
  code: "",
  addedIn: "default",
  tooltip_text: "",
};

// default or bookでaddIconかdeleteIconか判断する
export default hot(module)(MiddleItemTr);
