import { ArrayTools } from "./arrayTools";

// 項目内の指定idの最大+1を返す・要素を追加する際に利用
function getLastIdPlusOne(items, idName = "sid") {
  let r = 0;
  if (ArrayTools.checkArray(items)) {
    r = Math.max(...items.map((item) => item[idName])) + 1;
  }
  return r;
}

// 項目内で指定idを満たす要素配列を取得(has_many)
function getSmallItemsByMid(itemsS, mid) {
  let rItems_s = [];
  if (ArrayTools.checkArray(itemsS)) {
    itemsS.forEach((v) => {
      if (v["mid"] === mid) {
        rItems_s.push(v);
      }
    });
  }
  return rItems_s;
}
// 項目内で指定idを満たす要素配列を取得(has_many)
function getMiddleItemsByLid(itemsM, lid) {
  let rItems_m = [];
  if (ArrayTools.checkArray(itemsM)) {
    itemsM.forEach((v) => {
      if (v["lid"] === lid) {
        rItems_m.push(v);
      }
    });
  }
  return rItems_m;
}

function getOtherItemByLid(itemsOther, lid) {
  let rItems_other = null;
  if (ArrayTools.checkArray(itemsOther)) {
    itemsOther.forEach((v) => {
      if (v["lid"] === lid) {
        rItems_other = v;
      }
    });
  }
  return rItems_other;
}

function getLargeItemsByCid(itemsL, cid) {
  let rItems_l = [];
  if (ArrayTools.checkArray(itemsL)) {
    itemsL.forEach((v) => {
      if (v["cid"] === cid) {
        rItems_l.push(v);
      }
    });
  }
  return rItems_l;
}

/**
 * 項目内で指定idを満たす要素をの配列を取得(has_many)
 */
function getItemsById(items, idName = "small_id", id) {
  let rItems = [];
  if (ArrayTools.checkArray(items)) {
    items.forEach((v) => {
      if (v[idName] === id) {
        rItems.push(v);
      }
    });
  }
  return rItems;
}

/**
 * 項目内で指定idを満たす要素を一つだけ取得(has_one)
 */
function getItemById(items, idName = "sid", id) {
  let r = {};
  if (ArrayTools.checkArray(items)) {
    items.forEach((v, _) => {
      if (v[idName] === id) {
        r = v;
      }
    });
  }
  return r;
}
export const Association = {
  getLastIdPlusOne,
  getSmallItemsByMid,
  getMiddleItemsByLid,
  getLargeItemsByCid,
  getOtherItemByLid,
  getItemsById,
  getItemById,
};
