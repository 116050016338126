// 選択ボックスの自動更新
// noinspection JSUnusedLocalSymbols
export function changeSelect(element, url) {
  let report_id;
  const id = element.data("id");

  if (element.data("report-id") === undefined) {
    report_id = $("#report_id").data("id");
  } else {
    report_id = element.data("report-id");
  }

  $.ajax({
    type: "GET",
    url: `/reports/${report_id}${url}`,
    data: {
      id,
      report_id,
      selected: element.find(":selected").val(),
    },
    dataType: "json",
    error: () => console.log("error"),
  });
}

global.changeSelect = changeSelect;
