export function lehmannSimulator(id, url) {
  const value = $(`input.value[data-id=${id}]`).val();
  const unit = $(`select.unit[data-id=${id}]`).find("option:selected").val();

  if (value === "") {
    return;
  }

  const auto_calc = $(`input.auto-calc[data-id=${id}]:checked`).val();
  let data;

  if (auto_calc === undefined) {
    data = { price: value, unit: unit };
  } else {
    const rValue = $(`input.remuneration-value[data-id=${id}]`).val();
    const rUnit = $(`select.remuneration-unit[data-id=${id}]`)
      .find("option:selected")
      .val();
    data = {
      price: value,
      unit,
      auto_calc,
      remuneration: rValue,
      r_unit: rUnit,
    };
  }

  const element = $(`.simulator-result[data-id=${id}]`);

  $.ajax({
    type: "GET",
    url,
    data,
    dataType: "json",
  }).done((data) => {
    element.find(".result-value").text(data.output.value);
    element.find(".result-unit").text(data.output.unit);
    element.find(".result").text(data.output.origin);

    let total = $("#lehmanns-total");
    if (total === "undefined") {
      return;
    }

    let values = 0;
    $(".lehmann-simulator .simulator-result .result").each((i, element) => {
      const value = $(element).text();
      if (value !== "") {
        values += parseInt(value, 10);
      }
    });

    $.ajax({
      type: "GET",
      url: "/lehmanns/unit_split",
      data: { price: values },
      dataType: "json",
    }).done((data) => {
      $("#lehmanns-total .total-value").text(data.value);
      $("#lehmanns-total .total-unit").text(data.unit);
    });
  });
}

$(() => {
  $(document).on(
    "input change",
    ".lehmann-simulator .trigger--simulation-default",
    function () {
      lehmannSimulator($(this).data("id"), "/lehmanns/calc");
    }
  );

  $(document).on(
    "input change",
    ".lehmann-simulator .trigger--simulation-report",
    function () {
      const id = $(this).data("id");
      lehmannSimulator(id, `/reports/${id}/edit_estimated_price`);
    }
  );

  $(document).on(
    "change",
    ".lehmann-simulator .trigger--auto_calc",
    function () {
      const id = $(this).data("id");
      const autoCalc = $(`input.auto-calc[data-id=${id}]:checked`).val();

      const auto = $(".event--auto_calc-auto");
      const free = $(".event--auto_calc-free");

      switch (autoCalc) {
        case "auto":
          free.addClass("d-none");
          auto.removeClass("d-none");
          break;
        default:
          auto.addClass("d-none");
          free.removeClass("d-none");
      }
    }
  );
});
