import { estm } from "./estimate";
import { itpl } from "./interpolate";
import * as math from "mathjs";

$(() => {
  $("#base_calc").on("click", function () {
    const grossRate = $("#base_gross_rate").val();
    const baseType = $("#base_type").val();
    const fcf_id = $(this).data("fcf_id");

    let lastTermVals = estm.getLastTermValues();
    let planTds = estm.getPlanTds(fcf_id);

    estm.baseCalcMotion("GP_NS", lastTermVals, planTds, grossRate, baseType);
    estm.baseCalcMotion("GP_CS", lastTermVals, planTds, grossRate, baseType);
    estm.baseCalcMotion("OP_SA", lastTermVals, planTds, grossRate, baseType);
    estm.baseCalcMotion("OP_SA_D", lastTermVals, planTds, grossRate, baseType);
    estm.baseCalcMotion("OT_CAPEX", lastTermVals, planTds, grossRate, baseType);
  });

  $("#achievement_calc").on("click", () => {
    // 売上高　=> 前年度比の平均を年成長率とする
    // 売上原価 => 対売上比率の過去実績平均 x 売上予想
    // 販管費 => 対売上比率の過去実績平均 x 売上予想
    // const fcf_id = $(this).data("fcf_id");
    // let lastTermVals = estm.getLastTermValues();
    // let planTds = estm.getPlanTds(fcf_id);
    let achieveVals1 = estm.getAchieveVals();

    const achieveVals2 = estm.fitGP_NSLength(achieveVals1);
    const achieveVals3 = itpl.interpolateAssociativeArray(achieveVals2);
    const achieveVals = estm.convertNaNtoZero(achieveVals3);

    let GP_NS_rate = estm.calcGrowthAvg(achieveVals["GP_NS"]);
    let GP_CS_ratio = estm.calcVersusRatioAvg(
      achieveVals["GP_CS"],
      achieveVals["GP_NS"]
    );
    let OP_SA_ratio = estm.calcVersusRatioAvg(
      achieveVals["OP_SA"],
      achieveVals["GP_NS"]
    );

    let GP_NS_rates = estm.calcGrowthList(achieveVals["GP_NS"]);
    let GP_CS_rates = estm.calcVersusRatioList(
      achieveVals["GP_CS"],
      achieveVals["GP_NS"]
    );
    let OP_SA_rates = estm.calcVersusRatioList(
      achieveVals["OP_SA"],
      achieveVals["GP_NS"]
    );
    let tableHead = $("#estm_confirm_table_head");
    let GP_NSRow = $("#estm_confirm_GP_NS");
    let GP_CSRow = $("#estm_confirm_GP_CS");
    let OP_SARow = $("#estm_confirm_OP_SA");

    tableHead.children("th").remove();
    GP_NSRow.children("td").remove();
    GP_CSRow.children("td").remove();
    OP_SARow.children("td").remove();
    for (let i = 0; i < achieveVals["GP_NS"].length; i++) {
      let period = achieveVals["GP_NS"].length - i;
      let periodName = period === 1 ? "直前期" : `${String(period)}期前`;
      tableHead.append(
        `<th width="130" class="text-center bg-lightgray">${String(
          periodName
        )}</th>`
      );
      if (GP_NS_rates[i - 1] === undefined) {
        GP_NSRow.append(
          `<td widtg="130" class="text-center">${String(
            achieveVals["GP_NS"][i]
          )}<br>（ - %）</td>`
        );
      } else {
        estm.setNumWithITPL("GP_NS", GP_NSRow, achieveVals, achieveVals2, i);
        GP_NSRow.children("td:last").append(
          `<label>（${String(
            math.round(GP_NS_rates[i - 1] * 100, 2)
          )}%）</label><br>`
        );
      }
      estm.setNumWithITPL("GP_CS", GP_CSRow, achieveVals, achieveVals2, i);
      GP_CSRow.children("td:last").append(
        `<label>（${String(math.round(GP_CS_rates[i] * 100, 2))}%）</label>`
      );
      estm.setNumWithITPL("OP_SA", OP_SARow, achieveVals, achieveVals2, i);
      OP_SARow.children("td:last").append(
        `<label>（${String(math.round(OP_SA_rates[i] * 100, 2))}%）</label>`
      );
    }

    tableHead.append(
      '<th width="130" class="text-center bg-lightgray">平均</th>'
    );
    GP_NSRow.append(
      `<td widtg="130" class="text-center text-primary">${String(
        math.round(GP_NS_rate * 100, 2)
      )}%</td>`
    );
    GP_CSRow.append(
      `<td widtg="130" class="text-center text-primary">${String(
        math.round(GP_CS_ratio * 100, 2)
      )}%</td>`
    );
    OP_SARow.append(
      `<td widtg="130" class="text-center text-primary">${String(
        math.round(OP_SA_ratio * 100, 2)
      )}%</td>`
    );

    $("#waypoint").click();
  });

  $("#apply_estm").on("click", function () {
    const fcf_id = $(this).data("fcf_id");
    let lastTermVals = estm.getLastTermValues();
    let planTds = estm.getPlanTds(fcf_id);
    let achieveVals1 = estm.getAchieveVals();

    const achieveVals2 = estm.fitGP_NSLength(achieveVals1);
    const achieveVals3 = itpl.interpolateAssociativeArray(achieveVals2);
    const achieveVals = estm.convertNaNtoZero(achieveVals3);

    let GP_NS_rate = estm.calcGrowthAvg(achieveVals["GP_NS"]);
    let GP_CS_ratio = estm.calcVersusRatioAvg(
      achieveVals["GP_CS"],
      achieveVals["GP_NS"]
    );
    let OP_SA_ratio = estm.calcVersusRatioAvg(
      achieveVals["OP_SA"],
      achieveVals["GP_NS"]
    );

    let GP_NS_plan = estm.calcGrowthPlan(lastTermVals["GP_NS"], GP_NS_rate);
    let GP_CS_plan = estm.calcVersusRatioPlan(GP_NS_plan, GP_CS_ratio);
    let OP_SA_plan = estm.calcVersusRatioPlan(GP_NS_plan, OP_SA_ratio);

    let estmPlans = { GP_NS: GP_NS_plan, GP_CS: GP_CS_plan, OP_SA: OP_SA_plan };

    estm.estmCalcMotion(
      "GP_NS",
      lastTermVals,
      planTds,
      math.round(GP_NS_rate * 100, 2),
      estmPlans,
      { growthLabel: "成長率平均" }
    );
    estm.estmCalcMotion(
      "GP_CS",
      lastTermVals,
      planTds,
      math.round(GP_CS_ratio * 100, 2),
      estmPlans,
      { growthLabel: "売上比平均" }
    );
    estm.estmCalcMotion(
      "OP_SA",
      lastTermVals,
      planTds,
      math.round(OP_SA_ratio * 100, 2),
      estmPlans,
      { growthLabel: "売上比平均" }
    );

    let fcfComment = [];
    let comment1 = `1. 売上高は前年度比平均から年平均成長率を算出し、成り行き予想をしております。（売上年平均成長率：${String(
      math.round(GP_NS_rate * 100, 2)
    )}%）また前年度比は0~10%の範囲に限定しております。`;
    let comment2 = `2. 売上原価、販管費は対売上比率平均を算出し、成り行き予想をしております。（売上原価対売上高比率：${String(
      math.round(GP_CS_ratio * 100, 2)
    )}%、販管費対売上高比率：${String(math.round(OP_SA_ratio * 100, 2))}%）`;
    fcfComment.push(comment1);
    fcfComment.push(comment2);
    $("#fcf_comment").val(fcfComment.join("\n")).effect("highlight");
  });

  $("#add_achievement").on("click", function () {
    let lastPeriod = $(this).data("last_period");
    let lastYear = $(this).data("last_year");
    let month = $(this).data("month");
    const period = parseInt(lastPeriod) - 1;
    const Year = parseInt(lastYear) - 1;

    // table headの２個目にインサート
    let th_ele = `<th width="130" class="text-center bg-lightgray" data-period=${String(
      period
    )}><div>${String(
      -period + 1
    )}期前</div><label class="badge badge-secondary mr-1">任意</label>${String(
      Year
    )}年${String(month)}月期</th>`;
    let GP_NS_ele;
    GP_NS_ele = `<td><input name="subject[GP_NS][${String(
      period
    )}]" type="text" value="" class="form-control text-right"/></td>`;
    let GP_CS_ele = `<td><input name="subject[GP_CS][${String(
      period
    )}]" type="text" value="" class="form-control text-right"/></td>`;
    let OP_SA_ele = `<td><input name="subject[OP_SA][${String(
      period
    )}]" type="text" value="" class="form-control text-right"/></td>`;
    let OP_SA_D_ele = `<td><input name="subject[OP_SA_D][${String(
      period
    )}]" type="text" value="" class="form-control text-right"/></td>`;
    let OT_CAPEX_ele = `<td><input name="subject[OT_CAPEX][${String(
      period
    )}]" type="text" value="" class="form-control text-right"/></td>`;
    $("#achievement_header th:first").before(th_ele);
    $("#achievement_GP_NS td:first").before(GP_NS_ele);
    $("#achievement_GP_CS td:first").before(GP_CS_ele);
    $("#achievement_OP_SA td:first").before(OP_SA_ele);
    $("#achievement_OP_SA_D td:first").before(OP_SA_D_ele);
    $("#achievement_OT_CAPEX td:first").before(OT_CAPEX_ele);

    // 次に追加する際に使うdata属性を設定
    $(this).data("last_period", period);
    $(this).data("last_year", Year);
    if (period < -3) {
      $(this).css("display", "none");
    }
  });

  $("#calc_de_ratio").on("click", () => {
    const NA_SE = $("input[name='subject[NA_SE][0]']").val();
    const OT_IBD = $("input[name='subject[OT_IBD][0]']").val();
    const result = Number(OT_IBD / NA_SE).toPrecision(3);

    if (NA_SE === "" || OT_IBD === "") {
      alert("有利子負債か株主資本が入力されておりません");
    } else {
      $("input[name='subject[OT_TDR][0]']").val(result);
    }
  });

  $(
    "#financials-show_similarity input[name='similar'],#financials-approach input[name='similar']"
  ).on("click", function () {
    const element = $(this);
    const report_id = element.data("report-id");
    const company_id = element.data("company-id");

    $.ajax({
      type: "GET",
      url: `/reports/${report_id}/financial_similarities/${company_id}/toggle`,
      data: { flag: element.prop("checked") },
      dataType: "json",
      success: () => console.log("success"),
      error: () => console.log("error"),
    });
  });

  $("#financials-approach input").on("keydown", function (e) {
    const $input = $("input");
    const element = $input.length;
    if (e.which === 13) {
      e.preventDefault();
      const Index = $input.index(this);
      const nextIndex = $input.index(this) + 1;
      if (nextIndex < element) {
        $input[nextIndex].focus(); // 次の要素へフォーカスを移動
      } else {
        $input[Index].blur(); // 最後の要素ではフォーカスを外す
      }
    }
  });
});
