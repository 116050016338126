import React from 'react';
import { hot } from 'react-hot-loader'
import {
  InputName,
  InputBookV,
  InputDiffV,
  InputFixedV,
  InputComment,
  DeleteButton,
  AddButton,
} from '../input'

const SmallItemTr = props => {
  const addedInFix = props.addedIn == "fix"
  return (
    <React.Fragment>
      <SmallItemTrBase {...props}
        inputName={
          <InputName
            {...props}
            value={props.name}
            kindOfItem="small"
            edittable={addedInFix ? true : false}
            onChange={addedInFix ? (e) => props.editSmallItem(e) : null}
          />
        }
        iconButton={
          addedInFix && <DeleteButton onClick={(e) => props.removeSmallItem(e)} />
        }
      />
    </React.Fragment>
  );
}

//nameのみ編集可否が変動
const SmallItemTrBase = props => {
  return (
    <tr>
      <td colSpan={5} className="indent-3">
        {props.inputName}
      </td>
      <td colSpan={1} className="icon">
        {props.iconButton}
      </td>
      <td colSpan={4}>
        <InputBookV
          {...props}
          value={props.book_v}
          valueStatus="solid"
          edittable={false}
          kindOfItem="small"
        />
      </td>
      <td colSpan={1} className="text-center">+</td>
      <td colSpan={3}>
        <InputDiffV
          {...props}
          value={props.diff_v}
          edittable={true}
          onChange={(e) => props.editSmallItem(e)}
          kindOfItem="small"
        />
      </td>
      <td colSpan={1} className="text-center">=</td>
      <td colSpan={3}>
        <InputFixedV
          {...props}
          value={props.fixed_v}
          edittable={true}
          onChange={(e) => props.editSmallItem(e)}
          kindOfItem="small"
        />
      </td>
      <td colSpan={6}>
        <InputComment
          {...props}
          value={props.comment}
          kindOfItem="small"
          onChange={(e) => props.editSmallItem(e)}
        />
      </td>
    </tr>
  )
}

SmallItemTr.defaultProps = {
  name: "",
  book_v: null,
  diff_v: null,
  fixed_v: null,
  comment: "",
  code: "",
  addedIn: "default",
}

export default hot(module)(SmallItemTr);
