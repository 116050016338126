import { Association } from "../../../modules/financialStatements/associationTools";

export const BS_FIX_INIT_PROPS = "BS_FIX_INIT_PROPS";
export const BS_FIX_ADD_SMALL_ITEM = "BS_FIX_ADD_SMALL_ITEM";
export const BS_FIX_ADD_MIDDLE_ITEM = "BS_FIX_ADD_MIDDLE_ITEM";
export const BS_FIX_ADD_LARGE_ITEM = "BS_FIX_ADD_LARGE_ITEM";
export const BS_FIX_REMOVE_SMALL_ITEM = "BS_FIX_REMOVE_SMALL_ITEM";
export const BS_FIX_REMOVE_MIDDLE_ITEM = "BS_FIX_REMOVE_MIDDLE_ITEM";
export const BS_FIX_REMOVE_LARGE_ITEM = "BS_FIX_REMOVE_LARGE_ITEM";
export const BS_FIX_EDIT_SMALL_ITEM = "BS_FIX_EDIT_SMALL_ITEM";
export const BS_FIX_EDIT_MIDDLE_ITEM = "BS_FIX_EDIT_MIDDLE_ITEM";
export const BS_FIX_EDIT_LARGE_ITEM = "BS_FIX_EDIT_LARGE_ITEM";
export const BS_FIX_EDIT_OTHER_ITEM = "BS_FIX_EDIT_OTHER_ITEM";

export const BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS =
  "BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS";
export const BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS =
  "BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS";
export const BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS =
  "BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS";

import { ReducerHelper } from "../../../modules/financialStatements/reducerHelper";
import { ArrayTools } from "../../../modules/financialStatements/arrayTools";

// actionCreator
function bsFixInitProps(body) {
  return {
    type: BS_FIX_INIT_PROPS,
    body: body,
  };
}

/**
 * Smallを追加
 * @returns {{item_small: *, type: *, body: *, items_small: *}}
 */
function bsFixAddSmallItem(body, itemSmall) {
  let items_small = body.items_small;
  items_small.push(itemSmall);
  body.items_small = items_small;
  return {
    type: BS_FIX_ADD_SMALL_ITEM,
    body,
    item_small: itemSmall,
    items_small,
  };
}

/**
 * Middleを追加
 */
function bsFixAddMiddleItem(body, itemMiddle) {
  return {
    type: BS_FIX_ADD_MIDDLE_ITEM,
    body: {
      ...body,
      items_middle: [...body.items_middle, itemMiddle],
    },
    item_middle: itemMiddle,
    items_middle: body.items_middle,
  };
}

/**
 * Largeを追加
 */
function bsFixAddLargeItem(body, itemLarge) {
  let items_large = body.items_large;
  items_large.push(itemLarge);
  body.items_large = items_large;
  return {
    type: BS_FIX_ADD_LARGE_ITEM,
    body,
    item_large: itemLarge,
    items_large,
  };
}

/**
 * smallを削除
 */
function bsFixRemoveSmallItem(body, smallId) {
  const items_small = ReducerHelper.removeItemById(
    body.items_small,
    "small_id",
    smallId
  );
  return {
    type: BS_FIX_REMOVE_SMALL_ITEM,
    body,
    small_id: smallId,
    items_small,
  };
}

/**
 * Middleを削除
 */
function bsFixRemoveMiddleItem(body, middleId) {
  const items_middle = ReducerHelper.removeItemById(
    body.items_middle,
    "middle_id",
    middleId
  );
  return {
    type: BS_FIX_REMOVE_MIDDLE_ITEM,
    body,
    middle_id: middleId,
    items_middle,
  };
}

/**
 * Largeを削除
 */
function bsFixRemoveLargeItem(body, largeId) {
  const items_large = ReducerHelper.removeItemById(
    body.items_large,
    "large_id",
    largeId
  );
  return {
    type: BS_FIX_REMOVE_LARGE_ITEM,
    body,
    large_id: largeId,
    items_large,
  };
}

/**
 * Smallを修正
 */
function bsFixEditSmallItem(body, smallId, name, value) {
  const items_small = ReducerHelper.editItemByIdAndElement(
    body.items_small,
    "small_id",
    smallId,
    name,
    value
  );
  body.items_small = items_small;
  return {
    type: BS_FIX_EDIT_SMALL_ITEM,
    body,
    small_id: smallId,
    name,
    value,
    items_small,
  };
}

/**
 * Middleを修正
 */
function bsFixEditMiddleItem(body, middleId, name, value) {
  const items_middle = ReducerHelper.editItemByIdAndElement(
    body.items_middle,
    "middle_id",
    middleId,
    name,
    value
  );
  body.items_middle = items_middle;
  return {
    type: BS_FIX_EDIT_MIDDLE_ITEM,
    body,
    middle_id: middleId,
    name,
    value,
    items_middle,
  };
}

/**
 * Largeを修正
 */
function bsFixEditLargeItem(body, largeId, name, value) {
  const items_large = ReducerHelper.editItemByIdAndElement(
    body.items_large,
    "large_id",
    largeId,
    name,
    value
  );
  body.items_large = items_large;
  return {
    type: BS_FIX_EDIT_LARGE_ITEM,
    body,
    large_id: largeId,
    name,
    value,
    items_large,
  };
}

/**
 * Otherを修正
 */
function bsFixEditOtherItem(body, largeId, name, value) {
  const items_other = ReducerHelper.editItemByIdAndElement(
    body.items_other,
    "large_id",
    largeId,
    name,
    value
  );
  body.items_other = items_other;
  return {
    type: BS_FIX_EDIT_OTHER_ITEM,
    body,
    large_id: largeId,
    name,
    value,
    items_other,
  };
}

/**
 * Middleの集計を行う
 */
const bsFixCalcMiddleItemsBySmallItems = (body) => {
  const items_middle = ReducerHelper.calcItemsByAnotherItems(
    body.items_middle,
    body.items_small,
    "middle_id"
  );
  body.items_middle = items_middle;
  return {
    type: BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
    body,
    items_middle,
  };
};

/**
 * Largeで集計を行う
 */
const bsFixCalcLargeItemsByMiddleItemsAndOtherItems = (body) => {
  const items_middle_sums = ReducerHelper.calcItemsByAnotherItems(
    body.items_large,
    body.items_middle,
    "large_id"
  );
  const items_large = ReducerHelper.calcSumBetweenTwoItems(
    items_middle_sums,
    body.items_other
  );
  body.items_large = items_large;
  return {
    type: BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS,
    body,
    items_large,
  };
};

/**
 * カテゴリで集計を行う
 * @param body
 * @returns {{type: *, body: *, items_category: *}}
 */
const bsFixCalcCategoryItemsByLargeItems = (body) => {
  const items_category = ReducerHelper.calcItemsByAnotherItems(
    body.items_category,
    body.items_large,
    "category_id"
  );
  return {
    type: BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS,
    body,
    items_category,
  };
};

export const BalanceSheetFixAction = {
  bsFixInitProps,
  bsFixAddSmallItem,
  bsFixAddMiddleItem,
  bsFixAddLargeItem,
  bsFixRemoveSmallItem,
  bsFixRemoveMiddleItem,
  bsFixRemoveLargeItem,
  bsFixEditSmallItem,
  bsFixEditMiddleItem,
  bsFixEditLargeItem,
  bsFixEditOtherItem,
  bsFixCalcMiddleItemsBySmallItems,
  bsFixCalcLargeItemsByMiddleItemsAndOtherItems,
  bsFixCalcCategoryItemsByLargeItems,
};
