$(() => {
  if ($("#open_modal").data("flag")) {
    location.href = "/homes#modal";
  }

  $("#homes-index #value_company_id").autocomplete({
    source: (request, response) => {
      $.ajax({
        type: "GET",
        url: "/companies/search",
        dataType: "json",
        data: { q: request.term },
        success: (data) => {
          // noinspection JSUnresolvedVariable
          const companies = $.map(data.companies, (n) => n.ja_name);
          response(companies);
        },
      });
    },
    autoFocus: true,
    delay: 300,
  });
});
