import {
  saveCheckbox,
  saveNonValidate,
  saveSelectbox,
  saveTable,
  saveTextarea,
} from "./editValidates";
import {
  answerJson,
  nextDisplay,
  submitDisplay,
  submitProcess,
  setAnswer,
} from "./editFunctions";
import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
} from "../../../constants";
import { tableRowWithLen } from "./elements";
import autosize from "autosize";

// noinspection JSUnusedLocalSymbols
function setPrevArr() {
  // interview_answerにprevArrを入れるカラムを用意する
  // answer登録時にprevArrも登録する
  // 上記は登録時に行うところ
  // ===================================
  // prevArrのカラムを取得してprevArrにセットする
}

function setView() {
  // prevArrにセットされている最後の質問ページを表示する
}

/**
 * インタビュー回答画面
 * @param questionCount
 */
export function interviewAnswerEdit({ questionCount }) {
  const prevArr = [];

  // 既存の回答をセットする
  setAnswer();
  setView();

  // 戻るボタンがクリックされた時
  $("#interview_answer_edit #trigger--back").on("click", () => {
    const $answerBlockVisible = $(
      "#interview_answer_edit .answer-block:visible"
    );
    console.log("戻るクリック");
    if (prevArr.length > 0) {
      $answerBlockVisible.hide();
    }
    const prev_pop = prevArr.pop();
    $(prev_pop).fadeIn("slow");
    nextDisplay();
  });

  const errorMessage = () => {
    alert("選択または入力してください。");
  };

  // 次への質問ボタンがクリックされた時
  const $save = $("#interview_answer_edit .trigger--save");
  $save.on("click", function () {
    const $element = $(this);
    const idName = $element.attr("id");
    console.log(idName);

    const $answerBlockVisible = $(
      "#interview_answer_edit .answer-block:visible"
    );
    const answerType = $answerBlockVisible.data("type");
    const $formItemTextBox = $(
      "#interview_answer_edit .answer-block:visible .form-item-text-box"
    );
    const isRequire = $answerBlockVisible.data("required_flag");

    setTimeout(() => {
      const elements = $(".form-item-input, .form-item-text-box");
      autosize.destroy(elements);
      autosize(elements);
    }, 100);

    switch (idName) {
      case "next":
        // 保存して次へ
        console.log("保存して次へクリック");
        switch (answerType) {
          case ANSWER_TYPE__RADIO_BUTTON:
          case ANSWER_TYPE__CHECK_BOX:
            console.log("answerType: saveCheckbox");
            if (!isRequire || $answerBlockVisible.find("input:checked").val()) {
              saveCheckbox({
                $answerBlockVisible,
                prevArr,
                submitDisplay,
              });
            } else {
              errorMessage();
            }
            break;
          case ANSWER_TYPE__TEXT_BOX:
            console.log("answerType: saveTextarea");
            if (!isRequire || $formItemTextBox.val()) {
              saveTextarea({
                $answerBlockVisible,
                prevArr,
                submitDisplay,
              });
            } else {
              errorMessage();
            }
            break;
          case ANSWER_TYPE__SELECT_BOX:
            console.log("answerType: saveSelectbox");
            if (
              !isRequire ||
              $answerBlockVisible.find("option:selected").val()
            ) {
              // セレクトボックスが選択されていれば
              saveSelectbox({
                $answerBlockVisible,
                prevArr,
                submitDisplay,
              });
            } else {
              errorMessage();
            }
            break;
          case ANSWER_TYPE__TABLE:
            console.log("answerType: saveTable");
            if (
              !isRequire ||
              $(
                "#interview_answer_edit .answer-block:visible .form-item-input"
              ).val()
            ) {
              saveTable({
                $answerBlockVisible,
                prevArr,
                submitDisplay,
                idName,
              });
            } else {
              errorMessage();
            }
            break;
          default:
            console.warn("answerType: saveNonValidate");
            saveNonValidate({
              $answerBlockVisible,
              prevArr,
              submitDisplay,
            });
        }
        break;
      case "save":
        // 一時保存
        console.log("一時保存クリック");
        break;
      default:
        throw new Error("Unknown action type");
    }
  });

  /**
   * edit form submit イベントを追加
   * フォームの送信
   */
  $("#interview_answer_edit form").submit(() =>
    submitProcess({ questionCount })
  );

  /**
   * addイベントを追加
   * テーブル 行の追加
   */
  $(document).on("click", ".trigger--add_row", () => {
    const $answerBlockVisible = $(
      "#interview_answer_edit .answer-block:visible"
    );

    const question_id = $answerBlockVisible.data("id");
    const len =
      $("#interview_answer_edit .answer-block:visible tbody").children()
        .length + 1;
    const item_ids = $answerBlockVisible.data("item_ids");
    $("#interview_answer_edit .answer-block:visible .table tbody").append(
      "<tr/>"
    );
    for (let i = 0; i < item_ids.length; i++) {
      const element = $(
        tableRowWithLen({
          questionId: question_id,
          itemIds: item_ids,
          i,
          len,
        })
      );
      $(
        "#interview_answer_edit .answer-block:visible .table tbody tr:last"
      ).append(element);
      setTimeout(() => {
        autosize(element.find(".form-item-input"));
      }, 0);
    }
  });

  /**
   * clickイベントを追加
   * テーブル 行の削除
   */
  $(document).on("click", ".trigger--remove_row", () => {
    const len = $(
      "#interview_answer_edit .answer-block:visible tbody"
    ).children().length;
    if (len > 1) {
      const rt = confirm("最後の行を削除してよろしいですか？");
      if (rt === true) {
        $(
          "#interview_answer_edit .answer-block:visible .table tbody tr:last"
        ).remove();
      }
    }
  });

  /**
   * clickイベントを追加
   * 一時保存
   */
  $save.on("click", function () {
    const element = $(this);
    const group_id = element.data("group_id");
    const report_id = element.data("report_id");
    const interview_id = element.data("interview_id");
    const answer_id = element.data("answer_id");
    const answer_answer = JSON.stringify(answerJson({ questionCount }));
    // FIXME: POSTに修正する
    $.ajax({
      type: "GET",
      url: `/reports/${report_id}/interview_answers/${answer_id}/temporary_saving`,
      data: {
        group_id,
        report_id,
        interview_id,
        answer_id,
        answer_answer,
      },
      dataType: "json",
      success: (_response) => console.log("success"),
      error: (_response) => console.log("error"),
    });
  });
}
