$(() => {
  let tmp = "1111";
  // 候補とリレーションからの選択による入力
  $("#nonnames-modal .trigger--select-modal-value").on("click", function () {
    const data_id = $(this).data("id");
    const value = $(this).data("value");
    console.log(data_id);

    $(`#event--show_text_${data_id}`).html(value.replace(/\n/g, "<br>"));
    $(`#txt_${data_id}`).val(value);
    $(`.event--input_modal_text[data-id=${data_id}]`).val(value).focus();
  });

  // エンター時に確定
  $("#nonnames-modal .trigger--enter_fix").on("keypress", function (e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      $(this).parents(".remodal").find(".trigger--fix_modal").click();
      return false;
    }
    return true;
  });

  // クリアボタン
  $("#nonnames-modal .trigger--clear_value").on("click", function () {
    const data_id = $(this).data("id");
    const data_label = $(this).data("label");
    $(`#event--show_text_${data_id}`).html(`${data_label}を入力`);
    $(`#body_${data_id}`).val("");
    $(`.event--input_modal_text[data-id=${data_id}]`).val("");
  });

  // 確定ボタン
  $("#nonnames-modal .trigger--fix_modal").on("click", function () {
    const element = $(this)
      .parents(".remodal")
      .find(".event--input_modal_text");
    const data_id = element.data("id");
    const value = element.val();
    console.log(data_id);
    // テキストエリアの内容をビューに反映する
    console.log(`#body_${data_id}`, value);
    if (value !== "") {
      $(`#event--show_text_${data_id}`).html(value.replace(/\n/g, "<br>"));
      $(`#body_${data_id}`).val(value);
      $(`#body_${data_id}_name`).val(value);
    }
  });

  // モーダル表示時にテキストフィールドをフォーカス
  $(document).on("opening", ".trigger--modal_text", function (e) {
    const data_id = $(this).data("id");
    $(`.event--input_modal_text[data-id=${data_id}]`).focus();
  });
});
