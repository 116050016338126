$(() => {
  const unit = ["", "万", "億", "兆", "京"];

  $(
    "#reports-edit .trigger--unit-conversion, #mandas-edit .trigger--unit-conversion, #report_financials-new .trigger--unit-conversion, #report_financials-edit .trigger--unit-conversion"
  ).each((i, element) => {
    const price = $(element).val();
    if (price === "") {
      return;
    }
    const prices = String(price)
      .replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,")
      .split(",")
      .reverse();
    let result = "";
    for (let i = 0; i < prices.length; i++) {
      if (!prices[i].match(/^[0]+$/)) {
        result = prices[i].replace(/^[0]+/g, "") + unit[i] + result;
      }
    }
    result = result.replace(/^\-/, "△");
    $(element).next(".event--unit-conversion").text(`${result}円`);
    $(element)
      .parent(".field_with_errors")
      .next(".event--unit-conversion")
      .text(`${result}円`);
  });

  $(
    "#reports-new .trigger--unit-conversion, #reports-edit .trigger--unit-conversion, #mandas-edit .trigger--unit-conversion, #report_financials-new .trigger--unit-conversion, #report_financials-edit .trigger--unit-conversion"
  ).on("keyup", function () {
    const element = $(this);
    const price = element.val();

    const prices = String(price)
      .replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,")
      .split(",")
      .reverse();
    let result = "";
    for (let i = 0; i < prices.length; i++) {
      if (!prices[i].match(/^[0]+$/)) {
        result = prices[i].replace(/^[0]+/g, "") + unit[i] + result;
      }
    }
    result = result.replace(/^\-/, "△");
    element.next(".event--unit-conversion").text(`${result}円`);
    element
      .parent(".field_with_errors")
      .next(".event--unit-conversion")
      .text(`${result}円`);
  });
});
