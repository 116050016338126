// noinspection OverlyNestedFunctionJS
import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
} from "../../../../constants";
import { tableRow } from "../elements";
import autosize from "autosize";

// noinspection OverlyNestedFunctionJS
/**
 * 解答をセットする
 */
export function setAnswer() {
  const answers = $("#interview_answer_edit").data("answer_answer");
  const questionCount = $("#interview_answer_edit .answer-block").length;
  for (let i = 1; i <= questionCount; i++) {
    const $question = $(`#interview_answer_edit #question_${i}`);
    const questionId = $question.data("id");
    const answerType = $question.data("type");

    if (!answers[questionId]) {
      continue;
    }

    let itemId;
    let itemIds;
    const $interviewAnswerEdit = $(
      `#interview_answer_edit #${Object.keys(answers[questionId]["result"])[0]}`
    );

    switch (answerType) {
      case ANSWER_TYPE__RADIO_BUTTON:
        // ラジオボタン
        $interviewAnswerEdit.attr("checked", true);
        break;
      case ANSWER_TYPE__SELECT_BOX:
        // セレクトボックス
        $interviewAnswerEdit.attr("selected", true);
        break;
      case ANSWER_TYPE__CHECK_BOX:
        // チェックボックス
        itemIds = Object.keys(answers[questionId]["result"]);
        $.each(itemIds, (index) =>
          $(`#interview_answer_edit #${itemIds[index]}`).attr("checked", true)
        );
        break;
      case ANSWER_TYPE__TEXT_BOX:
        // テキストボックス
        itemId = Object.keys(answers[questionId]["result"])[0];
        const itemItem = answers[questionId]["result"][itemId];
        $(`#interview_answer_edit #${questionId}`).text(itemItem);
        setTimeout(() => {
          autosize($(".form-item-input"));
        }, 0);
        break;
      case ANSWER_TYPE__TABLE:
        // テーブル
        const rows = Object.keys(answers[questionId]["result"]);
        if (Object.keys(rows).length > 0) {
          const itemIds = Object.keys(answers[questionId]["result"]["1"]);
          $.each(rows, (index, _) => {
            if (index <= 0) {
            } else {
              $(`#interview_answer_edit #question_${i} .table tbody`).append(
                "<tr/>"
              );
              for (let j = 0; j < itemIds.length; j++) {
                const elm = $(tableRow({ index, questionId, itemIds, j }));
                $(
                  `#interview_answer_edit #question_${i} .table tbody tr:last`
                ).append(elm);
                setTimeout(() => {
                  autosize(elm.find(".form-item-input"));
                }, 0);
              }
            }
            $.each(itemIds, (n, item_id) => {
              const itemItem =
                answers[questionId]["result"][index + 1][item_id];
              $(
                `#interview_answer_edit .form-item-input[name='${questionId}[${
                  index + 1
                }][${item_id}]']`
              ).val(itemItem);
            });
          });
        }
        break;
      default:
        throw new Error("Unknown answer type");
    }
  }
}
