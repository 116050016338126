import React from 'react';
import { ArrayTools } from './arrayTools'

function createRefsDictByItems(items, id_name="middle_id") {
  let r = []
  let tmp_hash = {}
  if(ArrayTools.checkArray(items)){
  items.forEach(v => {
    tmp_hash[id_name] = v[id_name]
    tmp_hash["addChildRef"] = React.createRef();
    tmp_hash["addChildBelowRef"] = React.createRef();
    tmp_hash["selfValueRef"] = React.createRef();
    tmp_hash["selfNameRef"] = React.createRef();
    r.push({...tmp_hash });
  });
  }
  return r;
}

function findRefFromRefsDictById(refsDict, id_name="middle_id", ref_name="addChildRef", id) {
  let r = null;
  refsDict.forEach(v => {
    if (v[id_name] == id) {
      r = v[ref_name];
    }
  })
  return r;
}

export const refsCreator = {
  createRefsDictByItems,
  findRefFromRefsDictById,
}