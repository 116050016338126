export const sendGcEvent = (type, value) => {
    const DEVELOPMENT = "development";
    if (process.env.NODE_ENV === DEVELOPMENT) {
        console.log(`[GA] set ${type} ${value}`);
    }

    if (type.indexOf("dimension") !== -1) {
        // noinspection JSUnresolvedFunction
        gtag('event', type, {
            [type]: value
        });
    } else if (type.indexOf("metric") !== -1) {
        // noinspection JSUnresolvedFunction
        gtag('send', 'hitType', {
            [type]: value
        });
    }
};

window.sendGcEvent = sendGcEvent;
