import autosize from "autosize";
import ClipboardJS from "../../vendor/clipboard.min";

$(() => {
  // テキストエリアの自動リサイズ
  autosize($("textarea"));

  // クリップボード
  const clipboard = new ClipboardJS(".clipboard-copy");
  clipboard.on("success", () => console.log("クリップボードにコピーしました"));

  // アラート
  const $alert = $(".alert");
  if ($alert.length) {
    $(document).ready(() => setTimeout(() => $alert.fadeOut(), 5000));
  }
});
