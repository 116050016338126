import {
  ANSWER_TYPE__CHECK_BOX,
  ANSWER_TYPE__RADIO_BUTTON,
  ANSWER_TYPE__SELECT_BOX,
  ANSWER_TYPE__TABLE,
  ANSWER_TYPE__TEXT_BOX,
} from "../../../../constants";

/**
 * 送信処理
 */
export function submitProcess({ questionCount }) {
  for (let i = 1; i <= questionCount; i++) {
    const $question = $(`#question_${i}`);

    const answerType = $question.data("type");
    switch (answerType) {
      case ANSWER_TYPE__RADIO_BUTTON:
        const radio = $question.find("input:checked").val();
        if (!radio) {
          $question.find("input").prop("disabled", true);
        }
        break;
      case ANSWER_TYPE__SELECT_BOX:
        const select = $question.find("option:selected").val();
        if (select === 0) {
          $question.find("option").prop("disabled", true);
        }
        break;
      case ANSWER_TYPE__CHECK_BOX:
        const check = $question.find("input:checked").val();
        if (!check) {
          $question.find("input").prop("disabled", true);
        }
        break;
      case ANSWER_TYPE__TEXT_BOX:
        const textarea = $question.find(".form-item-text-box").val();
        if (textarea === "") {
          $question.find("textarea").prop("disabled", true);
        }
        break;
      case ANSWER_TYPE__TABLE:
        // 表の一行が全て空の場合は送信しない
        // const question_id = $question.data("id");
        const element = $(`#interview_answer_edit #question_${i} tbody`);
        // tbody内のeach(tr)
        element.children().each((index, data) => {
          let send_flag = false;
          // tr内のeach(td)
          $(data)
            .children()
            .each((i, d) => {
              if ($(d).children().val() !== "") {
                send_flag = true;
              }
            });

          if (!send_flag) {
            $(data).remove();
          }
        });
        break;
      default:
        throw Error("Unknown answer type");
    }
  }
}
