import React from 'react';
import { hot } from 'react-hot-loader'
import InputBookV from '../input/InputBookV'


const OtherMiddleItemTr = props => {
  const book_v = props.book_v
  if (![0,"",null,undefined].includes(book_v)) {
    return (
      <tr>
        <td colSpan={11} className="indent-2 fs-middle">
          その他
      </td>
        <td colSpan={1} className="icon">
        </td>
        <td colSpan={12}>
          <InputBookV
            value={props.book_v}
            valueStatus="sum"
            edittable={false}
            kindOfItem="middle" />
        </td>
      </tr>
    );
  } else {
    return false;
  }
}

OtherMiddleItemTr.defaultProps = {
  book_v: null,
}

export default hot(module)(OtherMiddleItemTr);
