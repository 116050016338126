import "./document_sheets/quick_input_events";
import "./document_sheets/modal_events";

$(() => {
  // インタビュー結果を事業概要書へ反映
  $(document).on("click", "#interview_reflection", function () {
    const element = $(this);
    const report_id = element.data("report-id");
    const document_id = element.data("document-id");
    const template_id = element.data("template-id");
    $.ajax({
      type: "GET",
      url: `/reports/${report_id}/documents/${document_id}/document_sheets/interview_reflection`,
      data: {
        report_id,
        document_id,
        template_id,
      },
      dataType: "json",
      success: (response) =>
        $.each(response, (key, data) =>
          $.each(data, (data_id, value) => {
            $(`#event--show_text_${data_id}`).html(
              value.replace(/\n/g, "<br>")
            );
            $(`#txt_${data_id}`).val(value);
          })
        ),
      error: () => console.log("error"),
    });
  });
});
