import axios from "axios";
// railsのviewの値による初期化
export const FINANCIAL_STATEMENT_IMAGE_INIT_PROPS =
  "FINANCIAL_STATEMENT_IMAGE_INIT_PROPS";
export const FINANCIAL_STATEMENT_IMAGE_INIT_OCR_LIMITED_COUNT =
  "FINANCIAL_STATEMENT_IMAGE_INIT_OCR_LIMITED_COUNT";
// 解析開始
export const FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK =
  "FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK";
export const FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST =
  "FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST";
export const FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_SUCCESS =
  "FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_SUCCESS";
export const FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_FAILURE =
  "FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_FAILURE";
// 解析中のステータス取得
export const FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST =
  "FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST";
export const FINANCIAL_STATEMENT_IMAGE_GET_STATUS_SUCCESS =
  "FINANCIAL_STATEMENT_IMAGE_GET_STATUS_SUCCESS";
export const FINANCIAL_STATEMENT_IMAGE_GET_STATUS_FAILURE =
  "FINANCIAL_STATEMENT_IMAGE_GET_STATUS_FAILURE";
// 結果の取得
export const FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST =
  "FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST";
export const FINANCIAL_STATEMENT_IMAGE_GET_RESULT_SUCCESS =
  "FINANCIAL_STATEMENT_IMAGE_GET_RESULT_SUCCESS";
export const FINANCIAL_STATEMENT_IMAGE_GET_RESULT_FAILURE =
  "FINANCIAL_STATEMENT_IMAGE_GET_RESULT_FAILURE";
// モーダルの開閉
export const FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN =
  "FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN";
export const FINANCIAL_STATEMENT_IMAGE_MODAL_CLOSE =
  "FINANCIAL_STATEMENT_IMAGE_MODAL_CLOSE";
// 再解析
export const FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK =
  "FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK";
export const FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST =
  "FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST";
export const FINANCIAL_STATEMENT_IMAGE_REANALYZE_SUCCESS =
  "FINANCIAL_STATEMENT_IMAGE_REANALYZE_SUCCESS";
export const FINANCIAL_STATEMENT_IMAGE_REANALYZE_FAILURE =
  "FINANCIAL_STATEMENT_IMAGE_REANALYZE_FAILURE";
//決算書入力で用いる画像を選択(checkbox)するためのアクション
export const FINANCIAL_STATEMENT_IMAGE_SELECT_IMAGE =
  "FINANCIAL_STATEMENT_IMAGE_SELECT_IMAGE";

// railsのviewの値による初期化 =========================================================================
const financialStatementImageInitProps = (fs_images) => ({
  type: FINANCIAL_STATEMENT_IMAGE_INIT_PROPS,
  fs_images,
});

const financialStatementImageInitOcrLimitedCount = (ocr_limited_count) => ({
  type: FINANCIAL_STATEMENT_IMAGE_INIT_OCR_LIMITED_COUNT,
  ocrLimitedCount: ocr_limited_count,
});

// 解析開始 ============================================================================================
const financialStatementImageExecOcrClick = ({
  fs_image_id,
  report_id,
  fs_images,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_CLICK,
  fs_image_id,
  report_id,
  fs_images,
  is_loading: true,
  ajax_text: "",
});

const financialStatementImageExecOcrRequest = ({
  fs_image_id,
  report_id,
  fs_images,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_REQUEST,
  fs_image_id,
  report_id,
  fs_images,
  is_loading: true,
  ajax_text: "通信中",
});

const financialStatementImageExecOcrSuccess = ({
  dataChanged,
  ocrLimitedCount,
  prevImages,
  fs_images,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_SUCCESS,
  dataChanged,
  ocrLimitedCount,
  prevImages,
  fs_images,
  is_loading: false,
  ajax_text: "通信成功",
});

const financialStatementImageExecOcrFailure = ({
  fs_image_id,
  report_id,
  fs_images,
  error,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_EXEC_OCR_FAILURE,
  fs_image_id,
  report_id,
  fs_images,
  is_loading: false,
  ajax_text: "通信失敗",
  error,
});

// 解析中のステータス取得 ==================================================================================
const financialStatementImageGetStatusRequest = ({
  fs_image_id,
  report_id,
  fs_images,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_GET_STATUS_REQUEST,
  fs_image_id,
  report_id,
  fs_images,
  is_loading: true,
  ajax_text: "通信中",
});
const financialStatementImageGetStatusSuccess = ({
  dataChanged,
  prevImages,
  fs_images,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_GET_STATUS_SUCCESS,
  dataChanged,
  prevImages,
  fs_images,
  is_loading: false,
  ajax_text: "通信成功",
});

const financialStatementImageGetStatusFailure = ({
  fs_image_id,
  report_id,
  fs_images,
  error,
}) => ({
  type: FINANCIAL_STATEMENT_IMAGE_GET_STATUS_FAILURE,
  fs_image_id,
  report_id,
  fs_images,
  error,
  is_loading: false,
  ajax_text: "通信失敗",
  status: "waiting",
});

// モーダルの開閉 ======================================================================================-
const financialStatementImageModalOpen = ({ fs_image }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_MODAL_OPEN,
  is_open: true,
  fs_image,
  ajax_text: "取得準備中",
});

const financialStatementImageModalClose = () => ({
  type: FINANCIAL_STATEMENT_IMAGE_MODAL_CLOSE,
  is_open: false,
});

const financialStatementImageGetResultRequest = ({ fs_image }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_GET_RESULT_REQUEST,
  is_open: true,
  fs_image,
  is_loading: true,
  ajax_text: "取得中",
});

const financialStatementImageGetResultSuccess = ({ res }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_GET_RESULT_SUCCESS,
  ocrResult: {
    result: res.data.result,
    spreadsheet: res.data.spreadsheet,
  },
  is_loading: false,
  ajax_text: "",
});

const financialStatementImageGetResultFailure = ({ error }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_GET_RESULT_FAILURE,
  error,
  is_loading: false,
  ajax_text: "解析結果の取得に失敗しました",
});

// 再解析 ====================================================================
const financialStatementImageReanalyzeClick = ({ fs_image }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_REANALYZE_CLICK,
  fs_image,
  is_loading: true,
  ajax_text: "取得準備中",
});

const financialStatementImageReanalyzeRequest = ({ fs_image }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_REANALYZE_REQUEST,
  fs_image,
  is_loading: true,
  ajax_text: "再解析中",
});

const financialStatementImageReanalyzeSuccess = ({ res }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_REANALYZE_SUCCESS,
  ocrResult: {
    result: res.data.result,
    spreadsheet: res.data.spreadsheet,
  },
  is_loading: false,
  ajax_text: "",
});

const financialStatementImageReanalyzeFailure = ({ error }) => ({
  type: FINANCIAL_STATEMENT_IMAGE_REANALYZE_FAILURE,
  error,
  is_loading: false,
  ajax_text: "解析結果の取得に失敗しました",
});

function financialStatementImageSelectImage({
  fs_image_id: fsImageId,
  fs_images: fsImages,
}) {
  const nextImages = fsImages.map((fs_image, _) => {
    if (fs_image.fs_image_id === fsImageId) {
      fs_image.selected = !fs_image.selected;
      return { ...fs_image };
    } else {
      return fs_image;
    }
  });
  return {
    type: FINANCIAL_STATEMENT_IMAGE_SELECT_IMAGE,
    fs_image_id: fsImageId,
    fs_images: nextImages,
  };
}

export const FinancialStatementImagesMainAction = {
  financialStatementImageInitProps,
  financialStatementImageInitOcrLimitedCount,

  financialStatementImageExecOcrClick,
  financialStatementImageExecOcrRequest,
  financialStatementImageExecOcrSuccess,
  financialStatementImageExecOcrFailure,

  financialStatementImageGetStatusRequest,
  financialStatementImageGetStatusSuccess,
  financialStatementImageGetStatusFailure,

  financialStatementImageGetResultRequest,
  financialStatementImageGetResultSuccess,
  financialStatementImageGetResultFailure,

  financialStatementImageModalOpen,
  financialStatementImageModalClose,

  financialStatementImageReanalyzeClick,
  financialStatementImageReanalyzeRequest,
  financialStatementImageReanalyzeSuccess,
  financialStatementImageReanalyzeFailure,

  financialStatementImageSelectImage,
};
