// ユーザー管理 > ユーザ追加画面
// http://localhost:3000/system_admins/new

$(() => {
  // グループ選択(Auto Complete)
  const $element = $("#system_admins-new #micropost_groups");
  if ($element.length) {
    $element
      .autocomplete({
        source: "/system_admins/groups_auto_complete",
        delay: 500,
        minLength: 1,
        focus: (event, ui) => {
          console.log(ui);
          $("#micropost_groups").val(`${ui.item.id}. ${ui.item.name}`);
          return false;
        },
        select: (event, ui) => {
          console.log(ui);
          $("#micropost_groups").val(`${ui.item.id}. ${ui.item.name}`);
          return false;
        },
      })
      .data("ui-autocomplete")._renderItem = (ul, item) =>
      $("<li>")
        .attr("data-value", item.id)
        .data("ui-autocomplete-item", item)
        .append(`<a>${item.name}</a>`)
        .appendTo(ul);
  }
});
