import Input_Name from './InputName'
import Input_BookV from './InputBookV'
import Input_DiffV from './InputDiffV'
import Input_FixedV from './InputFixedV'
import Input_Comment from './InputComment'
import Add_Button from './AddButton'
import Delete_Button from './DeleteButton'

export const InputName = Input_Name;
export const InputBookV = Input_BookV;
export const InputDiffV = Input_DiffV;
export const InputFixedV = Input_FixedV;
export const InputComment = Input_Comment;
export const AddButton = Add_Button;
export const DeleteButton = Delete_Button;