// オプションはこちら
// https://introjs.com/docs/intro/options/
// ==============================================
import introJs from "intro.js";
export const createIntro = (key) => {
  let intro;
  if (localStorage.getItem(key) !== "on") {
    if ($.cookie(key) !== "on") {
      // Cookieが残っている場合はスルー
      intro = introJs().setOptions({
        nextLabel: "次へ→",
        prevLabel: "←戻る",
        skipLabel: "スキップ",
        doneLabel: "終了する",
        exitOnOverlayClick: false,
        showStepNumbers: false,
        overlayOpacity: 0.3,
      });
      intro.start();
    }
    localStorage.setItem(key, "on");
  }
};
global.createIntro = createIntro;
