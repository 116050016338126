const tableRowClass = "form-control input-text form-item-input";

// ▼ Ruby側も編集が必要
// app/views/interview_answers/_question__table.html.erb:18

/**
 * テーブル列の要素
 */
export function tableRow({ questionId, index, itemIds, j }) {
  return `<td><textarea type="text" rows="1" class="${tableRowClass}" name="${questionId}[${
    index + 1
  }][${itemIds[j]}]"></textarea></td>`;
}

/**
 * テーブル列の要素
 */
export function tableRowWithLen({ questionId, len, itemIds, i }) {
  return `<td><textarea class="${tableRowClass}" rows="1" type="text" name="${questionId}[${len}][${itemIds[i]}]"></textarea></td>`;
}
