export const PL_INIT_PROPS = "PL_INIT_PROPS";
export const PL_ADD_SMALL_ITEM = "PL_ADD_SMALL_ITEM";
export const PL_ADD_MIDDLE_ITEM = "PL_ADD_MIDDLE_ITEM";
export const PL_ADD_LARGE_ITEM = "PL_ADD_LARGE_ITEM";
export const PL_REMOVE_SMALL_ITEM = "PL_REMOVE_SMALL_ITEM";
export const PL_REMOVE_MIDDLE_ITEM = "PL_REMOVE_MIDDLE_ITEM";
export const PL_REMOVE_LARGE_ITEM = "PL_REMOVE_LARGE_ITEM";
export const PL_EDIT_SMALL_ITEM = "PL_EDIT_SMALL_ITEM";
export const PL_EDIT_MIDDLE_ITEM = "PL_EDIT_MIDDLE_ITEM";
export const PL_EDIT_LARGE_ITEM = "PL_EDIT_LARGE_ITEM";

export const PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS =
  "PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS";
export const PL_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS =
  "PL_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS";
export const PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS =
  "PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS";

import { ReducerHelper } from "../../../modules/financialStatements/reducerHelper";

// actionCreator
function plInitProps(body) {
  return {
    type: PL_INIT_PROPS,
    body,
  };
}

function plAddSmallItem(body, item_small) {
  let items_small = body.items_small;
  items_small.push(item_small);
  body.items_small = items_small;
  return {
    type: PL_ADD_SMALL_ITEM,
    body,
    item_small,
    items_small,
  };
}

function plAddMiddleItem(body, item_middle) {
  let items_middle = body.items_middle;
  items_middle.push(item_middle);
  body.items_middle = items_middle;
  return {
    type: PL_ADD_MIDDLE_ITEM,
    body,
    item_middle,
    items_middle,
  };
}

function plAddLargeItem(body, item_large) {
  let items_large = body.items_large;
  items_large.push(item_large);
  body.items_large = items_large;
  return {
    type: PL_ADD_LARGE_ITEM,
    body,
    item_large,
    items_large,
  };
}

function plRemoveSmallItem(body, small_id) {
  const items_small = ReducerHelper.removeItemById(
    body.items_small,
    "small_id",
    small_id
  );
  return {
    type: PL_REMOVE_SMALL_ITEM,
    body,
    small_id,
    items_small,
  };
}

function plRemoveMiddleItem(body, middle_id) {
  const items_middle = ReducerHelper.removeItemById(
    body.items_middle,
    "middle_id",
    middle_id
  );
  return {
    type: PL_REMOVE_MIDDLE_ITEM,
    body,
    middle_id,
    items_middle,
  };
}

function plRemoveLargeItem(body, large_id) {
  const items_large = ReducerHelper.removeItemById(
    body.items_large,
    "large_id",
    large_id
  );
  return {
    type: PL_REMOVE_LARGE_ITEM,
    body,
    large_id,
    items_large,
  };
}

function plEditSmallItem(body, small_id, name, value) {
  const items_small = ReducerHelper.editItemByIdAndElement(
    body.items_small,
    "small_id",
    small_id,
    name,
    value
  );
  body.items_small = items_small;
  return {
    type: PL_EDIT_SMALL_ITEM,
    body,
    small_id,
    name,
    value,
    items_small,
  };
}

function plEditMiddleItem(body, middle_id, name, value) {
  const items_middle = ReducerHelper.editItemByIdAndElement(
    body.items_middle,
    "middle_id",
    middle_id,
    name,
    value
  );
  body.items_middle = items_middle;
  return {
    type: PL_EDIT_MIDDLE_ITEM,
    body,
    middle_id,
    name,
    value,
    items_middle,
  };
}

function plEditLargeItem(body, large_id, name, value) {
  const items_large = ReducerHelper.editItemByIdAndElement(
    body.items_large,
    "large_id",
    large_id,
    name,
    value
  );
  body.items_large = items_large;
  return {
    type: PL_EDIT_LARGE_ITEM,
    body,
    large_id,
    name,
    value,
    items_large,
  };
}

// 副作用====================================================================
const plCalcMiddleItemsBySmallItems = (body) => {
  const items_middle = ReducerHelper.calcItemsByAnotherItems(
    body.items_middle,
    body.items_small,
    "middle_id"
  );
  body.items_middle = items_middle;
  return {
    type: PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
    body,
    items_middle,
  };
};

const plCalcOtherItemsByMiddleItemsAndLargeItems = (body) => {
  const items_middle_sums = ReducerHelper.calcItemsByAnotherItems(
    body.items_large,
    body.items_middle,
    "large_id"
  );
  const items_other = ReducerHelper.calcDiffBetweenTwoItems(
    body.items_large,
    items_middle_sums
  );
  body.items_other = items_other;
  return {
    type: PL_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS,
    body,
    items_middle_sums,
    items_other,
  };
};

const plCalcSumsItemsByLargeItems = (body) => {
  const items_sums = ReducerHelper.plCalcSumsByLargeItems(
    body.items_sums,
    body.items_large
  );
  body.items_sums = items_sums;
  return {
    type: PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS,
    body,
    items_sums,
  };
};

// 自動反映機能用 =========================================================
import { autoInputReferencer } from "../../../modules/financialStatements/autoInputReferencer";
import financialStore from "../../../stores/financialStore";

/**
 * TODO: PL一括反映
 * @param result
 * @param financialStatements
 * @param valuableItemStructure
 * @returns {Function}
 */
function plAutoInputByOcrResult(
  result,
  financialStatements,
  valuableItemStructure
) {
  const store = financialStore();
  const state = store.getState();
  const body = state.financialStatements.profitAndLoss.book;
  const bodyChanged = autoInputReferencer.convertPlBody(
    financialStatements.profitAndLoss.book,
    result,
    valuableItemStructure
  );
  return (dispatch) => {
    // 変更箇所で大項目を変更
    bodyChanged.items_large_changed.forEach((itemLarge) =>
      dispatch(
        plEditLargeItem(
          body,
          itemLarge["large_id"],
          "book_v",
          itemLarge["book_v"]
        )
      )
    );
    // 変更箇所で中項目を変更
    bodyChanged.items_middle_changed.forEach((itemMiddle) =>
      dispatch(
        plEditMiddleItem(
          body,
          itemMiddle["middle_id"],
          "book_v",
          itemMiddle["book_v"]
        )
      )
    );
    // 変更箇所で小項目を変更
    bodyChanged.items_small_changed.forEach((itemMiddle) =>
      dispatch(
        plEditSmallItem(
          body,
          itemMiddle["small_id"],
          "book_v",
          itemMiddle["book_v"]
        )
      )
    );

    // Middle項目を追加
    bodyChanged.itemsMiddleAdd.forEach((item) => {
      dispatch(
        plAddMiddleItem(body, {
          addedIn: "book",
          ...item,
        })
      );
    });
  };
}

function plAutoSagInputByOcrResult(result, financialStatements) {
  const store = financialStore();
  const state = store.getState();
  const body = state.financialStatements.profitAndLoss.book;
  const body_changed = autoInputReferencer.convertSagBody(
    financialStatements.profitAndLoss.book,
    result
  );
  return (dispatch) => {
    body_changed.items_middle_added.items.forEach((item_sag) => {
      dispatch(plAddMiddleItem(body, item_sag));
    });
  };
}

export const ProfitAndLossAction = {
  plInitProps,
  plAddSmallItem,
  plAddMiddleItem,
  plAddLargeItem,
  plRemoveSmallItem,
  plRemoveMiddleItem,
  plRemoveLargeItem,
  plEditSmallItem,
  plEditMiddleItem,
  plEditLargeItem,
  plCalcMiddleItemsBySmallItems,
  plCalcOtherItemsByMiddleItemsAndLargeItems,
  plCalcSumsItemsByLargeItems,
  plAutoInputByOcrResult,
  plAutoSagInputByOcrResult,
};
