// 追加ボタン
// ============================================================================================
// noinspection JSUnusedLocalSymbols
function addButton(element, name, type, url) {
  const report_id = $("#report_id").data("id");
  const id = element.data("id");

  $.ajax({
    type: "GET",
    url: `/reports/${report_id}${url}`,
    data: { id, type },
    dataType: "json",
    success: () => $(`#add-${id}`).html(""),
    error: () => console.log("error"),
  });
}
