$(() => {
  $("#rate-form .trigger--rate-form-select").on("change", function() {
    const element = $(this);
    const report_id = element.data('report-id');
    const financial_id = element.data('financial-id');
    const formula = element.data('formula');
    const value = element.find('option:selected').val();

    $.ajax(`/reports/${report_id}/financial_rate/${financial_id}/form`, {
      type: 'get',
      data: {
        value: value,
        formula: formula,
        back_link: $(location).attr('pathname') + $(location).attr('search')
      }
    }).done(data => {
      $("#rate-form .event--rate-form-select").html(data);
    });
  });
});
