import { ReducerHelper } from "../../../modules/financialStatements/reducerHelper";
import { autoInputReferencer } from "../../../modules/financialStatements";
import financialStore from "../../../stores/financialStore";

export const BS_INIT_PROPS = "BS_INIT_PROPS";
export const BS_ADD_SMALL_ITEM = "BS_ADD_SMALL_ITEM";
export const BS_ADD_MIDDLE_ITEM = "BS_ADD_MIDDLE_ITEM";
export const BS_ADD_LARGE_ITEM = "BS_ADD_LARGE_ITEM";
export const BS_REMOVE_SMALL_ITEM = "BS_REMOVE_SMALL_ITEM";
export const BS_REMOVE_MIDDLE_ITEM = "BS_REMOVE_MIDDLE_ITEM";
export const BS_REMOVE_LARGE_ITEM = "BS_REMOVE_LARGE_ITEM";
export const BS_EDIT_SMALL_ITEM = "BS_EDIT_SMALL_ITEM";
export const BS_EDIT_MIDDLE_ITEM = "BS_EDIT_MIDDLE_ITEM";
export const BS_EDIT_LARGE_ITEM = "BS_EDIT_LARGE_ITEM";

export const BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS =
  "BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS";
export const BS_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS =
  "BS_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS";
export const BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS =
  "BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS";

export const BS_AUTO_INPUT_BY_OCR_RESULT = "BS_AUTO_INPUT_BY_OCR_RESULT";
export const BS_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS =
  "BS_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS";

// actionCreator
function bsInitProps(body) {
  return {
    type: BS_INIT_PROPS,
    body,
  };
}

/**
 * Smallを追加
 */
function bsAddSmallItem(body, itemSmall) {
  let items_small = body.items_small;
  items_small.push(itemSmall);
  body.items_small = items_small;
  return {
    type: BS_ADD_SMALL_ITEM,
    body,
    item_small: itemSmall,
    items_small,
  };
}

/**
 * バランスシートに中項目の追加
 * @param body
 * @param itemMiddle
 * @returns {{item_middle: *, type: *, body: *, items_middle: *}}
 */
function bsAddMiddleItem(body, itemMiddle) {
  let items_middle = [...body.items_middle];
  items_middle.push(itemMiddle);
  body.items_middle = items_middle;
  return {
    type: BS_ADD_MIDDLE_ITEM,
    body,
    item_middle: itemMiddle,
    items_middle,
  };
}

/**
 * Largeを追加
 */
function bsAddLargeItem(body, itemLarge) {
  let items_large = body.items_large;
  items_large.push(itemLarge);
  body.items_large = items_large;
  return {
    type: BS_ADD_LARGE_ITEM,
    body,
    item_large: itemLarge,
    items_large,
  };
}

/**
 * Smallを削除
 */
function bsRemoveSmallItem(body, smallId) {
  const items_small = ReducerHelper.removeItemById(
    body.items_small,
    "small_id",
    smallId
  );
  return {
    type: BS_REMOVE_SMALL_ITEM,
    body,
    small_id: smallId,
    items_small,
  };
}

/**
 * Middleを削除
 */
function bsRemoveMiddleItem(body, middleId) {
  const items_middle = ReducerHelper.removeItemById(
    body.items_middle,
    "middle_id",
    middleId
  );
  return {
    type: BS_REMOVE_MIDDLE_ITEM,
    body,
    middle_id: middleId,
    items_middle,
  };
}

/**
 * LargeItemを削除
 */
function bsRemoveLargeItem(body, largeId) {
  const items_large = ReducerHelper.removeItemById(
    body.items_large,
    "large_id",
    largeId
  );
  return {
    type: BS_REMOVE_LARGE_ITEM,
    body,
    large_id: largeId,
    items_large,
  };
}

/**
 * BSのSmallを編集
 */
function bsEditSmallItem(body, smallId, name, value) {
  const items_small = ReducerHelper.editItemByIdAndElement(
    body.items_small,
    "small_id",
    smallId,
    name,
    value
  );
  body.items_small = items_small;
  return {
    type: BS_EDIT_SMALL_ITEM,
    body,
    small_id: smallId,
    name,
    value,
    items_small,
  };
}

/**
 *  BSのMiddleを編集
 */
function bsEditMiddleItem(body, middleId, name, value) {
  const items_middle = ReducerHelper.editItemByIdAndElement(
    body.items_middle,
    "middle_id",
    middleId,
    name,
    value
  );
  body.items_middle = items_middle;
  return {
    type: BS_EDIT_MIDDLE_ITEM,
    body,
    middle_id: middleId,
    name,
    value,
    items_middle,
  };
}

/**
 * LargeItemを編集
 */
function bsEditLargeItem(body, largeId, name, value) {
  const items_large = ReducerHelper.editItemByIdAndElement(
    body.items_large,
    "large_id",
    largeId,
    name,
    value
  );
  body.items_large = items_large;
  return {
    type: BS_EDIT_LARGE_ITEM,
    body,
    large_id: largeId,
    name,
    value,
    items_large,
  };
}

/**
 * smallを集計してmiddleに反映
 */
const bsCalcMiddleItemsBySmallItems = (body) => {
  const items_middle = ReducerHelper.calcItemsByAnotherItems(
    body.items_middle,
    body.items_small,
    "middle_id"
  );
  body.items_middle = items_middle;
  return {
    type: BS_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
    body,
    items_middle,
  };
};

/**
 * middleとlargeを集計してotherに反映
 */
const bsCalcOtherItemsByMiddleItemsAndLargeItems = (body) => {
  const items_middle_sums = ReducerHelper.calcItemsByAnotherItems(
    body.items_large,
    body.items_middle,
    "large_id"
  );
  const items_other = ReducerHelper.calcDiffBetweenTwoItems(
    body.items_large,
    items_middle_sums
  );
  body.items_other = items_other;
  return {
    type: BS_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS,
    body,
    items_middle_sums,
    items_other,
  };
};

/**
 * largeを集計してcategoryに反映
 */
const bsCalcCategoryItemsByLargeItems = (body) => {
  const items_category = ReducerHelper.calcItemsByAnotherItems(
    body.items_category,
    body.items_large,
    "category_id"
  );
  return {
    type: BS_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS,
    body,
    items_category,
  };
};

/**
 * TODO: バランスシートの自動反映
 * @param result
 * @param financialStatements
 * @param valuableItemStructure
 * @returns {Function}
 */
function bsAutoInputByOcrResult(
  result,
  financialStatements,
  valuableItemStructure
) {
  const store = financialStore();
  const state = store.getState();
  const body = state.financialStatements.balanceSheet.book;
  const bodyChanged = autoInputReferencer.convertBsBody(
    financialStatements.balanceSheet.book,
    result,
    valuableItemStructure
  );

  return (dispatch) => {
    // 変更箇所で大項目を変更
    bodyChanged.items_large_changed.forEach((item_large) =>
      dispatch(
        bsEditLargeItem(
          body,
          item_large["large_id"],
          "book_v",
          item_large["book_v"]
        )
      )
    );

    // 変更箇所で中項目を変更
    bodyChanged.items_middle_changed.forEach((item_middle) =>
      dispatch(
        bsEditMiddleItem(
          body,
          item_middle["middle_id"],
          "book_v",
          item_middle["book_v"]
        )
      )
    );

    // 変更箇所で小項目を変更
    bodyChanged.items_small_changed.forEach((item_small) =>
      dispatch(
        bsEditSmallItem(
          body,
          item_small["small_id"],
          "book_v",
          item_small["book_v"]
        )
      )
    );

    // Middle項目を追加
    bodyChanged.itemsMiddleAdd.forEach((item) =>
      dispatch(
        bsAddMiddleItem(body, {
          addedIn: "book",
          ...item,
        })
      )
    );
  };
}

export const BalanceSheetAction = {
  bsInitProps,
  bsAddSmallItem,
  bsAddMiddleItem,
  bsAddLargeItem,
  bsRemoveSmallItem,
  bsRemoveMiddleItem,
  bsRemoveLargeItem,
  bsEditSmallItem,
  bsEditMiddleItem,
  bsEditLargeItem,
  bsCalcMiddleItemsBySmallItems,
  bsCalcOtherItemsByMiddleItemsAndLargeItems,
  bsCalcCategoryItemsByLargeItems,
  bsAutoInputByOcrResult,
};
