import React from "react";
import { hot } from "react-hot-loader";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";
import indigo from "@material-ui/core/colors/indigo";

const AddMiddleItemTr = (props) => {
  const buttonStyle = {
    backgroundColor: props.backgroundColor,
    color: props.color,
    height: props.height,
    width: props.width,
    fontSize: props.fontSize,
    padding: props.padding,
    margin: props.margin,
  };
  const ref = props.addMiddleItemRef || null;
  const { name } = props;
  return (
    <tr
      onClick={(e) => props.addMiddleItem(e)}
      onDrop={(e) => props.onDropAddItemRef(e, ref)}
      onDragOver={(e) => props.onDragOver(e, ref)}
      onDragLeave={(e) => props.onDragLeave(e, ref)}
      ref={props.addMiddleItemRef}
    >
      <td colSpan={24} className="indent-2 indent-m2 text-center">
        <div className="btn btn-xs btn-pill btn-outline-secondary">
          <i className="fa fa-plus-circle"></i>
          {` ${name}に科目を追加`}
        </div>
      </td>
    </tr>
  );
};
AddMiddleItemTr.defaultProps = {
  backgroundColor: indigo[200],
  color: "white",
  height: "2.5em",
  width: "40%",
  fontSize: "0.6em",
  padding: "0",
  margin: "0.1em",
};

export default hot(module)(AddMiddleItemTr);
