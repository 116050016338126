$(() => {
  // action: row
  //===========================================================================

  /**
   * 行の削除
   * @type {jQuery|HTMLElement}
   */
  const $correctionTable = $("#correction .correction-table");
  $correctionTable.on("click", ".trigger--correction-row-drop", function () {
    const element = $(this);
    const is_new = element.data("new");
    const row = element.data("row");

    $(row).remove();

    if (is_new === "update") {
      const id = element.data("id");
      const type = element.data("type");

      $(`#correction-action-${type}`).append(
        `<input type="hidden" name="${type}[delete][${id}]" value=${id}>`
      );
    }
  });

  /**
   * 修正値と修正後の自動計算
   */
  $correctionTable.on(
    "keyup",
    ".trigger--correction-change-revised_value",
    function () {
      const element = $(this);
      const row = element.data("row");
      const value = parseInt(element.val()); // correction_value
      const actual_value = parseInt($(`${row} .actual_value`).val());

      $(`${row} .revised_value`).val(value + actual_value);
    }
  );
  $correctionTable.on(
    "keyup",
    ".trigger--correction-change-correction_value",
    function () {
      const element = $(this);
      const row = element.data("row");
      const value = parseInt(element.val()); // revised_value
      const actual_value = parseInt($(`${row} .actual_value`).val());

      $(`${row} .correction_value`).val(value - actual_value);
    }
  );

  // 行の追加
  $("#correction .trigger--add-correction").on("click", function () {
    const $correction = $("#correction");
    const report_id = $correction.data("report-id");
    const financial_id = $correction.data("financial-id");

    const element = $(this);
    const type = element.data("type");
    const $correctionAction = $(`#correction-action-${type}`);
    const index = parseInt($correctionAction.data("count"));
    $correctionAction.data("count", String(index + 1));

    $.ajax(`/reports/${report_id}/financial_corrections/${financial_id}/row`, {
      type: "get",
      data: { type, new: "True", index: String(index) },
    }).done((data) => $(`#correction-${type}`).append(data));
  });

  // 行の追加（よく使われる修正項目）
  $(".correction .trigger--add-correction-popular").on("click", function () {
    const $correction = $("#correction");
    const report_id = $correction.data("report-id");
    const financial_id = $correction.data("financial-id");

    const element = $(this);
    const type = element.data("type");
    const name = element.data("name");
    const code = element.data("code");
    const data_parent = element.data("parent");
    const $correctionAction = $(`#correction-action-${type}`);
    const index = parseInt($correctionAction.data("count"));
    $correctionAction.data("count", String(index + 1));

    $.ajax(`/reports/${report_id}/financial_corrections/${financial_id}/row`, {
      type: "get",
      data: {
        type,
        new: "True",
        index: String(index),
        values: {
          parent: data_parent,
          name,
          actual_value: "0",
          code,
        },
      },
    }).done((data) => {
      $(`#correction-${type}`).append(data);
      $(`#correction-modal-${type} .remodal-close`).click();
    });
  });

  // action: period_row
  //===========================================================================
  // 行の削除
  const $correctionsSection = $(".corrections-section");
  $correctionsSection.on("click", ".trigger--correction-row-drop", function () {
    const element = $(this);
    const is_new = element.data("new");
    const row = element.data("row");
    const period = element.data("period");

    $(row).remove();

    if (is_new === "update") {
      const id = element.data("id");

      $(`#correction-action-${period}`).append(
        `<input type="hidden" name="corrections[${period}][delete][${id}]" value=${id}>`
      );
    }
  });

  // 修正値と修正後の自動計算
  $correctionsSection.on(
    "keyup",
    ".trigger--correction-change-revised_value",
    function () {
      const element = $(this);
      const row = element.data("row");
      const value = parseInt(element.val()); // correction_value
      const actual_value = parseInt($(`${row} .actual_value`).val());

      $(`${row} .revised_value`).val(value + actual_value);
    }
  );
  $correctionsSection.on(
    "keyup",
    ".trigger--correction-change-correction_value",
    function () {
      const element = $(this);
      const row = element.data("row");
      const value = parseInt(element.val()); // revised_value
      const actual_value = parseInt($(`${row} .actual_value`).val());

      $(`${row} .correction_value`).val(value - actual_value);
    }
  );

  // 行の追加
  $correctionsSection.on("click", ".trigger--add-correction", function () {
    const element = $(this);
    const report_id = element.data("report-id");
    const period = element.data("period");
    const parents = element.data("parents");

    const counter = $(`#correction-action-${period}`);
    const index = parseInt(counter.data("count"));
    $(counter).data("count", String(index + 1));

    $.ajax(`/reports/${report_id}/financial_corrections/period_row`, {
      type: "get",
      data: {
        period,
        new: "True",
        index: String(index),
        parents,
      },
    }).done((data) =>
      $(`#correction-period-${period} .corrections`).append(data)
    );
  });
});
