$(() => {
  $("#wacc-form .trigger--wacc-form-select").on("change", function() {
    const element = $(this);
    const report_id = element.data('report-id');
    const financial_id = element.data('financial-id');
    const value = element.find('option:selected').val();

    $.ajax(`/reports/${report_id}/financial_wacc/${financial_id}/form`, {
      type: 'get',
      data: {
        value: value,
        back_link: $(location).attr('pathname') + $(location).attr('search')
      }
    }).done(data => {
      $("#wacc-form .event--wacc-form-select").html(data);
    });
  });
});
