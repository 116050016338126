export const titleForUrl = title => title ?
    title.replace(/\//g, "_")
        .replace(/%/g, "_")
        .replace(/\$/g, "_")
        .replace(/#/g, "_")
        .replace(/&/g, "_")
        .replace(/'/g, "_")
        .replace(/~/g, "_")
        .replace(/"/g, "_")
        .replace(/!/g, "_")
        .replace(/`/g, "_")
        .replace(/@/g, "_")
        .replace(/\*/g, "_")
        .replace(/{/g, "_")
        .replace(/}/g, "_")
        .replace(/\^/g, "_")
        .replace(/\|/g, "_")
        .replace(/=/g, "_")
        .replace(/</g, "_")
        .replace(/>/g, "_")
        .replace(/\?/g, "_")
        .replace(/\(/g, "_")
        .replace(/\)/g, "_")
        .replace(/\[/g, "_")
        .replace(/]/g, "_") : "id";
