import "../lib/common";
import "../lib/maker";
import "../lib/financials";
import "../lib/similarities";
import "core-js/stable";
import "regenerator-runtime/runtime";

import WebpackerReact from "webpacker-react";
import { InterviewBackgroundContainer } from "../components/interview";
import { StatementCategoryFixViewContainer } from "../components/financial/financial_statements";
import { DEVELOPMENT } from "../constants";

// noinspection JSUnresolvedFunction
WebpackerReact.setup({
  InterviewBackgroundContainer,
  StatementCategoryFixViewContainer,
});

if (process.env.NODE_ENV === DEVELOPMENT) {
  console.log("--- loaded maker");
}
