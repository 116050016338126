// 登録・削除ボタン
// ============================================================================================
// noinspection JSUnusedLocalSymbols

export function triggerButton(element, operate, model, action, text, options) {
  let adviser_id;
  let report_id;
  const id = element.data("id");
  const data = {};
  let url;

  if (options["route"] === "report") {
    if (element.data("report-id") === undefined) {
      report_id = $("#report_id").data("id");
    } else {
      report_id = element.data("report-id");
    }
    url = `/reports/${report_id}/${model}`;
    data["id"] = id;
  } else if (options["route"] === "adviser") {
    if (element.data("adviser-id") === undefined) {
      adviser_id = $("#adviser_id").data("id");
    } else {
      adviser_id = element.data("adviser-id");
    }
    url = `/advisers/${adviser_id}/${model}`;
    data["id"] = id;
  } else {
    data["target_id"] = id;
    url = `/${model}`;
  }

  if (options["type"]) {
    data["type"] = options["type"];
  }

  $.ajax({
    type: "GET",
    url: `${url}/${operate}_${action}`,
    data,
    dataType: "json",
  })
    .done(() => {
      // 追加時の処理
      // ================================================================================
      if (operate === "add") {
        element.html(`<i class="fa fa-check"></i> ${text}`);

        element.removeClass(`trigger--add_${action}`);
        if (!options["color"]) {
          element.removeClass("btn-outline-secondary");
        }

        if (options["only"] === "add") {
          element.removeClass("btn");
        } else {
          element.addClass(`trigger--remove_${action}`);
          if (!options["color"]) {
            element.addClass("btn-outline-info");
          }
        }

        // 削除時の処理
        // ================================================================================
      } else {
        element.html(text);

        element.removeClass(`trigger--remove_${action}`);
        if (!options["color"]) {
          element.removeClass("btn-outline-info");
        }

        if (options["only"] === "remove") {
          element.removeClass("btn");
          element.html("");
        } else {
          element.addClass(`trigger--add_${action}`);
          if (!options["color"]) {
            element.addClass("btn-outline-secondary");
          }
        }
      }
    })
    .fail(() => console.log("error"));
}
