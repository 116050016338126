$(() => {
  // 「クイック入力」から「プレビュー」に即座に反映する
  // ============================================================================================
  // コメント入力
  $(
    "#document_sheets-new .trigger--input_comment, #document_sheets-edit .trigger--input_comment"
  ).on("keyup", function () {
    const value = $(this).val().replace(/\n/g, "<br>");
    const element = $("#event--show_text_comment");

    if (value === "") {
      element.html(
        '<a href="#modal-com" id="event--show_text_comment"><p>コメントを入力</p></a>'
      );
    } else {
      element.html(value);
    }
  });

  // テキスト入力
  $(
    "#document_sheets-new .trigger--input_text, #document_sheets-edit .trigger--input_text"
  ).on("keyup", function () {
    const data_id = $(this).data("id");
    const value = $(this).val().replace(/\n/g, "<br>");

    if (value === "") {
      $(`#event--show_text_${data_id}`).html(
        `<a href="#modal-txt_${data_id}" id="event--show_text_${data_id}">テキストを入力</a>`
      );
    } else {
      $(`#event--show_text_${data_id}`).html(value);
    }
  });

  // 画像入力
  $(
    "#document_sheets-new .trigger--upload_image, #document_sheets-edit .trigger--upload_image"
  ).change(function () {
    const data_id = $(this).data("id");
    const file = $(this).prop("files")[0];

    // 画像以外は処理を停止
    if (!file.type.match("image.*")) {
      $(this).val("");
      return;
    }

    // 画像表示
    const reader = new FileReader();
    reader.onload = () => {
      const src = reader.result;
      $(`#event--image_block_${data_id}`)
        .find(".trigger--change_image")
        .addClass("active")
        .html(`<img src=${src}>`);
    };
    reader.readAsDataURL(file);
    $(`#event--show_image_title_${data_id}`).html(
      `${file.name}<span class="trigger--remove_image red ml-2" data-id="${data_id}"><i class="fa fa-minus-circle"/></span>`
    );
  });

  // 画像の削除処理
  $(document).on(
    "click",
    "#document_sheets-new .trigger--remove_image, #document_sheets-edit .trigger--remove_image",
    function () {
      const data_id = $(this).data("id");
      const title = $(`#event--show_image_title_${data_id}`);

      title.siblings("input[type='file']").val("");
      title.html(
        `<input id="event--removed_image_${data_id}" type="hidden" name="img[${data_id}]" value="remove">`
      );

      $(`#event--image_block_${data_id}`)
        .find(".trigger--change_image")
        .removeClass("active")
        .html(`画像を入力<br>（画像${data_id}）`);
    }
  );
});
