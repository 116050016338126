import React from "react";
import { hot } from "react-hot-loader";
import OcrResultContainer from "../share/OcrResult";
const OcrResultList = (props) => {
  const { referenceAreaInput } = props;
  const results = referenceAreaInput.map((item) => {
    return JSON.parse(item.result);
  });
  const units = referenceAreaInput.map((item) => {
    return item.unit;
  });
  const fs_images = referenceAreaInput.map((item) => {
    return item.fs_image;
  });

  return (
    <React.Fragment>
      <div className="mt-3 mb-1 ml-3 fs-11">
        ※以下、ドラッグ&ドロップできます。
      </div>
      <div className="scroll-area pr-1">
        {results.map((result, index) => (
          <OcrResultContainer
            key={index}
            index={index}
            result={result}
            unit={units[index]}
            file_name={fs_images[index].file_name}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default hot(module)(OcrResultList);
