import React from "react";
import { connect } from "react-redux";
import { hot } from "react-hot-loader";
import { StatementCategoryFixAction } from "../../../../actions/financialActions/financialStatements/StatementCategoryFixAction";

import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import deepOrange from "@material-ui/core/colors/deepOrange";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { red } from "@material-ui/core/colors";
import FinancialAppProvider from "../../../appProviders/FinancialAppProvider";

class StatementCategoryFix extends React.Component {
  constructor(props) {
    super(props);
    this.dataset = this.props.defaultProps.dataset;
    this.categoryName = this.props.defaultProps.categoryName;
    this.handleInit(this.dataset);
  }

  handleInit(dataset) {
    this.props.handleInit(dataset);
  }
  handleChange(index, e) {
    this.props.handleChange(this.dataset, index, e);
  }
  addItem() {
    const item = { 項目名: "", 項目1: 0 };
    this.props.handleAddItem(this.dataset, item);
  }
  deleteItem(index) {
    this.props.handleDeleteItem(this.dataset, index);
  }
  renderHeader() {
    return (
      <thead>
        <tr className="row px-3">
          <th className="col-6">項目名</th>
          <th className="col-3">金額1</th>
          <th className="col-2">金額2</th>
          <th className="col-1">削除</th>
        </tr>
      </thead>
    );
  }

  renderTr(index) {
    const nameName = `name[${this.categoryName}][${index}]`;
    const value1Name = `value[${this.categoryName}][${index}]`;
    const name = this.dataset[index]["項目名"];
    const value1 = this.dataset[index]["金額1"];
    const value2 = this.dataset[index]["金額2"];

    return (
      <tr key={index} className="row px-3">
        <td className="col-6">
          <input
            value={name}
            name={nameName}
            className="form-control"
            onChange={(e) => this.handleChange(index, e)}
          />
        </td>
        <td className="col-3">
          <input
            value={value1}
            name={value1Name}
            className="form-control"
            onChange={(e) => this.handleChange(index, e)}
          />
        </td>
        <td className="col-2">
          <span className="pt-2">{value2}</span>
        </td>
        <td className="col-1">
          <IconButton size="small" onClick={() => this.deleteItem(index)}>
            <DeleteForeverOutlinedIcon style={{ color: red[800] }} />
          </IconButton>
        </td>
      </tr>
    );
  }

  renderTbody() {
    let list = [];
    const dataset = this.dataset;
    if (![[], undefined, null].includes(dataset)) {
      if (dataset.length > 1) {
        dataset.forEach((v, i) => list.push(this.renderTr(i)));
      } else if (dataset.length === 1) {
        list.push(this.renderTr(0));
      }
    }
    return <tbody>{list}</tbody>;
  }

  render() {
    this.dataset = this.props.financialStatements["statementCategoryFix"][
      "dataset"
    ];
    return (
      <React.Fragment>
        <table className="table table-sm">
          {this.renderHeader()}
          {this.renderTbody()}
        </table>
        <div className="text-center">
          <Button
            variant="contained"
            style={{ backgroundColor: deepOrange[500], color: "white" }}
            startIcon={<AddCircleIcon />}
            onClick={() => this.addItem()}
          >
            追加
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleInit(dataset) {
      dispatch(
        StatementCategoryFixAction.statementCategoryFixInitProps(dataset)
      );
    },
    handleChange(dataset, index, e) {
      dispatch(
        StatementCategoryFixAction.statementCategoryFixEditItem(
          dataset,
          index,
          e
        )
      );
    },
    handleAddItem(dataset, item) {
      dispatch(
        StatementCategoryFixAction.statementCategoryFixAddItem(dataset, item)
      );
    },
    handleDeleteItem(dataset, index) {
      dispatch(
        StatementCategoryFixAction.statementCategoryFixDeleteItem(
          dataset,
          index
        )
      );
    },
  };
}

function mapStateToProps(state) {
  return state;
}

const StatementCategoryFixContainer = hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(StatementCategoryFix)
);

class StatementCategoryFixViewContainer extends React.Component {
  render() {
    return (
      <FinancialAppProvider>
        <StatementCategoryFixContainer defaultProps={this.props} />
      </FinancialAppProvider>
    );
  }
}
export default StatementCategoryFixViewContainer;
