// 削除ボタン
// ============================================================================================
// noinspection JSUnusedLocalSymbols
export function removeButton(element, operate, name, type, url) {
  const report_id = $("#report_id").data("id");
  const id = element.data("id");

  $.ajax({
    type: "GET",
    url: `/reports/${report_id}${url}`,
    data: { id, type },
    dataType: "json",
    success: () => {
      const element = $(`#remove-${id}`);
      const button = element.find(".remove-button");
      switch (operate) {
        case "remove":
          element.addClass("disabled");
          button.removeClass(`red trigger--remove_${name}`);
          button.addClass(`green trigger--add_${name}`);
          button.html('<i class="fa fa-plus-circle">');
          break;
        case "add":
          element.removeClass("disabled");
          button.removeClass(`green trigger--add_${name}`);
          button.addClass(`red trigger--remove_${name}`);
          button.html('<i class="fa fa-times-circle">');
          break;
        default:
          break;
      }
    },
    error: () => console.log("error"),
  });
}
