import React, {useState} from 'react';
import { hot } from 'react-hot-loader'
import InputBookV from '../input/InputBookV'



const SumsItemTr = props => {
  const explainText = convertNameToExplain(props.name);
  const {warning, warning_text} = props
  const style_warning = warning ? { color: "red", fontWeight: "bold" } : {}

  return (
    <tr>
      <td colSpan={11} className="indent-m1 fs-large">
        {props.name}
      </td>
      <td colSpan={1} className="icon"></td>
      <td colSpan={warning ? 5 : 6 } className="fs-10">
        <HTMLComponent htmlstring={explainText}/>
      </td>
      {warning && 
        <td colSpan={2} style={{ color: "red" }} className="fs-10">
          <i className="fa fa-exclamation-triangle"></i>
          {warning_text}
        </td>
      }
      <td colSpan={warning ? 5 : 6}>
        <InputBookV
          style={style_warning}
          value={props.book_v}
          valueStatus="sum"
          edittable={false}
          kindOfItem="middle"
        />
      </td>
    </tr>
    );
} 
  

SumsItemTr.defaultProps = {
  book_v: null,
  warning: false,
  warning_text: ""
}

function convertNameToExplain(name) {
  switch (name) {
    case "売上総利益":
      return "=【売上高】-【売上原価】";
    case "営業利益":
      return "= 売上総利益 -【販売費及び管理費】";
    case "経常利益":
      return "= 営業利益 +【営業外収益】<br>-【営業外費用】";
    case "税引前当期純利益":
      return "= 経常利益 +【特別利益】<br>-【特別損益】";
    case "当期純利益":
      return "= 税引前当期純利益 <br>-【法人税等合計】";
    default:
      return "";
  };
}
const HTMLComponent = (props) => {
  return(
      <span dangerouslySetInnerHTML={{__html: props.htmlstring}}></span>
  );
}

export default hot(module)(SumsItemTr);
