import React from "react";
import { connect } from "react-redux";
import SmallItemTr from "../share/fix/SmallItemTr";
import MiddleItemTr from "../share/fix/MiddleItemTr";
import LargeItemTr from "../share/fix/LargeItemTr";
import OtherMiddleItemTr from "../share/fix/OtherMiddleItemTr";
import SumsItemTr from "../share/fix/SumsItemTr";
import { hot } from "react-hot-loader";
import { ProfitAndLossFixAction } from "../../../../../actions/financialActions/financialStatements/ProfitAndLossFixAction";
import { HashTools } from "../../../../../modules/financialStatements/hashTools";
import { ArrayTools } from "../../../../../modules/financialStatements/arrayTools";
import { Association } from "../../../../../modules/financialStatements/associationTools";
import { debounce } from "throttle-debounce";

export class ProfitAndLossFix extends React.Component {
  // noinspection DuplicatedCode
  constructor(props) {
    super(props);
    this.items_sums = this.props.defaultProps.items_sums;
    this.items_large = this.props.defaultProps.items_large;
    this.items_middle = this.props.defaultProps.items_middle;
    this.items_small = this.props.defaultProps.items_small;
    this.items_other = this.props.defaultProps.items_other;
    this.body = {
      items_sums: this.items_sums,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    this.bookValueKeeperName = "(決算書記載)";
    this.editSmallItem = this.editSmallItem.bind(this);
    this.editMiddleItem = this.editMiddleItem.bind(this);
    this.editLargeItem = this.editLargeItem.bind(this);
    this.editSmallItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditSmallItem(this.body, id, name, value)
    );
    this.editMiddleItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditMiddleItem(this.body, id, name, value)
    );
    this.editLargeItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditLargeItem(this.body, id, name, value)
    );
    this.handleInit(this.body);
  }

  handleInit(body) {
    this.props.handleInit(body);
  }

  addSmallItem(middleId) {
    const small_id = Association.getLastIdPlusOne(this.items_small, "small_id");
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    const middleItemHasChildren = ArrayTools.checkArray(smallItems);
    const parentMiddleItem = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const book_v = middleItemHasChildren ? 0 : parentMiddleItem["book_v"];
    const diff_v = middleItemHasChildren ? 0 : parentMiddleItem["diff_v"];
    const fixed_v = middleItemHasChildren ? 0 : parentMiddleItem["fixed_v"];
    // 中項目の子要素が存在しない場合、追加される小項目の簿価は中項目の簿価になる

    // 決算書記載額その他を追加 =========
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    if (
      !middleItemHasChildren &&
      (book_v !== 0 || diff_v !== 0 || fixed_v !== 0)
    ) {
      this.body = this.props.financialStatements["profitAndLoss"]["fix"];
      const item_small_dash = {
        small_id: small_id + 1,
        middle_id: middleId,
        name: item_middle.name + this.bookValueKeeperName,
        book_v,
        diff_v,
        fixed_v,
        comment: `「${item_middle.name}」の決算書記載金額`,
        addedIn: "book",
      };
      this.props.handleAddSmallItem(this.body, item_small_dash);
    }
    //===================================

    const item_small = {
      small_id,
      middle_id: middleId,
      name: "",
      book_v: 0,
      diff_v: 0,
      fixed_v: 0,
      addedIn: "fix",
    };
    this.props.handleAddSmallItem(this.body, item_small);
  }
  addMiddleItem(largeId) {
    const middle_id = Association.getLastIdPlusOne(
      this.items_middle,
      "middle_id"
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const largeItemHasChildren = ArrayTools.checkArray(middleItems);
    const parentLargeItem = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const book_v = largeItemHasChildren ? 0 : parentLargeItem["book_v"];
    const diff_v = largeItemHasChildren ? 0 : parentLargeItem["diff_v"];
    const fixed_v = largeItemHasChildren ? 0 : parentLargeItem["fixed_v"];

    // 決算書記載額その他を追加 =========
    const item_large = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    if (
      !largeItemHasChildren &&
      (book_v !== 0 || diff_v !== 0 || fixed_v !== 0)
    ) {
      this.body = this.props.financialStatements["profitAndLoss"]["fix"];
      const item_middle_dash = {
        middle_id: middle_id + 1,
        large_id: largeId,
        name: item_large.name + this.bookValueKeeperName,
        book_v,
        diff_v,
        fixed_v,
        comment: `「${item_large.name}」の決算書記載金額`,
        addedIn: "book",
      };
      this.props.handleAddMiddleItem(this.body, item_middle_dash);
    }
    //===================================

    const item_middle = {
      middle_id,
      large_id: largeId,
      name: "",
      book_v: 0,
      diff_v: 0,
      fixed_v: 0,
      addedIn: "fix",
    };
    this.props.handleAddMiddleItem(this.body, item_middle);
  }
  // addLargeItem(e) {
  //   const lid = Association.getLastIdPlusOne(this.items_l, "lid")
  //   const item_l = {"lid": lid, "name": "", "book_v": 0, "diff_v": 0, "fixed_v": 0, "addedIn": "book"}
  //   this.props.handleAddLargeItem(this.body, this.items_l, item_l)
  // }
  removeSmallItem(smallId) {
    const item_small = Association.getItemById(
      this.items_small,
      "small_id",
      smallId
    );
    const middle_id = item_small.middle_id;
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middle_id
    );

    this.props.handleRemoveSmallItem(this.body, smallId);

    // 決算書記載金額として追加した科目を削除
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middle_id
    );
    if (
      ArrayTools.checkArray(smallItems) &&
      smallItems.length === 1 &&
      smallItems[0].name === item_middle.name + this.bookValueKeeperName
    ) {
      this.props.handleRemoveSmallItem(this.body, smallItems[0].small_id);
    }
  }
  removeMiddleItem(middleId) {
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const large_id = item_middle.large_id;
    const item_large = Association.getItemById(
      this.items_large,
      "large_id",
      large_id
    );

    this.props.handleRemoveMiddleItem(this.body, middleId);

    // 決算書記載金額として追加した科目を削除
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      large_id
    );
    if (
      ArrayTools.checkArray(middleItems) &&
      middleItems.length === 1 &&
      middleItems[0].name === item_large.name + this.bookValueKeeperName
    ) {
      this.props.handleRemoveMiddleItem(this.body, middleItems[0].middle_id);
    }
  }
  // removeLargeItem() {

  // }
  editSmallItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editSmallItemDebounce(id, name, value);
    e.preventDefault();
  }
  editMiddleItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editMiddleItemDebounce(id, name, value);
    e.preventDefault();
  }
  editLargeItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editLargeItemDebounce(id, name, value);
    e.preventDefault();
  }

  editOtherItem(largeId, e) {
    const name = e.target.name;
    const value = e.target.value;
    this.props.handleEditOtherItem(this.body, largeId, name, value);
    e.preventDefault();
  }

  renderSmallItem(smallId) {
    const item_small = Association.getItemById(
      this.items_small,
      "small_id",
      smallId
    );
    if (
      ArrayTools.checkArray(this.items_small) &&
      HashTools.checkHash(item_small)
    ) {
      return (
        <SmallItemTr
          key={smallId}
          name={item_small["name"]}
          book_v={item_small["book_v"]}
          diff_v={item_small["diff_v"]}
          fixed_v={item_small["fixed_v"]}
          comment={item_small["comment"]}
          addedIn={item_small["addedIn"]}
          removeSmallItem={() => this.removeSmallItem(smallId)}
          editSmallItem={this.editSmallItem}
          data_item_id={smallId}
          data_item_name="small_id"
        />
      );
    } else {
      return false;
    }
  }

  renderSmallItems(middleId) {
    let list = [];
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    if (ArrayTools.checkArray(smallItems)) {
      smallItems.forEach((v) => list.push(this.renderSmallItem(v["small_id"])));
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  renderMiddleItem(middleId) {
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    return (
      <React.Fragment key={middleId}>
        <MiddleItemTr
          key={middleId}
          name={item_middle["name"]}
          book_v={item_middle["book_v"]}
          diff_v={item_middle["diff_v"]}
          fixed_v={item_middle["fixed_v"]}
          comment={item_middle["comment"]}
          addedIn={item_middle["addedIn"]}
          items_small={smallItems}
          addSmallItem={() => this.addSmallItem(middleId)}
          removeMiddleItem={() => this.removeMiddleItem(middleId)}
          editMiddleItem={this.editMiddleItem}
          data_item_id={middleId}
          data_item_name="middle_id"
        />
        {this.renderSmallItems(middleId)}
      </React.Fragment>
    );
  }

  renderMiddleItems(largeId) {
    let list = [];
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    if (ArrayTools.checkArray(middleItems)) {
      middleItems.forEach((v) =>
        list.push(this.renderMiddleItem(v["middle_id"]))
      );
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  renderLargeItem(largeId) {
    const item_large = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const otherItem = Association.getItemById(
      this.items_other,
      "large_id",
      largeId
    );
    const hasMiddleItems = ArrayTools.checkArray(middleItems);
    return (
      <React.Fragment key={largeId}>
        <LargeItemTr
          key={largeId}
          name={item_large["name"]}
          book_v={item_large["book_v"]}
          diff_v={item_large["diff_v"]}
          fixed_v={item_large["fixed_v"]}
          comment={item_large["comment"]}
          addedIn={item_large["addedIn"]}
          items_middle={middleItems}
          addMiddleItem={() => this.addMiddleItem(largeId)}
          removeLargeItem={() => {}}
          editLargeItem={this.editLargeItem}
          data_item_id={largeId}
          data_item_name="large_id"
        />
        {this.renderMiddleItems(largeId)}
        {/* <AddMiddleItemTr addMiddleItem={(e)=> this.addMiddleItem(lid,e)}/> */}
        {hasMiddleItems && (
          <OtherMiddleItemTr
            book_v={otherItem["book_v"]}
            diff_v={otherItem["diff_v"]}
            fixed_v={otherItem["fixed_v"]}
            comment={otherItem["comment"]}
            editOtherItem={(e) => this.editOtherItem(largeId, e)}
          />
        )}
      </React.Fragment>
    );
  }

  renderSumsItem(sumsId) {
    const item_sums = Association.getItemById(
      this.items_sums,
      "sumsId",
      sumsId
    );
    return (
      <SumsItemTr
        name={item_sums["name"]}
        book_v={item_sums["book_v"]}
        diff_v={item_sums["diff_v"]}
        fixed_v={item_sums["fixed_v"]}
        comment={item_sums["comment"]}
      />
    );
  }

  render() {
    const state = this.props.financialStatements["profitAndLoss"]["fix"];
    this.items_sums = state["items_sums"];
    this.items_large = state["items_large"];
    this.items_middle = state["items_middle"];
    this.items_small = state["items_small"];
    this.items_other = state["items_other"];
    this.body = {
      items_sums: this.items_sums,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    if (this.items_sums == null) {
      return false;
    } else {
      return (
        <React.Fragment>
          <div className="fs-11 px-4 contents-block">
            <div className="row scroll-area">
              <div className="col-10 offset-1">
                <table className="w-100">
                  <thead>
                    <tr>
                      <td
                        width={"100%"}
                        colSpan={24}
                        className="text-center font-weight-bold fs-15"
                      >
                        損益計算書
                      </td>
                    </tr>
                    <tr>
                      <td
                        width={"50%"}
                        colSpan={12}
                        className="text-center font-weight-bold fs-13"
                      >
                        科目
                      </td>
                      <td
                        colSpan={12}
                        className="text-center font-weight-bold fs-13"
                      >
                        金額
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderLargeItem(0)}
                    {this.renderLargeItem(1)}
                    {this.renderSumsItem(0)}
                    {this.renderLargeItem(2)}
                    {this.renderSumsItem(1)}
                    {this.renderLargeItem(3)}
                    {this.renderLargeItem(4)}
                    {this.renderSumsItem(2)}
                    {this.renderLargeItem(5)}
                    {this.renderLargeItem(6)}
                    {this.renderSumsItem(3)}
                    {this.renderLargeItem(7)}
                    {this.renderSumsItem(4)}
                  </tbody>
                </table>
              </div>
              <input
                type="hidden"
                name="financial_statement_fix[body]"
                value={JSON.stringify(this.body)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    handleInit(body) {
      dispatch(ProfitAndLossFixAction.plFixInitProps(body));
    },
    handleAddSmallItem(body, item_small) {
      dispatch(ProfitAndLossFixAction.plFixAddSmallItem(body, item_small));
    },
    handleAddMiddleItem(body, item_middle) {
      dispatch(ProfitAndLossFixAction.plFixAddMiddleItem(body, item_middle));
    },
    handleAddLargeItem(body, item_large) {
      dispatch(ProfitAndLossFixAction.plFixAddLargeItem(body, item_large));
    },
    handleRemoveSmallItem(body, small_id) {
      dispatch(ProfitAndLossFixAction.plFixRemoveSmallItem(body, small_id));
    },
    handleRemoveMiddleItem(body, middle_id) {
      dispatch(ProfitAndLossFixAction.plFixRemoveMiddleItem(body, middle_id));
    },
    handleRemoveLargeItem(body, large_id) {
      dispatch(ProfitAndLossFixAction.plFixRemoveLargeItem(body, large_id));
    },
    handleEditSmallItem(body, small_id, name, value) {
      dispatch(
        ProfitAndLossFixAction.plFixEditSmallItem(body, small_id, name, value)
      );
    },
    handleEditMiddleItem(body, middle_id, name, value) {
      dispatch(
        ProfitAndLossFixAction.plFixEditMiddleItem(body, middle_id, name, value)
      );
    },
    handleEditLargeItem(body, large_id, name, value) {
      dispatch(
        ProfitAndLossFixAction.plFixEditLargeItem(body, large_id, name, value)
      );
    },
    handleEditOtherItem(body, large_id, name, value) {
      dispatch(
        ProfitAndLossFixAction.plFixEditOtherItem(body, large_id, name, value)
      );
    },
  };
}

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(ProfitAndLossFix)
);
