import React from "react";

export const CURRENT_USER_CHECK_LOGIN = "CURRENT_USER_CHECK_LOGIN";

const currentUserCheckLogin = (props) => {
  const elm = document.getElementById("data--current-user");
  const currentUser = elm ? JSON.parse(elm.innerText) : null;
  return {
    type: CURRENT_USER_CHECK_LOGIN,
    isLogged: currentUser ? currentUser.isLogged : false,
  };
};

export const CurrentUserAction = {
  currentUserCheckLogin,
};
