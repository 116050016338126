import {
  BS_FIX_INIT_PROPS,
  BS_FIX_ADD_SMALL_ITEM,
  BS_FIX_ADD_MIDDLE_ITEM,
  BS_FIX_ADD_LARGE_ITEM,
  BS_FIX_REMOVE_SMALL_ITEM,
  BS_FIX_REMOVE_MIDDLE_ITEM,
  BS_FIX_REMOVE_LARGE_ITEM,
  BS_FIX_EDIT_SMALL_ITEM,
  BS_FIX_EDIT_MIDDLE_ITEM,
  BS_FIX_EDIT_LARGE_ITEM,
  BS_FIX_EDIT_OTHER_ITEM,
  BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS,
  BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS,
} from "../../../actions/financialActions/financialStatements/BalanceSheetFixAction";

function balanceSheetFixReducer(state = {}, action) {
  switch (action.type) {
    case BS_FIX_INIT_PROPS:
      return Object.assign({}, state, action.body);

    case BS_FIX_ADD_SMALL_ITEM:
      return { ...state, ...{ items_small: action.items_small } };

    case BS_FIX_ADD_MIDDLE_ITEM:
      return { ...state, ...{ items_middle: action.items_middle } };

    // case BS_FIX_ADD_LARGE_ITEM:
    //   return Object.assign({}, state, {})

    case BS_FIX_REMOVE_SMALL_ITEM:
      return { ...state, ...{ items_small: action.items_small } };

    case BS_FIX_REMOVE_MIDDLE_ITEM:
      return { ...state, ...{ items_middle: action.items_middle } };

    case BS_FIX_REMOVE_LARGE_ITEM:
      return Object.assign({}, state, {});

    case BS_FIX_EDIT_SMALL_ITEM:
      return { ...state, ...{ items_small: action.items_small } };

    case BS_FIX_EDIT_MIDDLE_ITEM:
      return { ...state, ...{ items_middle: action.items_middle } };

    case BS_FIX_EDIT_LARGE_ITEM:
      return { ...state, ...{ items_large: action.items_large } };

    case BS_FIX_EDIT_OTHER_ITEM:
      return { ...state, ...{ items_other: action.items_other } };

    case BS_FIX_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS:
      return { ...state, ...{ items_middle: action.items_middle } };
    case BS_FIX_CALC_LARGE_ITEMS_BY_MIDDLE_ITEMS_AND_OTHER_ITEMS:
      // MIDDLEとその他からLargeを集計
      console.log(action.items_large);
      return { ...state, ...{ items_large: action.items_large } };

    case BS_FIX_CALC_CATEGORY_ITEMS_BY_LARGE_ITEMS:
      return { ...state, ...{ items_category: action.items_category } };

    default:
      return state;
  }
}

export default balanceSheetFixReducer;
