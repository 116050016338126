import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// import {apiReducer, middleWare} from "redux-rails";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import currentUserReducer from "../reducers/currentUserReducer";
import { CurrentUserAction } from "../actions/CurrentUserAction";
import {
  profitAndLossReducer,
  profitAndLossFixReducer,
  balanceSheetReducer,
  balanceSheetFixReducer,
  statementCategoryFixReducer,
  financialStatementImageReducer,
  referenceAreaReducer,
  tooltipsReducer,
} from "../reducers/financial";
import { isIE } from "../utils";
import {
  financialStatementImageEpic,
  balanceSheetEpic,
  balanceSheetFixEpic,
  profitAndLossEpic,
  profitAndLossFixEpic,
  tooltipsEpic,
} from "../epics/financialStatements";
import { financialStatementScreensFixesReducer } from "../reducers/financial";
import { DEVELOPMENT } from "../constants";

let logger;
if (!isIE()) {
  logger = createLogger();
}

// キーはReducerと合わせる
const initialState = {
  currentUser: {
    isLogged: false,
  },
  financialStatementScreens: {
    fixes: {
      category: 0,
      period: 0,
      unit: "円",
      structure: {},
    },
  },
  financialStatements: {
    balanceSheet: {
      book: {
        items_category: [],
        items_large: [],
        items_middle: [],
        items_small: [],
        items_other: [],
      },
      fix: {
        items_category: [],
        items_large: [],
        items_middle: [],
        items_small: [],
        items_other: [],
      },
    },
    profitAndLoss: {
      book: {
        items_sums: [],
        items_large: [],
        items_middle: [],
        items_other: [],
        items_small: [],
      },
      fix: {
        items_sums: [],
        items_large: [],
        items_middle: [],
        items_other: [],
        items_small: [],
      },
    },
    statementCategoryFix: {
      dataset: [],
    },
    referenceArea: {
      ocrResultList: {},
    },
    tooltips: {
      body: {},
      is_fetching: false,
    },
  },
  financialStatementImages: {
    fs_images: [],
    modalStatus: {
      is_open: false,
      ocrResult: null,
      fs_image: null,
      is_loading: false,
      ajax_text: "",
    },
    ocrLimitedCount: null,
  },
};

// キーはinitialStateと合わせる
const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  financialStatementScreens: combineReducers({
    fixes: financialStatementScreensFixesReducer,
  }),
  financialStatements: combineReducers({
    balanceSheet: combineReducers({
      book: balanceSheetReducer,
      fix: balanceSheetFixReducer,
    }),
    profitAndLoss: combineReducers({
      book: profitAndLossReducer,
      fix: profitAndLossFixReducer,
    }),
    referenceArea: referenceAreaReducer,
    statementCategoryFix: statementCategoryFixReducer,
    tooltips: tooltipsReducer,
  }),
  financialStatementImages: financialStatementImageReducer,
});

const epicMiddleware = createEpicMiddleware();

let reduxDevToolExtension = null;
// noinspection EmptyCatchBlockJS,UnusedCatchParameterJS
try {
  // noinspection JSUnresolvedVariable,JSUnresolvedFunction
  reduxDevToolExtension =
    __REDUX_DEVTOOLS_EXTENSION__ && __REDUX_DEVTOOLS_EXTENSION__();
} catch (e) {}

// composeの順番は必ず thunkは上、logger と reduxDevToolExtension は下にする
export const RootStore = createStore(
  rootReducer,
  initialState,
  compose.apply(
    this,
    [
      applyMiddleware(thunk),
      applyMiddleware(epicMiddleware),
      process.env.NODE_ENV === DEVELOPMENT && logger
        ? applyMiddleware(logger)
        : null,
      process.env.NODE_ENV === DEVELOPMENT ? reduxDevToolExtension : null,
    ].filter((middleware) => !!middleware)
  )
);

const rootEpic = combineEpics(
  financialStatementImageEpic,
  balanceSheetEpic,
  balanceSheetFixEpic,
  profitAndLossEpic,
  profitAndLossFixEpic,
  tooltipsEpic
);

epicMiddleware.run(rootEpic);

const financialStore = () => RootStore;
export default financialStore;

try {
  setTimeout(() => {
    // webpack外で使用
    window.RootStore = RootStore;

    // ログインチェック
    RootStore.dispatch(CurrentUserAction.currentUserCheckLogin());
  }, 0);
} catch (e) {
  console.log("server side", e);
}

if (process.env.NODE_ENV === DEVELOPMENT) {
  console.log("--- use financialStore", process.env.NODE_ENV);
}
