import {
  PL_INIT_PROPS,
  PL_ADD_SMALL_ITEM,
  PL_ADD_MIDDLE_ITEM,
  PL_ADD_LARGE_ITEM,
  PL_REMOVE_SMALL_ITEM,
  PL_REMOVE_MIDDLE_ITEM,
  PL_REMOVE_LARGE_ITEM,
  PL_EDIT_SMALL_ITEM,
  PL_EDIT_MIDDLE_ITEM,
  PL_EDIT_LARGE_ITEM,

  PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS,
  PL_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS,
  PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS,
} from "../../../actions/financialActions/financialStatements/ProfitAndLossAction";


function profitAndLossReducer(state = {}, action) {

  switch (action.type) {

    case PL_INIT_PROPS:
      return Object.assign({}, state, action.body)

    case PL_ADD_SMALL_ITEM:
      return { ...state, ...{items_small: action.items_small}}

    case PL_ADD_MIDDLE_ITEM:
      return { ...state, ...{ items_middle: action.items_middle }}
    

    case PL_ADD_LARGE_ITEM:
      return Object.assign({}, state, {})

    case PL_REMOVE_SMALL_ITEM:
      return { ...state, ...{ items_small: action.items_small } }
    

    case PL_REMOVE_MIDDLE_ITEM:
      return { ...state, ...{items_middle: action.items_middle}}
    

    case PL_REMOVE_LARGE_ITEM:
      return Object.assign({}, state, {})

    case PL_EDIT_SMALL_ITEM:
      return { ...state, ...{items_small: action.items_small}}
    

    case PL_EDIT_MIDDLE_ITEM:
      return { ...state, ...{items_middle: action.items_middle}}
    

    case PL_EDIT_LARGE_ITEM:
      return { ...state, ...{items_large: action.items_large}}
    

    case PL_CALC_MIDDLE_ITEMS_BY_SMALL_ITEMS:
      return {...state,...{items_middle: action.items_middle}}

    case PL_CALC_OTHER_ITEMS_BY_MIDDLE_ITEMS_AND_LARGE_ITEMS:
      return { ...state, ...{ items_other: action.items_other } }
    
    case PL_CALC_SUMS_ITEMS_BY_LARGE_ITEMS:
      return {...state,...{items_sums: action.items_sums}}

    default:
      return state;
  }
}
export default profitAndLossReducer;
