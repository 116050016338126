"use strict";

function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest()
  );
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (
    !(
      Symbol.iterator in Object(arr) ||
      Object.prototype.toString.call(arr) === "[object Arguments]"
    )
  ) {
    return null;
  }
  const _arr = [];
  let _n = true;
  let _d = false;
  let _e = undefined;
  try {
    // noinspection AssignmentResultUsedJS,ForLoopThatDoesntUseLoopVariableJS
    for (
      let _i = arr[Symbol.iterator](), _s;
      !(_n = (_s = _i.next()).done);
      _n = true
    ) {
      _arr.push(_s.value);
      if (i && _arr.length === i) {
        // noinspection BreakStatementJS
        break;
      }
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) {
        _i["return"]();
      }
    } finally {
      if (_d) {
        // noinspection ThrowInsideFinallyBlockJS
        throw _e;
      }
    }
  }
  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) {
    return arr;
  }
  return true;
}

$(() => {
  $(".user_file__file_list_ul").click((event) => {
    if (event.target.getAttribute("data-user-file-download")) {
      event.preventDefault();

      const _$$data = $(event.target).data("userFileDownload"),
        _$$data2 = _slicedToArray(_$$data, 2),
        name = _$$data2[0],
        userFileId = _$$data2[1];

      window.open(`/user_files/${userFileId}/${name}`, "blank");
    } else if (event.target.getAttribute("data-user-file-delete")) {
      event.preventDefault();

      if (confirm("本当に削除してもよろしいですか？")) {
        const _$$data3 = $(event.target).data("userFileDelete"),
          _$$data4 = _slicedToArray(_$$data3, 2),
          _name = _$$data4[0],
          _userFileId = _$$data4[1];

        $.ajax({
          type: "POST",
          dataType: "json",
          url: `/user_files/${_userFileId}/${_name}`,
          data: {
            _method: "delete",
          },
          beforeSend: (request) => {
            request.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
        }).done(() => {
          location.reload();
        });
      }
    } else if (event.target.getAttribute("data-report-file-directory-delete")) {
      event.preventDefault();

      if (confirm("本当に削除してもよろしいですか？")) {
        const _$$data5 = $(event.target).data("reportFileDirectoryDelete"),
          _$$data6 = _slicedToArray(_$$data5, 2),
          _name2 = _$$data6[0],
          reportFileId = _$$data6[1];

        $.ajax({
          type: "POST",
          dataType: "json",
          url: `/report_files/${reportFileId}/${_name2}`,
          data: {
            _method: "delete",
          },
          beforeSend: (request) => {
            request.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
        })
          .done(() => {
            location.reload();
          })
          .fail((response) => {
            if (response.responseJSON.status === "file_exists") {
              alert("フォルダ内のファイルが残っているため、削除できません");
            }
          });
      }
    }
  });
  $("#user_files__upload_file_container").dropzone({
    dictDefaultMessage: "",
    maxFilesize: 100,
    maxFiles: 30,
    init: function init() {
      this.on("completemultiple", () => {
        location.reload();
      });
      this.on("complete", () => {
        location.reload();
      });
    },
  });
});
