/**
 * タイプ不明
 */
export function saveNonValidate({
  $answerBlockVisible,
  prevArr,
  submitDisplay,
}) {
  // 回答必須ではなく、入力されていなければ
  if ($answerBlockVisible.data("next_question_number")) {
    // 次の質問IDがあれば
    const next_question_number = $answerBlockVisible.data(
      "next_question_number"
    );

    const $answerEditQuestionNumber = $(
      `#interview_answer_edit #question_${next_question_number}`
    );

    const number = $answerBlockVisible.data("number");
    if ($answerEditQuestionNumber.length) {
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      $answerEditQuestionNumber.fadeIn("slow");
    } else {
      const number = $answerBlockVisible.data("number");
      prevArr.push(`#question_${number}`);
      $answerBlockVisible.hide();
      submitDisplay();
    }
  } else {
    // 次の質問IDがなければ
    const number = $answerBlockVisible.data("number");
    prevArr.push(`#question_${number}`);
    $answerBlockVisible.hide();
    submitDisplay();
  }
}
