import React from "react";
import { connect } from "react-redux";
import SmallItemTr from "../share/book/SmallItemTr";
import MiddleItemTr from "../share/book/MiddleItemTr";
import LargeItemTr from "../share/book/LargeItemTr";
import AddMiddleItemTr from "../share/book/AddMiddleItemTr";
import OtherMiddleItemTr from "../share/book/OtherMiddleItemTr";
import { hot } from "react-hot-loader";
import { BalanceSheetAction } from "../../../../../actions/financialActions/financialStatements/BalanceSheetAction";
import { HashTools } from "../../../../../modules/financialStatements/hashTools";
import { ArrayTools } from "../../../../../modules/financialStatements/arrayTools";
import { Association } from "../../../../../modules/financialStatements/associationTools";
import { refsCreator } from "../../../../../modules/financialStatements/refsCreator";
import { debounce } from "throttle-debounce";
import { TooltipsAction } from "../../../../../actions/financialActions/financialStatements/TooltipsAction";
import { getReportId } from "../../../../../modules/financialStatements/querySearcher";
import { balanceSheetSelector } from "../../../../../selectors/financial/financialStatements/balanceSheetSelector";

class BalanceSheet extends React.Component {
  constructor(props) {
    super(props);
    this.items_category = props.items_category;
    this.items_large = props.items_large;
    this.items_middle = props.items_middle;
    this.items_small = props.items_small;
    this.items_other = props.items_other;
    this.body = {
      items_category: this.items_category,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    this.editSmallItem = this.editSmallItem.bind(this);
    this.editMiddleItem = this.editMiddleItem.bind(this);
    this.editLargeItem = this.editLargeItem.bind(this);
    this.editSmallItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditSmallItem(this.body, id, name, value)
    );
    this.editMiddleItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditMiddleItem(this.body, id, name, value)
    );
    this.editLargeItemDebounce = debounce(300, (id, name, value) =>
      this.props.handleEditLargeItem(this.body, id, name, value)
    );

    this.handleInit(this.body);
    this.report_id = getReportId();
    this.props.initTooltips(this.report_id);
  }

  handleInit(body) {
    this.props.handleInit(body);
  }

  addSmallItem(middleId) {
    const small_id = Association.getLastIdPlusOne(this.items_small, "small_id");
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    const middleItemHasChildren = ArrayTools.checkArray(smallItems);
    const parentMiddleItem = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const book_v = middleItemHasChildren ? 0 : parentMiddleItem["book_v"];
    const diff_v = middleItemHasChildren ? 0 : parentMiddleItem["diff_v"];
    const fixed_v = middleItemHasChildren ? 0 : parentMiddleItem["fixed_v"];
    // 中項目の子要素が存在しない場合、追加される小項目の簿価は中項目の簿価になる
    const item_small = {
      small_id,
      middle_id: middleId,
      name: "",
      book_v,
      diff_v,
      fixed_v,
      addedIn: "book",
    };
    this.props.handleAddSmallItem(this.body, item_small);
  }
  addMiddleItem(largeId) {
    const middle_id = Association.getLastIdPlusOne(
      this.items_middle,
      "middle_id"
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      largeId
    );
    const largeItemHasChildren = ArrayTools.checkArray(middleItems);
    const parentLargeItem = Association.getItemById(
      this.items_large,
      "large_id",
      largeId
    );
    const book_v = largeItemHasChildren ? 0 : parentLargeItem["book_v"];
    const diff_v = largeItemHasChildren ? 0 : parentLargeItem["diff_v"];
    const fixed_v = largeItemHasChildren ? 0 : parentLargeItem["fixed_v"];
    const item_middle = {
      middle_id,
      large_id: largeId,
      name: "",
      book_v,
      diff_v,
      fixed_v,
      addedIn: "book",
    };
    this.props.handleAddMiddleItem(this.body, item_middle);
  }
  // addLargeItem(e) {
  //   const large_id = Association.getLastIdPlusOne(this.items_large, "large_id")
  //   const item_large = {"large_id": large_id, "name": "", "book_v": 0, "diff_v": 0, "fixed_v": 0, "addedIn": "book"}
  //   this.props.handleAddLargeItem(this.body, this.items_large, item_large)
  // }
  removeSmallItem(smallId) {
    this.props.handleRemoveSmallItem(this.body, smallId);
  }
  removeMiddleItem(middleId) {
    this.props.handleRemoveMiddleItem(this.body, middleId);
  }

  // removeLargeItem() {

  // }
  editSmallItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editSmallItemDebounce(id, name, value);
    e.preventDefault();
  }
  editMiddleItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editMiddleItemDebounce(id, name, value);
    e.preventDefault();
  }
  editLargeItem(e) {
    const name = e.target.name;
    const value = e.target.value;
    // noinspection JSUnresolvedVariable
    const id = e.target.dataset.item_id;
    this.editLargeItemDebounce(id, name, value);
    e.preventDefault();
  }

  // ==================================
  // Drag & Drop
  handleDragOver(e, ref) {
    ref.current.style.opacity = "0.4";
    ref.current.style.backgroundColor = "Yellow";
    e.preventDefault();
  }

  handleDragLeave(e, ref) {
    ref.current.style.opacity = "1";
    ref.current.style.backgroundColor = "white";
    e.preventDefault();
  }

  handleDropAddSmallItem(e, ref, middleId) {
    ref.current.style.opacity = "1";
    ref.current.style.backgroundColor = "white";
    const small_id = Association.getLastIdPlusOne(this.items_small, "small_id");
    let item_small = JSON.parse(e.dataTransfer.getData("text/plain"));
    item_small = Object.assign(item_small, {
      middle_id: middleId,
      small_id,
    });
    e.preventDefault();
    this.props.handleAddSmallItem(this.body, item_small);
  }
  handleDropAddMiddleItem(e, ref, largeId) {
    ref.current.style.backgroundColor = "white";
    ref.current.style.opacity = "1";
    const middle_id = Association.getLastIdPlusOne(
      this.items_middle,
      "middle_id"
    );
    let item_middle = JSON.parse(e.dataTransfer.getData("text/plain"));
    item_middle = Object.assign(item_middle, {
      middle_id,
      large_id: largeId,
    });
    e.preventDefault();
    this.props.handleAddMiddleItem(this.body, item_middle);
  }

  handleDropSelfValue(e, ref, idName, id) {
    ref.current.style.backgroundColor = "white";
    ref.current.style.opacity = "1";
    let item = JSON.parse(e.dataTransfer.getData("text/plain"));

    e.preventDefault();
    switch (idName) {
      case "large_id":
        this.props.handleEditLargeItem(this.body, id, "book_v", item["book_v"]);
        if (
          this.items_large.filter((item_large) => item_large.large_id === id)[0]
            .name === ""
        ) {
          this.props.handleEditLargeItem(
            this.props.financialStatements.balanceSheet.book,
            id,
            "name",
            item["name"]
          );
        }
        break;
      case "middle_id":
        this.props.handleEditMiddleItem(
          this.body,
          id,
          "book_v",
          item["book_v"]
        );
        if (
          this.items_middle.filter(
            (item_middle) => item_middle.middle_id === id
          )[0].name === ""
        ) {
          this.props.handleEditMiddleItem(
            this.props.financialStatements.balanceSheet.book,
            id,
            "name",
            item["name"]
          );
        }
        break;
      case "small_id":
        this.props.handleEditSmallItem(this.body, id, "book_v", item["book_v"]);
        if (
          this.items_small.filter((item_small) => item_small.small_id === id)[0]
            .name === ""
        ) {
          this.props.handleEditSmallItem(
            this.props.financialStatements.balanceSheet.book,
            id,
            "name",
            item["name"]
          );
        }
        break;
      default:
        break;
    }
  }
  handleDropSelfName(e, ref, idName, id) {
    ref.current.style.backgroundColor = "white";
    ref.current.style.opacity = "1";
    let item = JSON.parse(e.dataTransfer.getData("text/plain"));
    e.preventDefault();
    // noinspection FallThroughInSwitchStatementJS
    switch (idName) {
      case "large_id":
        this.props.handleEditLargeItem(this.body, id, "name", item["name"]);
        break;
      case "middle_id":
        this.props.handleEditMiddleItem(this.body, id, "name", item["name"]);
        break;
      case "small_id":
        this.props.handleEditSmallItem(this.body, id, "name", item["name"]);
      default:
        break;
    }
  }

  // noinspection ParameterNamingConventionJS
  renderSmallItem(small_id) {
    const item_small = Association.getItemById(
      this.items_small,
      "small_id",
      small_id
    );
    if (
      ArrayTools.checkArray(this.items_small) &&
      HashTools.checkHash(item_small)
    ) {
      const selfValueRef = refsCreator.findRefFromRefsDictById(
        this.smallItemsRefs,
        "small_id",
        "selfValueRef",
        small_id
      );
      const selfNameRef = refsCreator.findRefFromRefsDictById(
        this.smallItemsRefs,
        "small_id",
        "selfNameRef",
        small_id
      );
      const refs = { selfValueRef, selfNameRef };

      return (
        <SmallItemTr
          refs={refs}
          key={small_id}
          item_middle={item_small}
          name={item_small["name"]}
          book_v={item_small["book_v"]}
          diff_v={item_small["diff_v"]}
          fixed_v={item_small["fixed_v"]}
          comment={item_small["comment"]}
          addedIn={item_small["addedIn"]}
          removeSmallItem={() => this.removeSmallItem(small_id)}
          editSmallItem={this.editSmallItem}
          onDropSelfValueRef={(e) =>
            this.handleDropSelfValue(e, selfValueRef, "small_id", small_id)
          }
          onDropSelfNameRef={(e) =>
            this.handleDropSelfName(e, selfNameRef, "small_id", small_id)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
          data_item_id={small_id}
          data_item_name="small_id"
        />
      );
    } else {
      return false;
    }
  }

  renderSmallItems(middleId) {
    let list = [];
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    if (ArrayTools.checkArray(smallItems)) {
      smallItems.forEach((v) => list.push(this.renderSmallItem(v["small_id"])));
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  renderMiddleItem(middleId) {
    const item_middle = Association.getItemById(
      this.items_middle,
      "middle_id",
      middleId
    );
    const smallItems = Association.getItemsById(
      this.items_small,
      "middle_id",
      middleId
    );
    const addItemRef = refsCreator.findRefFromRefsDictById(
      this.middleItemsRefs,
      "middle_id",
      "addChildRef",
      middleId
    );
    const selfValueRef = refsCreator.findRefFromRefsDictById(
      this.middleItemsRefs,
      "middle_id",
      "selfValueRef",
      middleId
    );
    const selfNameRef = refsCreator.findRefFromRefsDictById(
      this.middleItemsRefs,
      "middle_id",
      "selfNameRef",
      middleId
    );
    const refs = {
      addItemRef,
      selfValueRef,
      selfNameRef,
    };
    const tooltip_text = this.props.financialStatements.tooltips.body[
      item_middle.code
    ];
    return (
      <React.Fragment key={middleId}>
        <MiddleItemTr
          refs={refs}
          key={middleId}
          item_middle={item_middle}
          name={item_middle["name"]}
          book_v={item_middle["book_v"]}
          diff_v={item_middle["diff_v"]}
          fixed_v={item_middle["fixed_v"]}
          comment={item_middle["comment"]}
          addedIn={item_middle["addedIn"]}
          items_small={smallItems}
          addSmallItem={() => this.addSmallItem(middleId)}
          removeMiddleItem={() => this.removeMiddleItem(middleId)}
          editMiddleItem={this.editMiddleItem}
          onDropAddItemRef={(e) =>
            this.handleDropAddSmallItem(e, addItemRef, middleId)
          }
          onDropSelfValueRef={(e) =>
            this.handleDropSelfValue(e, selfValueRef, "middle_id", middleId)
          }
          onDropSelfNameRef={(e) =>
            this.handleDropSelfName(e, selfNameRef, "middle_id", middleId)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
          data_item_id={middleId}
          data_item_name="middle_id"
          tooltip_text={tooltip_text}
        />
        {this.renderSmallItems(middleId)}
      </React.Fragment>
    );
  }

  // noinspection ParameterNamingConventionJS
  renderMiddleItems(large_id) {
    let list = [];
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      large_id
    );
    if (ArrayTools.checkArray(middleItems)) {
      middleItems.forEach((v) =>
        list.push(this.renderMiddleItem(v["middle_id"]))
      );
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  // noinspection ParameterNamingConventionJS
  renderLargeItem(large_id) {
    const item_large = Association.getItemById(
      this.items_large,
      "large_id",
      large_id
    );
    const middleItems = Association.getItemsById(
      this.items_middle,
      "large_id",
      large_id
    );
    const otherItem = Association.getItemById(
      this.items_other,
      "large_id",
      large_id
    );
    const addItemRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "addChildRef",
      large_id
    );
    const selfValueRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "selfValueRef",
      large_id
    );
    const selfNameRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "selfNameRef",
      large_id
    );
    const addItemBelowRef = refsCreator.findRefFromRefsDictById(
      this.largeItemsRefs,
      "large_id",
      "addChildBelowRef",
      large_id
    );
    const refs = {
      addItemRef,
      selfValueRef,
      selfNameRef,
      addItemBelowRef,
    };
    return (
      <React.Fragment key={large_id}>
        <LargeItemTr
          refs={refs}
          item_large={item_large}
          name={item_large["name"]}
          book_v={item_large["book_v"]}
          diff_v={item_large["diff_v"]}
          fixed_v={item_large["fixed_v"]}
          comment={item_large["comment"]}
          addedIn={item_large["addedIn"]}
          items_middle={middleItems}
          addMiddleItem={() => this.addMiddleItem(large_id)}
          removeLargeItem={() => {}}
          editLargeItem={this.editLargeItem}
          onDropAddItemRef={(e) =>
            this.handleDropAddMiddleItem(e, addItemRef, large_id)
          }
          onDropSelfValueRef={(e) =>
            this.handleDropSelfValue(e, selfValueRef, "large_id", large_id)
          }
          onDropSelfNameRef={(e) =>
            this.handleDropSelfValue(e, selfNameRef, "large_id", large_id)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
          data_item_id={large_id}
          data_item_name="large_id"
        />
        {this.renderMiddleItems(large_id)}
        <OtherMiddleItemTr book_v={otherItem["book_v"]} />
        <AddMiddleItemTr
          name={item_large["name"]}
          addMiddleItem={() => this.addMiddleItem(large_id)}
          addMiddleItemRef={addItemBelowRef || null}
          onDropAddItemRef={(e, ref) =>
            this.handleDropAddMiddleItem(e, ref, large_id)
          }
          onDragLeave={(e, ref) => this.handleDragLeave(e, ref)}
          onDragOver={(e, ref) => this.handleDragOver(e, ref)}
        />
      </React.Fragment>
    );
  }
  renderLargeItems(categoryId) {
    let list = [];
    const largeItems = Association.getItemsById(
      this.items_large,
      "category_id",
      categoryId
    );
    if (ArrayTools.checkArray(largeItems)) {
      largeItems.forEach((v) => list.push(this.renderLargeItem(v["large_id"])));
    }
    return <React.Fragment>{list}</React.Fragment>;
  }

  // noinspection ParameterNamingConventionJS
  renderPartial(category_id) {
    const name = Association.getItemById(
      this.items_category,
      "category_id",
      category_id
    )["name"];
    const book_v = Association.getItemById(
      this.items_category,
      "category_id",
      category_id
    )["book_v"];

    const ocrResultList = Object.values(
      this.props.financialStatements.referenceArea.ocrResultList
    );
    const items_reference_warning =
      ocrResultList.length > 0
        ? ocrResultList
            .flat()
            .filter(
              (item) =>
                (item["項目名"] === name ||
                  item["項目名"] === `${name}の部` ||
                  item["項目名"] === `${name}の部合計`) &&
                parseInt(item["金額1"]) !== book_v
            )
        : null;

    const reference_warning =
      items_reference_warning != null && items_reference_warning.length > 0;
    const warning = reference_warning;
    return (
      <table className="w-100">
        <thead>
          <tr>
            <td
              width={"100%"}
              colSpan={24}
              className="text-center font-weight-bold fs-15"
            >
              {name}の部
            </td>
          </tr>
          <tr>
            <td
              width={"70%"}
              colSpan={12}
              className="text-center font-weight-bold fs-13"
            >
              科目
            </td>
            <td colSpan={12} className="text-center font-weight-bold fs-13">
              金額
            </td>
          </tr>
        </thead>

        <tbody>{this.renderLargeItems(category_id)}</tbody>

        <tfoot>
          <tr>
            <td
              width={"60%"}
              colSpan={12}
              className="text-center font-weight-bold fs-13"
            >
              {name}の部合計
            </td>
            <td
              colSpan={warning ? 8 : 12}
              className="text-center font-weight-bold fs-13"
            >
              {book_v === "" ? null : parseInt(book_v).toLocaleString()}
            </td>
            {warning && (
              <td width={"30%"} colSpan={4} style={{ color: "red" }}>
                <i className="fa fa-exclamation-triangle"></i>
                {reference_warning && "OCR結果と不一致"}
              </td>
            )}
          </tr>
        </tfoot>
      </table>
    );
  }

  renderSummary() {
    const assetBookSum = Association.getItemById(
      this.items_category,
      "category_id",
      "AS"
    )["book_v"];
    const debtBookSum = Association.getItemById(
      this.items_category,
      "category_id",
      "DE"
    )["book_v"];
    const equityBookSum = Association.getItemById(
      this.items_category,
      "category_id",
      "NA"
    )["book_v"];
    const deBookSum = String(debtBookSum + equityBookSum);

    const sum_warning = assetBookSum !== parseInt(deBookSum);
    const style_warning = sum_warning ? { color: "red" } : {};
    const warning = sum_warning;

    return (
      <React.Fragment>
        <div className="col-6 text-center">
          <table className="w-100">
            <tbody>
              <tr>
                <td
                  className="fs-15 font-weight-bold"
                  width={"60%"}
                  colSpan={8}
                >
                  資産の部合計
                </td>
                <td
                  className="fs-13 font-weight-bold"
                  colSpan={warning ? 12 : 16}
                  style={style_warning}
                >
                  {assetBookSum === ""
                    ? null
                    : parseInt(assetBookSum).toLocaleString()}
                </td>
                {warning && (
                  <td colSpan={4} style={{ color: "red" }}>
                    <i className="fa fa-exclamation-triangle" />
                    {sum_warning && "貸借が不一致"}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-6 text-center">
          <table className="w-100">
            <tbody>
              <tr>
                <td
                  className="fs-15 font-weight-bold"
                  width={"60%"}
                  colSpan={8}
                >
                  負債・純資産の部合計
                </td>
                <td
                  className="fs-13 font-weight-bold"
                  colSpan={warning ? 12 : 16}
                  style={style_warning}
                >
                  {deBookSum === ""
                    ? null
                    : parseInt(deBookSum).toLocaleString()}
                </td>
                {warning && (
                  <td colSpan={4} style={{ color: "red" }}>
                    <i className="fa fa-exclamation-triangle"></i>
                    {sum_warning && "貸借が不一致"}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const state = this.props.financialStatements["balanceSheet"]["book"];
    this.items_category = state["items_category"];
    this.items_large = state["items_large"];
    this.items_middle = state["items_middle"];
    this.items_small = state["items_small"];
    this.items_other = state["items_other"];
    this.body = {
      items_category: this.items_category,
      items_large: this.items_large,
      items_middle: this.items_middle,
      items_small: this.items_small,
      items_other: this.items_other,
    };
    this.largeItemsRefs = refsCreator.createRefsDictByItems(
      this.items_large,
      "large_id"
    );
    this.middleItemsRefs = refsCreator.createRefsDictByItems(
      this.items_middle,
      "middle_id"
    );
    this.smallItemsRefs = refsCreator.createRefsDictByItems(
      this.items_small,
      "small_id"
    );

    if (this.items_category == null) {
      return false;
    } else {
      return (
        <React.Fragment>
          <div className="contents-block fs-11 px-2">
            <div className="row mx-0 scroll-area">
              <div className="col-6 px-3 bd-ol">{this.renderPartial("AS")}</div>
              <div className="col-6 px-3 bd-ol">
                {this.renderPartial("DE")}
                <br />
                {this.renderPartial("NA")}
              </div>
            </div>
            <div className="row">{this.renderSummary()}</div>
            <input
              type="hidden"
              name="financial_statement_fix[body]"
              value={JSON.stringify(this.body)}
            />
          </div>
        </React.Fragment>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleInit(body) {
      dispatch(BalanceSheetAction.bsInitProps(body));
    },
    handleAddSmallItem(body, item_small) {
      dispatch(BalanceSheetAction.bsAddSmallItem(body, item_small));
    },
    handleAddMiddleItem(body, item_middle) {
      dispatch(BalanceSheetAction.bsAddMiddleItem(body, item_middle));
    },
    handleAddLargeItem(body, item_large) {
      dispatch(BalanceSheetAction.bsAddLargeItem(body, item_large));
    },
    handleRemoveSmallItem(body, small_id) {
      dispatch(BalanceSheetAction.bsRemoveSmallItem(body, small_id));
    },
    handleRemoveMiddleItem(body, middle_id) {
      dispatch(BalanceSheetAction.bsRemoveMiddleItem(body, middle_id));
    },
    handleRemoveLargeItem(body, large_id) {
      dispatch(BalanceSheetAction.bsRemoveLargeItem(body, large_id));
    },
    handleEditSmallItem(body, small_id, name, value) {
      dispatch(BalanceSheetAction.bsEditSmallItem(body, small_id, name, value));
    },
    handleEditMiddleItem(body, middle_id, name, value) {
      dispatch(
        BalanceSheetAction.bsEditMiddleItem(body, middle_id, name, value)
      );
    },
    handleEditLargeItem(body, large_id, name, value) {
      dispatch(BalanceSheetAction.bsEditLargeItem(body, large_id, name, value));
    },
    initTooltips(report_id) {
      dispatch(TooltipsAction.tooltipsRequest(report_id));
    },
  };
}

export default hot(module)(
  connect(balanceSheetSelector, mapDispatchToProps)(BalanceSheet)
);
