export const TOOLTIPS_REQUEST = "TOOLTIPS_REQUEST"
export const TOOLTIPS_SUCCESS = "TOOLTIPS_SUCCESS"
export const TOOLTIPS_FAILURE = "TOOLTIPS_FAILURE"


const tooltipsRequest = report_id => ({
  type: TOOLTIPS_REQUEST,
  report_id,
  is_fetching: true,
})


const tooltipsSuccess = res => {
  const body = JSON.parse(res.data.tooltips)
  return{
    type: TOOLTIPS_SUCCESS,
    body,
    is_fetching: false,
  }
}

const tooltipsFailure = error => ({
  type: TOOLTIPS_FAILURE,
  error,
  is_fetching: false,
})

export const TooltipsAction = {
  tooltipsRequest,
  tooltipsSuccess,
  tooltipsFailure
}